import React from 'react'

const SVG = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 2592.2 3826.6">
      <path
        ST0
        fill="#191919"
        d="M1221.4,1696.7c-0.2,0.1-0.5,0.3-0.7,0.4c0.2,0.2,0.6,0.4,0.9,0.6c0.7,0.4,1.5,0.9,1.8,1.8
				c0.1,0.4,0.1,0.8-0.1,1.2c-0.4,0.6-1,0.7-1.5,0.7c-0.1,0-0.1,0-0.2,0c-0.7,0-1.6-0.1-1.8-0.3c-0.7-0.3-1.2-0.8-1.6-1.2
				c-0.4-0.4-0.7-0.7-1.2-0.8c-0.8-0.2-1.7-0.4-2.7-0.4l-1.5-0.1l0.7-1.4c1.2-2.2,2.5-3.4,4.1-4.9c0.4-0.4,0.9-0.8,1.3-1.3
				c0.8-0.8,1.6-1.3,2.3-1.9c0.4-0.3,0.8-0.6,1.2-0.9c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-1-0.8-1-1.7-0.9-2.3
				c0.5-2.2,4.5-3.6,5.7-4c0.1,0,0.2-0.1,0.3-0.1l0.1,0c0.6-0.2,1.4-0.5,2.2-0.5c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.4,0.7,0.7
				c0.3,0.8,0,1.9-0.9,3.8l-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c-0.2,0.5-0.2,1.2-0.2,2c-0.1,1.6-0.2,3.5-1.4,4.5
				c-0.3,0.2-0.7,0.4-1.1,0.4c-0.5,0-1.1-0.1-1.7-0.2l-0.1,0c-0.4-0.1-0.7-0.2-0.9-0.1c-0.5,0.1-0.6,0.2-0.9,1.2
				c-0.1,0.5-0.3,1-0.5,1.5C1222.8,1695.9,1222.2,1696.3,1221.4,1696.7z M1276.2,1707.2c0.5,2.1,0.4,4.4,0.3,6.4l0,0.1
				c0,0.2,0,0.5,0,0.8c0,1.3-0.1,3-0.8,4c-0.8,1.1-1.9,2-2.9,2.9c-0.5,0.4-0.9,0.7-1.3,1.1c-0.2,0.2-0.3,0.3-0.5,0.5l-0.1,0.1
				c-1.3,1.2-2.6,2.4-3.5,3.8c-0.9,1.6-2,2.7-3.2,3.9c-0.5,0.6-1.1,1.1-1.6,1.7c-0.5,0.5-0.7,1.4-0.9,2.4c-0.1,0.7-0.3,1.4-0.6,2
				c-0.8,1.9-2.6,4-5.2,4.1c-0.5,0-1,0-1.5-0.2c-1.9-0.5-1.5-2.6-1.2-3.7c0.1-0.4,0.2-1.2,0.2-1.4c-0.4-0.9-1-1.4-1.7-1.4l-0.1,0
				c-0.7,0-1.3,0.5-1.7,1.2c-0.9,1.6-2,3.3-3,4.8c-2.4,3.7-5,7.5-5.3,11.7c-0.2,2.3-0.2,4.9,1.3,7.3c1.1,1.7,2.4,2.5,4,3.5
				c0.8,0.5,1.6,1,2.4,1.6c5.2,4,6.4,12.7,7,16.9c0,0.2,0.1,0.5,0.1,0.9l0,0.1c0.2,1.5,0.6,4.9,1.8,5.5c1.4,0.6,2.9,0.9,4.4,1.3
				c0.9,0.2,1.8,0.4,2.7,0.7c0.7,0.2,1.6,0.6,1.9,1.5c0.3,1.1-0.4,2-1.2,2.9l-0.3,0.3c-2,2.4-3.7,5.2-5.4,7.8l-0.6,1
				c-0.9,1.4-1.7,2.8-2.6,4.3l0,0.1c-2.3,3.9-4.7,8-7.7,11.2c-1,1.1-1.9,2.1-2.9,3.2c-2.3,2.5-4.6,5-6.9,7.6c-2,2.3-5.4,6.7-6,11
				c-0.1,1,0.1,2.1,0.4,3.2c0.3,1.4,0.7,2.9,0.3,4.5c-0.5,1.8-2.2,3.3-3.6,4.6c-0.3,0.3-0.6,0.5-0.8,0.7c-2.3,2.2-4.2,4.5-6.3,6.9
				l-0.1,0.1c-0.1,0.2-0.3,0.3-0.4,0.5c-1,1.2-2,2.4-3.4,3.2c-0.9,0.5-2,1.1-2.6,1.9c-0.2,0.2-0.2,0.6-0.3,0.9
				c-0.1,0.6-0.2,1.2-0.8,1.9c-0.6,0.7-1.4,1.3-2.1,1.8c-0.9,0.6-1.6,1.3-2.2,2.1c-1.2,1.8-0.8,5.6-0.3,7.6c0.1,0.3,0.5,0.8,0.9,1.2
				c0.6,0.7,1.2,1.4,0.9,2.2c-0.1,0.3-0.4,0.7-1.3,0.8c-0.3,0-0.6,0-1-0.1c-1.1-0.2-1.9-0.5-2.8-0.9c-1-0.4-1.8-0.7-2.6-0.7
				c-0.6,0-1.2,0.3-1.8,0.7c-0.9,0.7-1.6,1.5-2.4,2.3c-0.6,0.7-1.3,1.4-2,2.1c-1.9,1.7-4.1,3.7-5.3,6c-0.6,1.3-0.7,2.8-0.8,4.5
				c-0.1,1.5-0.2,3-0.7,4.5c-0.5,1.4-1.1,2.7-1.6,4l0,0.1c-0.3,0.7-0.6,1.4-0.9,2c-1.8,4.3-3.7,8.6-5.5,12.8l0,0.1
				c-1.6,3.5-3.2,7.2-4.7,10.8c-0.6,1.5-1.4,3.3-2,5c-0.3,1-0.4,1.9-0.5,3c0,0.7-0.1,1.5-0.2,2.3c-0.3,1.6-1.2,2.9-2.1,4.1
				c-0.6,0.9-1.2,1.7-1.5,2.5c-1.1,2.8-3.3,5.1-6.2,6.4c-1.1,0.5-2.2,0.7-3.3,1c-1.1,0.2-2.1,0.5-3,0.9c-2.6,1.3-4.3,4-5.9,6.8
				c-0.1,0.2-0.2,0.4-0.3,0.6c-0.6,1.2-1.3,2.6-3,3c-0.7,0.2-1.3,0.2-2,0.3c-1.1,0.1-2.1,0.1-2.8,0.8c-1.8,1.7-3.7,2.7-6.1,3.8
				c-2.9,1.3-5.5,3.2-7.7,4.8c-0.1,0.1-0.3,0.2-0.4,0.3l-0.1,0.1c-1.1,0.9-2.6,2.1-4.3,2.1l-0.1,0c-0.9,0-1.4,1-2,2l0,0.1
				c-0.1,0.2-0.2,0.4-0.4,0.6c-5.9,9.9-8.6,21.9-7.1,32.2c0.8,5.7,1.9,12.2,4.4,17.2c0.7,1.5,2.1,2.6,3.5,3.8l0.1,0.1
				c0.2,0.2,0.5,0.4,0.7,0.6c0.3,0.3,1.4,1.2,1.1,2.3c0,0.2-0.1,0.3-0.2,0.4c0.3,0,0.6,0,0.9,0.1c1,0.3,1.3,1.1,1.6,1.6
				c0.2,0.3,0.2,0.5,0.3,0.5c0.1,0,0.2,0.1,0.3,0.1l0.1,0c0.6,0,1.4-0.4,2.2-0.8c0.7-0.3,1.4-0.7,2-0.8c0.2,0,0.4-0.1,0.6-0.1
				c1.8-0.1,3.8,0.8,5.6,1.6c0.6,0.3,1.1,0.5,1.5,0.7c0.1,0,0.2,0.1,0.4,0.1c1.7,0.7,4.6,1.7,4.8,4c0.4-0.2,0.9-0.5,1.2-0.7
				c0.4-0.3,0.8-0.5,1.1-0.6c0.2-0.1,0.5-0.1,0.7-0.1l0.1,0c2,0,2.5,2.5,2.7,4.2c0.1,0.3,0.1,0.7,0.2,0.9c0.2,0.6,0.3,0.6,1.3,0.7
				c0.9,0,2.3,0.1,3,1.6c0.4,0.9,0.2,1.7,0,2.3c0,0.2-0.1,0.3-0.1,0.5c0.2,0.1,0.4,0.2,0.5,0.4c1.1,1.5,0.9,3.9,0.8,5.6l0,0.3
				c0,0.5,0.1,1.2,0.2,1.9c0.2,1.3,0.4,2.6,0,3.7c-0.9,2.5-2.6,5.1-3.8,6.8c-1.5,2.2-4.5,4.6-7.3,4.7c-1.4,0.1-3.5-0.4-4.7-3.4
				c-0.8-1.9-0.9-4-1.1-5.9l0-0.2c0-0.3,0-0.6,0-0.9c0-1-0.1-2.5-0.6-3.1c-0.2-0.2-0.3-0.2-0.3-0.3c-0.3,0.2-0.9,2-1,3.7
				c0,0.8-0.2,1.5-0.3,2.3c-0.4,1.8-0.6,3.1,0.7,4.2c0.1,0.1,0.2,0.2,0.3,0.3l0,0c1.2,1,2.7,2.2,2.3,3.5c-0.3,1-1.5,1.2-2.4,1.3
				c-0.5,0-0.9,0.1-1.4,0.1c-0.4,0-0.8,0-1.2,0c-1.7,0-4.3-0.2-6.6-1.7c-0.8-0.5-1.6-0.9-2.5-1.3c-1-0.5-2.1-1-3.2-1.8l-0.3-0.2
				c-1.8-1.3-3.4-2.5-5.5-2.5l-2,0c-2,0-4.1,0-6.2,0.1c-2.2,0.1-4.1,0.3-5.9,0.5c-2,0.3-3.9,0.8-5.8,1.4c-1.6,0.5-3.3,1-5,1.3
				c-0.9,0.2-1.8,0.3-2.7,0.5l-0.3,0.1c-4,0.7-8.1,1.4-11.9,2.7c-2.2,0.7-4.3,1.9-6.3,3.1c-0.9,0.5-1.8,1-2.7,1.5
				c-2.8,1.5-5.6,2.9-8.1,4.2l-0.2,0.1c-1.7,0.9-3.5,1.8-5.2,2.7c-4.7,2.4-9.7,5.1-14.6,8c-2.6,1.5-5.1,3.2-7.6,4.9l0,0
				c-1.8,1.2-3.7,2.5-5.6,3.7c-2.2,1.4-4.6,2.7-6.8,4c-5.3,3.1-12,9.6-16.7,13.6c-1.7,1.5-3.6,3-5.3,4.4c-4.1,3.3-8.3,6.7-11.7,10.6
				c-2.3,2.7-5.3,6.3-7.8,10.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.7,1-1.4,2.1-1.6,3.1c0,0.2,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.6
				c0.6,1.8,0.8,3.4,0.8,4.9c0,2.2-0.8,3.8-1.7,5.6c-0.2,0.3-0.3,0.6-0.5,1c-1,2-1.9,4.1-2.9,6.8c-0.1,0.4-0.3,0.8-0.4,1.1l0,0.1
				c-1.2,3.1-2.6,6.7-2.2,9.8c0.4,3.1,0.6,6.3,0.9,9.4c0.4,4.4,0.7,8.9,1.3,13.2c0.1,0.5,0.2,1,0.2,1.5l0,0.1c0.3,1.9,0.6,4,0.8,6
				c0.2,2.6-0.2,4.8-0.6,7.2c-0.1,0.7-0.2,1.4-0.4,2.1c0,0.3-0.1,0.6-0.1,1c-0.4,2.8-0.8,5.8-2.5,8.3c-1,1.6-2.2,3-3.4,4.4l-0.4,0.5
				c-0.9,1-1.8,2.1-2.6,3l0,0c-2,2.3-4.1,4.7-5.8,7.2c-0.6,0.9-0.6,1.5-0.6,2.7c0.1,3.3-2.3,6-4.4,7.4c-0.2,0.2-0.5,0.3-0.7,0.4
				c-1.1,0.7-1.7,1.1-2,2.2c-0.5,1.7-1,3.4-1.6,5l-0.9,2.5c-1,3-2.1,6.1-3,9.2c0,0.1-0.1,0.3-0.1,0.4l0,0.1c-0.3,1.1-1.1,3.5-0.4,4.3
				c0.4,0.4,0.9,0.7,1.4,0.9c1.2,0.7,3,1.7,2.3,4.4c-0.4,1.6-1.1,3.1-1.7,4.6c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.2-0.2,0.4-0.4,0.7
				c-1.2,2.5-1.1,3-1.1,3c0.8,1.3-0.2,3.5-1.3,5.6c-0.1,0.3-0.3,0.5-0.3,0.6c-0.4,0.8-0.8,1.5-1.1,2.2c-0.9,1.8-1.9,3.6-2.3,5.5
				c-0.1,0.6-0.2,1.3-0.3,2c-0.2,1.5-0.4,3.1-1.3,4.5c-0.6,1-1.3,2.1-2.6,2.6c-0.3,0.1-0.7,0.2-1.1,0.2c-0.2,0-0.5,0-0.7,0
				c-0.2,0-0.4,0-0.5,0c-0.4,0-0.4,0.1-0.5,0.2c-0.7,0.7-0.7,2.2-0.8,3.4c0,0.3,0,0.6,0,0.8c-0.1,1.4-1,2.2-1.7,2.7
				c-0.3,0.2-0.5,0.4-0.7,0.6c-1.8,1.8-2.7,4-2.2,5.4c0.3,0.9,1.3,1.5,2.9,1.7c1.9,0.3,4,0.9,6.6,2.1c0.9,0.4,1.4,1,1.4,1.7
				c0,0.2,0,0.5-0.1,0.7c0.5-0.1,1,0,1.4,0.1c0.8,0.2,1.4,0.7,1.7,1.4c0.6,1.3,0,2.9-0.4,4l-0.1,0.3c-0.5,1.2-0.1,2,0.4,3.1
				c0.3,0.6,0.6,1.3,0.8,2.2c0.4,1.8,0.2,3.3-0.7,4.4c-1.7,2.3-5.4,2.6-8.4,2.8c-0.5,0-0.9,0.1-1.3,0.1c-6.8,0.6-13.6,2.7-20.1,4.7
				l-0.1,0c-5.3,1.6-9.5,4.7-12.8,9.2c-2.1,3-2.9,6.4-3.7,10c-0.1,0.5-0.2,0.9-0.3,1.4c-1,4.6-2,8.9-5.8,13c-2.2,2.4-4.7,4.7-7.1,6.9
				c-1.7,1.5-3.4,3.1-5,4.7c-1.8,1.8-3.9,3-5.9,4.1c-2,1.1-4.1,2.3-5.7,4c-2.9,3-6.8,7.5-6.5,11.3l0,0.4c0.2,3.2,0.4,7.2-1.1,10.1
				c-1.1,2.1-2.7,3.8-4.3,5.4c-0.3,0.3-0.5,0.5-0.8,0.8c-0.5,0.5-1,1-1.4,1.5c-1.5,1.6-3.1,3.3-4.9,4.8c-2.9,2.4-6.3,3.1-9.6,3.7
				c-1.9,0.3-3.8,0.7-5.6,1.4c-2.2,0.8-4.2,2.1-6.2,3.3l-0.5,0.3c-1,0.6-2.1,1.1-3.1,1.6c-1,0.4-1.9,0.8-2.8,1.3
				c-0.4,0.2-0.7,0.5-1.1,0.8c-0.4,0.3-0.9,0.7-1.4,0.9c-1.8,1-3.9,1.2-5.8,1.4l-0.1,0c-1,0.1-2,0.2-2.9,0.2c-2.2,0.1-4.4,0.3-6.5,0.7
				c-2,0.4-4.1,1-6,1.6l-0.2,0.1c-0.5,0.2-1,0.3-1.6,0.5c-3.9,1.1-7.5,1.8-10.7,1.9c-2.8,0.1-5.4-0.1-7.6-0.8c-1.8-0.5-2.6-1.7-3.3-2.8
				c-0.5-0.8-1-1.5-1.9-1.9c-1.4-0.6-3.1-0.8-4.7-1c-1.1-0.1-2.2-0.3-3.3-0.5c-3-0.7-5.9-1.9-8.4-2.9c-0.2-0.1-0.5-0.2-0.7-0.3
				c-0.9-0.4-2-0.9-2.9-0.9l-0.1,0c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.6,0.8-0.8,1.8c-0.1,0.5-0.2,1-0.4,1.5c-1.4,3.3-4.7,3.8-7.6,3.9
				c-0.3,0-0.7,0-1,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.9,0-1.9,0.1-2.7,0.3c-1.2,0.3-2.5,1.1-3.8,1.9c-1.9,1.2-3.9,2.4-6.1,2.5
				c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2-0.1-1.7-0.2c-2.9-0.8-4.8-3.3-6.5-5.4l0,0c-0.2-0.3-0.4-0.5-0.6-0.8c-0.4-0.5-0.9-1.1-1.4-1.6
				c-1-1.1-2-2.3-2.8-3.6c-0.3-0.6-0.3-1.1-0.1-1.4c0.3-0.7,1.1-0.9,1.6-1l0.1,0c1.8-0.4,3.6-1.1,5.3-2.2c3.2-1.9,6.6-4.9,6.4-6.8
				c-0.1-0.9-1.1-1.8-2.9-2.4l-0.2-0.1c-0.5-0.2-1.2-0.5-1.7-0.5l-0.1,0c-0.2,0-0.2,0-0.2,0.3c0,0.3,0,0.5-0.1,0.8l0,0.1
				c-0.1,1.7-0.2,3.3-0.9,5.1c-0.5,1.4-1.4,1.8-2,1.8l-0.1,0c-1.5,0-3-1.8-4.3-3.2c-0.3-0.4-0.6-0.8-0.9-1c-0.9-0.8-1.8-1.7-2.6-2.4
				l0,0c-1.8-1.6-3.6-3.3-5.2-5.1c-0.4-0.5-0.9-1-1.3-1.5c-0.7-0.9-1.5-1.9-2.3-2.6c-0.4-0.3-0.9-0.4-1.6-0.4c-0.1,0-0.2,0-0.4,0
				c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0.1-1.1,0.1l-0.1,0c-0.5,0-1,0-1.5-0.1c-0.6,0-1.1-0.1-1.5-0.1c-0.8,0-1.3,0.2-1.7,1
				c0.6,0.7,1.3,1.7,1.2,2.9c-0.1,0.7-0.5,1.8-2.7,1.9c-1.5,0.1-3.4-0.4-4.5-0.6l-0.1,0c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-1-0.2-1.6-0.4
				l-0.1,0c-1.4-0.3-2.9-0.7-4.1-0.7c-0.3,0-0.6,0-0.8,0.1c0,0,0,0-0.1,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c-0.1,1-0.8,1.3-1.4,1.3
				l-0.1,0c-0.6,0-1.1-0.3-1.6-0.5l0,0c-0.1-0.1-0.2-0.1-0.2-0.1c-0.4-0.2-0.8-0.3-1.1-0.3c-0.7,0-1.2,0.6-1.6,2
				c-0.5,1.8-1.8,2.1-2.5,2.1c-1.4,0-2.7-0.9-3.4-1.8c-0.6-0.7-1-1.5-1.5-2.2c-0.6-1-1.2-1.9-2-2.7c-0.4-0.4-1.2-0.4-2.1-0.4
				c-1.2-0.1-2.5-0.1-3.2-1.2c-1-1.6,1.1-3.5,3.7-5.7c0.3-0.3,0.7-0.6,0.8-0.7c0.4-0.4,0.5-0.7,0.5-0.7c-0.1-0.4-1.8-1-2.4-1.1
				c-2.8-1-5.2-3-7.4-5c-1.5-1.3-2.9-2.5-4.4-3.4c-2.9-1.8-6.7-2.6-10.9-2.5c-1.7,0.1-3.5,0.3-5.2,0.7c-3.9,0.9-4.4,3.7-5.1,7.6
				l-0.1,0.6c-0.6,3-2.7,4.7-6.4,4.9c-1.5,0.1-2.8,0.6-4.2,1c-1.1,0.4-2.2,0.8-3.4,1c-1.2,0.2-2.6,0.4-3.9,0.4c-0.4,0-0.9,0-1.4,0
				c-4.6,0-9.1-0.9-13.3-1.9c-0.7-0.1-1.4-0.2-2.2-0.2c-0.7,0-1.4,0.1-2,0.2c-0.7,0.1-1.4,0.2-2.2,0.2c-0.8,0-1.6,0-2.3-0.1
				c-0.9-0.2-1.6-0.4-2.4-0.7c-1-0.4-2-0.7-3-0.7c-0.3,0-0.5,0-0.7,0.1c-0.6,0.1-1.2,0.3-1.7,0.4l-0.2,0c-3.3,0.8-6.4,1.6-9.8,1.8
				c-1.1,0-2.2,0-3.3-0.1c-2-0.2-9.8-2-11.4-5.4c-0.5-1.1-0.4-2.3,0.3-3.5c0.9-1.4,2.1-2.6,3.2-3.8c0.9-0.9,1.7-1.8,2.5-2.8
				c0.8-1.1,0.9-1.5,0.9-1.6c-0.1-0.1-0.3-0.2-1.2-0.3c-0.6,0-1.5,0.2-2.4,0.4l-0.1,0c-0.9,0.3-1.9,0.5-2.9,0.6c-1,0-1.9-0.2-2.5-0.8
				c-1.7-1.5-1.5-4.9-1.3-6.9l0-0.2c0.1-1.7,0.6-4.2,2.2-6.2c1.7-2.2,4-2.5,6.2-2.8c0.7-0.1,1.3-0.2,2-0.3c2-0.5,4.3-1.3,5.2-1.9
				c-0.2,0-0.4,0-0.6,0c-1,0-2.2,0.3-3,0.6l-0.1,0c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-1,0.2c-1.2,0.3-2.8,0.7-4.1,0.7
				c-1.3,0-2.1-0.2-2.7-0.8c-1.5,1.7-3.2,3-4.9,4c-0.6,0.4-1.3,1.1-2.1,1.9c-1.8,1.8-3.8,3.8-6.1,3.9c-0.8,0-1.7-0.2-2.4-0.7
				c-2.5-1.8-3.1-4.6-3.4-6.8c-0.3-2.2-1.1-3.7-2.2-5.5c-0.3-0.6-0.7-1.2-1-1.8c-3.6-6.7,3.5-12.1,5.8-13.6c1.5-1,3.2-2.1,5.1-2.4
				c0.2,0,0.5-0.1,0.7-0.1c0.8,0,1.5,0.1,2.2,0.2c0.5,0.1,1,0.1,1.4,0.1c2.3,0.1,4.4-0.3,6.3-1c1.6-0.7,2.9-1.8,4.1-2.9
				c0.8-0.7,1.6-1.5,2.6-2.1c1.1-0.8,2.2-1.1,3.3-1.2c1.1,0,2.2,0.2,3.2,0.5c1.1,0.3,2.1,0.6,3.2,0.5c0.3-0.1,2.6-1.8,3.5-2.6
				c0.9-0.7,1.6-1.2,2.2-1.7c-1.4-0.2-2.8-0.6-4.1-0.9c-1.1-0.3-2.1-0.4-2.9-0.4c-1.3,0.1-2.6,0.5-4,1.3c-0.8,0.5-1.5,1.1-2.3,1.7
				c-1.4,1.1-2.8,2.2-4.5,2.8c-1.1,0.3-2.1,0.4-3.1,0.5c-0.6,0-1,0.1-1.5,0.1c-0.3,0-0.7,0.1-1,0.2l-0.1-0.2l0,0l0,0.2
				c-0.8,0.1-1.7,0.3-2.6,0.3c-0.5,0-0.9,0-1.4,0c-2.9-0.3-4.2-2.7-5.3-4.8l-0.2-0.3c-1-1.9-1.4-2.1-1.6-2.1c0,0-0.1,0-0.4,0.2
				c-0.9,0.9-1.7,2-2.4,3.2c-0.9,1.4-1.9,2.8-3.2,4c-0.5,0.4-1.4,1.1-2.2,1.2l-0.1,0c-0.5,0-0.9-0.2-1.2-0.6c-0.4-0.6-0.4-1.3,0.1-2.5
				c0.5-1.2,1.1-2.3,1.6-3.3c0.5-0.9,1-1.8,1.4-2.7c0.4-0.8,0.5-1.6,0.7-2.5c0.3-1.3,0.6-2.6,1.4-3.9c0.6-1,1.5-2.1,2.6-2.7
				c0.4-0.2,0.8-0.3,1.1-0.3c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.7-0.6,1.4-1.2,2.3-1.5c0.5-0.2,1.1-0.3,1.7-0.3
				c0.4,0,0.8,0,1.2,0c0.6,0,1.1,0,1.4,0c0.5,0,1-0.1,1.5-0.2c0.9-0.2,1.8-0.3,2.7-0.4c1.2,0,2.1,0.1,2.9,0.5c0.8,0.4,1.3,1.2,1.7,2.2
				c-0.4-1.6-0.5-3.2,0.4-4.6c-0.4,0.1-0.7,0.1-1.1,0.1c-1.7,0.1-3.3-0.4-4.6-1.5c-1.6-1.4-2.7-4.1-2.8-6.7c-0.1-2,0.6-3.8,1.7-4.8
				c0.8-0.7,1.7-0.8,2.4-0.9c0.3,0,0.6,0,0.8-0.1c0.1,0,0.2-0.2,0.4-1.5c0-0.1,0-0.2,0-0.3c0.3-2.1,0.5-4.2,0.7-6.3
				c0.4-3.8,0.7-7.8,1.9-11.5c1.3-4.1,4.7-7,8.5-7.2c0.4,0,0.8,0,1.2,0c3.2,0.3,6.4,0.9,9.6,1.4c0.9,0.2,1.7,0.4,2.5,0.6
				c-0.1-0.5-0.1-1,0.1-1.5c0.2-0.6,0.6-1,1.1-1.4c-0.2,0.1-0.3,0.1-0.5,0.2c-1.3,0.5-2.8,0.8-4.4,0.8c-0.7,0-1.5,0-2.2-0.1
				c-2.4-0.2-4.2-1.9-5.9-3.3c-0.4-0.3-0.8-0.7-1.2-1c-2.7-2.2-1.2-5.4-0.2-7.5c1.5-3.1,3.4-6.3,6-9.9c3.2-4.5,6.3-9.2,9.2-13.7
				c0.8-1.2,1.5-2.4,2.3-3.6c0.9-1.4,1.8-2.8,2.7-4.2l0.1-0.1c2.2-3.3,4.4-6.8,6.7-10.2c0.7-1,1.4-2.1,2.2-3c1.3-1.7,2.5-3.5,3.7-5.4
				c0.1-0.1,0.1-0.2,0.2-0.4l0-0.1c0.8-1.6,1.8-3.1,3.1-3.2l0.1,0c0.2,0,0.5,0.1,0.7,0.2c0.3,0.1,0.7,0.5,1.3,1.2c0.3-1.4,1-2.8,1.9-4
				c1.9-2.3,4.8-3.5,6.9-4.2c2.2-0.8,4.4-1.3,6.3-1.5c0.2,0,0.5,0,0.7,0c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1-0.9-2.1-1-3.3
				c-0.1-0.9,0-1.6,0.4-2.1c0.6-0.8,1.6-0.9,2.5-0.9l0,0c0.2,0,0.5,0,0.7-0.1c0.3,0,0.4-0.3,0.5-1.6c0-0.3,0-0.5,0-0.7
				c0.2-2.2,0.7-6.7,4.4-7.3c0.6-0.1,1.4,0.2,2.1,0.5c0.2,0.1,0.5,0.2,0.7,0.3c0.2-0.6,0.2-1.3,0.2-2c0-0.8,0.1-1.6,0.3-2.3
				c0.6-2.1,1.8-2.6,2.9-3c0.4-0.1,0.7-0.3,1.2-0.5c1.4-0.8,2.5-2.1,3.6-3.4c0.4-0.5,0.9-1,1.3-1.5c0.7-0.8,1.7-1.6,2.9-1.7
				c0.9,0,1.8,0.3,2.7,1.2c0.1,0.1,0.2,0.2,0.3,0.3l0,0c1.9,1.8,3,2.6,3.6,3c-0.4-0.7-1-1.6-1.2-2c-0.4-0.6-0.7-1-0.8-1.3
				c-0.9-2-0.8-4.3-0.8-6.3l0-0.3c0.1-5.4,1.9-12.9,6.6-17.8c1.3-1.4,2.9-2.4,4.5-3.1c0.3-0.1,0.6-0.2,0.9-0.2c0,0,0.1,0,0.1,0
				c1.8,0,3.5,1.5,4.5,2.5l0.1,0.1c2.1,1.9,3.6,3.9,4.5,6c-0.2-0.4-0.3-0.8-0.5-1.2c-1-2.4-1.9-4.2-2.9-5.9c-0.4-0.7-0.9-1.2-1.4-1.7
				c-0.4-0.4-0.9-0.9-1.3-1.4c-1.2-1.6-1.9-4.3-1.2-6.2c0.6-1.4,2.6-1.6,4.3-1.8c0.5,0,0.9-0.1,1.1-0.2l0.3-0.1
				c1.1-0.3,3.2-0.8,3.6-1.7c0.1-0.2-0.2-0.8-2.8-2.4c-0.5-0.3-0.8-0.5-1.1-0.7c-0.4-0.3-0.9-0.8-1-1.5c0-0.8,0.5-1.3,0.9-1.7
				c0.1-0.1,0.3-0.3,0.4-0.4l0.1-0.1c0.6-0.7,1.2-1.4,1.9-2.1c0.7-0.6,1.4-1.3,2-1.9c1.6-1.4,3.1-2.8,4.4-4.5c0.3-0.4,0.6-1,0.9-1.6
				c0.6-1.2,1.3-2.4,2.4-3.2c0.8-0.6,1.7-0.7,2.5-0.8c1.1-0.1,1.6-0.2,1.8-0.8c0.4-0.7,0.5-1.5,0.7-2.3c0.3-1.4,0.5-2.9,1.8-4.1
				c0.3-0.2,0.5-0.5,0.7-0.7c1-0.9,1.5-1.3,1.6-2.6c0.2-2.3,1.4-4.5,2.5-6.3c0.2-0.3,0.4-0.6,0.5-0.9c0.9-1.7,2.2-2.6,4.3-3.1
				c0.9-0.2,1.8-0.4,2.7-0.7l0.1,0c1.8-0.4,3.7-0.9,5.5-1.4c2.1-0.7,4.9-1.6,7.5-3c0.3-0.2,0.7-0.5,1.2-0.9c1-0.8,2.2-1.8,3.4-1.8
				c0.4,0,0.7,0.1,1.1,0.2c0.5,0.2,1,0.5,1.4,0.8c1.1,0.6,1.9,1.1,3.1,0.9c2-0.3,3.9-0.9,5.9-1.6c4.5-1.6,9.6-3.5,12.9-6.8
				c3.3-3.2,6.8-5.7,10.5-8.3c0.8-0.5,1.5-1.1,2.3-1.6c3.8-2.7,6.9-6,10.1-9.5l0,0c0.8-0.9,1.7-1.9,2.6-2.8c3.4-3.6,8-8.2,13.1-12.4
				c1-0.8,2-1.5,2.9-2.3l0.1-0.1c1.2-0.9,2.4-1.9,3.6-2.9c2.6-2.2,5.6-4.7,9.3-6c1.7-0.6,3.2-1.5,4.8-2.5c1-0.6,2-1.2,3-1.7
				c0.7-0.4,1.8-0.9,3-0.9c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0.1,0.9,0.2c0.4,0.1,0.7,0.1,0.9,0.1c0.2,0,0.2,0,0.2,0
				c0.7-0.5,1.2-1.5,1.8-2.5c0.6-1,1.1-2,1.9-2.8c1.1-1,2.1-1.2,2.9-1.3c0.1,0,0.3,0,0.4,0l0.5,0c0.1,0,0.2,0,0.3,0
				c0.4,0,0.7-0.1,1-0.2c1-0.4,1.7-2.6,2.2-4.1c0.2-0.7,0.5-1.4,0.7-1.9c1.6-3.3,3.5-6.4,5.7-9.3c1.6-2.1,3.7-4.5,6.3-6.1
				c1.8-1.1,3.8-1.9,5.7-2.7c0.8-0.3,1.7-0.7,2.6-1.1c5.6-2.5,8.9-7.1,12.5-11.9l0.1-0.1c0.3-0.4,0.6-0.9,1-1.3c1.4-1.9,3-3.6,4.6-5.3
				c2.3-2.5,4.7-5.1,6.3-8.1c0.5-0.9,0.9-1.9,1.3-2.8c1.7-3.8,3.2-7.4,8.7-8c3-0.3,4.7-2.8,6.4-5.4c0.6-1,1.3-2,2.1-2.9
				c1.9-2.2,4.3-3.1,6.7-3.9c0.9-0.3,1.8-0.6,2.7-1c11.2-4.9,19.9-10.6,26-16.9c3.1-3.2,5.6-7.1,8.1-10.9l0.1-0.1
				c0.6-0.9,1.2-1.9,1.8-2.8c0.7-1.1,1.5-2.2,2.2-3.3l0.1-0.1c2.6-3.9,6.5-11.2,9.3-15c1-1.3,2-2.6,3-3.8c1.8-2.2,3.7-4.5,5.2-7
				c2.8-4.8,5.1-9.6,6.9-14.3c0.6-1.6,1-3.3,1.4-4.9c0.4-1.9,0.9-3.8,1.7-5.7c1-2.6,2.2-5.3,3.4-7.8c1.5-3.3,3-6.7,4.3-10.2
				c1.6-4.6,1.4-9.3,1.1-14.2c-0.1-1.3-0.1-2.6-0.2-3.9c-0.1-3,0-6.8,1.7-10.1c0.7-1.4,1.5-2.6,2.4-3.8c0.7-1,1.4-2,2-3.1
				c0.9-1.6,1.1-3.5,1.3-5.4c0.1-0.9,0.2-1.9,0.4-2.9c0.4-2.2,1.2-4.2,1.9-6.2c0.3-0.7,0.5-1.4,0.8-2.1c1.1-3.2,0.7-6.2,0.2-9.5
				c-0.3-1.8-0.5-3.7-0.5-5.6c0-2.9,2.1-3.6,3.9-3.9c0.5-0.1,1-0.2,1.5-0.2l0.1,0c2.1-0.3,4.2-0.6,5.8-1.8c1.3-1,2.3-1.6,3.5-1.7
				c0.8,0,1.6,0.2,2.6,0.7c0.6,0.3,1.1,0.4,1.7,0.4c1.3-0.1,2.7-0.9,3.8-1.8c2.2-1.7,4.2-3.6,6.2-5.4c2.1-1.9,4.3-3.9,6.6-5.7
				c3.9-3,7.1-6.8,9.7-11.5c1.5-2.7,2.8-5.5,4-8.3c1.4-3.1,2.8-6.3,4.6-9.3c0.6-1,1.2-2,1.8-3c1.5-2.4,3-4.9,4-7.5
				c1.5-3.6,3.1-8.4,2.8-13.4c-0.2-3-0.2-6.1-0.3-9.1c0-1,0-2.1-0.1-3.1l0-2c-0.1-3.7-0.2-7.6-0.4-11.3c-0.2-2.7-0.7-5.4-1.1-8
				c-0.3-1.7-0.6-3.4-0.8-5.1c-0.3-2.4-0.7-5-0.8-7.7c-0.1-3.1,1.7-5.1,3.4-7l0.3-0.4c0.6-0.7,1.1-1.5,1.7-2.4c0.7-1.1,1.4-2.2,2.4-3.2
				c1.6-1.5,3.5-2.6,5.3-3.7c0.6-0.3,1.2-0.7,1.7-1c5.9-3.7,11-8.3,15.1-13.8c0.3-0.5,0.7-0.9,1-1.4l0.1-0.1c2.6-3.5,5.2-7.1,9.3-9.2
				c1.1-0.6,2.2-0.7,3-0.7c0.4,0,0.9,0,1.3,0l0.1,0c0.4,0,0.7,0,1.1,0l0.2,0c2-0.1,4.3-0.8,6.6-2.1c0.7-0.4,1.3-0.8,1.7-1.1
				c-0.7-0.2-1.5-0.2-2.5-0.2c-0.7,0-1.4,0.1-2.1,0.2c-0.7,0.1-1.5,0.1-2.1,0.2c-0.5,0-0.9,0-1.3,0c-1-0.1-1.6-0.4-1.7-1.1
				c-0.2-1.1,1-1.8,2.5-2.6c0.3-0.2,0.7-0.4,0.8-0.4c1.2-0.9,2.2-1.9,3.3-2.9c1-0.9,1.9-1.9,3-2.7c1.4-1.1,3-1.6,5.1-1.7
				c0.9,0,1.8,0,2.7,0c0.5,0,1.1,0.1,1.6,0.1c4.1,0.1,8.5,0.1,14.1,0.3c0.8,0,1.5,0,2.3,0c3.3,0,6.8,0,9.9,1.3l0.1,0
				c4,1.6,5.5,2.8,5.3,4.2c-0.1,0.5-0.5,1.1-1.4,1.1c-0.9,0-2.2-0.4-3.6-0.9c-0.4-0.1-0.8-0.3-0.9-0.3c-3.9-0.9-8-1.4-11.5-1.8
				c-0.5,0-1-0.1-1.6-0.2c-1.7-0.2-3.6-0.4-5.4-0.3c-1.4,0.1-2.5,0.3-3.3,0.7c-0.8,0.4-1.8,1.8-2.5,3c-0.2,0.4-0.5,0.8-0.8,1.2
				c-1.7,2.3-2.8,4.3-3,7.1c-0.1,1-0.6,2.1-1.1,3.1c-0.6,1.1-1.2,2.3-0.9,3.1c0,0.1,0.1,0.2,0.8,0.3c0.8,0.1,2.3,0.3,2.8,2.1
				c0.2,0.9,0.3,1.9,0.4,2.8c0.1,1.7,0.3,3.4,1.4,4.6c1.1,1.2,2.4,2.4,3.6,3.5c0.4,0.3,0.7,0.7,1.1,1c1.9,1.7,3.7,3.4,5.8,4.5
				c0.9,0.5,1.8,0.8,2.8,1.2c1.2,0.4,2.4,0.9,3.5,1.6c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.5,1.3-1c0.5-0.6,1.1-1.2,1.9-1.6
				c0.5-0.2,0.9-0.2,1.3-0.2c0.2,0,0.5,0,0.6-0.1c1-0.3,1.7-1.1,2.3-2.2c0.9-1.8,2-2.8,3.1-2.8l0.1,0c0.8,0,1.5,0.5,2.2,1.4
				c0.9,1.4,1.1,3,1.2,4.5c0.1,0.8,0.1,1.5,0.3,2.2c0.2,0.7,0.5,0.9,2,0.9c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c1,0,2.1,0.1,2.8,0.9
				c0.6,0.6,0.8,1.5,0.6,2.7c-0.1,0.9-0.3,1.8-0.5,2.6c-0.3,1.2-0.5,2.4-0.5,3.6c0,0.3,0,0.6,0,0.9c0.1,2.3,0.1,4.5-1.4,6.7
				c-0.3,0.5-0.6,0.9-0.9,1.3c-1.2,1.6-1.9,2.5-1.3,4.5c0.3,1.1-0.1,2.3-0.5,3.7c-0.3,1-0.7,2.6-0.3,2.9c2,1.9,1.8,3.8,1.5,6.1
				l-0.1,0.6c-0.3,2.4,0.7,3.8,2.9,5.8c0.3,0.3,0.7,0.6,1,0.8c1.4,1.2,2.7,2.3,3.3,4.6c0.1,0.4,0.2,0.9,0.3,1.4
				c0.3,1.5,0.6,3.4,1.5,3.8c1.9,1,1.7,2.1,1.5,3c-0.1,0.4-0.2,0.6,0,1c0,0,0.2,0.1,0.8,0.2c0.4,0.1,0.8,0.2,1.1,0.4
				c1.1,0.6,2,1.4,2.9,2.2c0.2,0.1,0.3,0.3,0.5,0.4l0.3,0.3c0.8,0.7,1.6,1.4,2.3,1.4l0,0c0.2,0,0.4-0.1,0.7-0.3
				c1.7-1.2,3.1-2.9,4.5-4.6c0.4-0.5,0.8-1,1.2-1.5c0.2-0.3,0.5-0.5,0.7-0.8c1.5-1.8,3-3.4,4-5.6c1.1-2.3,2.7-5.2,6-6.9
				c1.9-1,3.9-1.6,5.8-2.2l0.1,0c0.4-0.1,0.8-0.2,1.1-0.3c0.3-0.1,0.5-0.2,0.8-0.2c1.7-0.5,3.6-1.1,4.6-2.2c0.2-0.3,0.3-0.6,0.4-1.1
				c0.1-0.3,0.1-0.6,0.2-1c0.4-1.2,1.1-2.4,2.2-3.7c0.4-0.5,0.9-1,1.4-1.5c0.5-0.5,0.9-0.9,1.3-1.4c1.2-1.5,2.5-3,4.2-3.8
				c0.5-0.2,0.9-0.4,1.4-0.4c0,0,0.1,0,0.1,0c2.3,0,3.3,2.7,3.5,5.1c0,0.1,0,0.4,0.8,0.4c0,0,0.1,0,0.1,0c0.4,0,0.9-0.1,1.2-0.3
				c0.6-0.4,1.1-0.8,1.7-1.2c0.4-0.3,0.8-0.6,1.2-0.8c-0.1,0-0.1,0-0.2,0c-0.9-0.1-1.6-0.7-1.9-1.6c-0.6-1.5,0-3.8,0.8-4.8
				c1.6-1.8,3.2-2.7,5-3.8l0,0c0.4-0.3,0.9-0.5,1.3-0.8c0.2-0.1,0.4-0.3,0.6-0.4c0.6-0.4,1.3-1,2.1-1.1c0.4-0.1,0.7-0.1,0.9-0.1
				c0.6,0,0.8,0,1.2-0.4c1.8-1.7,2.7-3.1,2.8-3.6c-0.3-0.1-0.9-0.1-1.4-0.1l-0.7,0c-1.3,0-2.6-0.1-3.1-1c-0.5-1,0.2-2,0.4-2.3
				c0.8-1.2,1-2,0.6-2.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.8-1.4-2.1-0.7-3.3c0.3-0.5,0.9-0.9,2.3-1.7l0,0c0.1,0,0.2-0.1,0.3-0.1
				c-0.3-0.6-0.3-1.2-0.2-1.6c0-0.5,0.1-0.9,0.1-1.4c0.1-0.7,0.1-1.2,0.1-1.8c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.5-0.2-1.2,0.2-2.2
				c0.5-1.1,1.1-2.2,1.7-3.1l0.3-0.5c0.1-0.1,0.2-0.4,0.4-0.7l0,0c1.6-3.1,2.7-4.8,4.1-4.9l0.1,0c0.4,0,0.8,0.2,1.1,0.5
				c1.2,1.1,0.9,3.2,0.6,4.9c0,0.2,0,0.3-0.1,0.5c0.3-1,0.6-1.9,1.2-2.7c0.7-0.9,1.6-1.4,2.6-2c1-0.6,2-1.2,2.2-2
				c0.4-1.3,0.3-2.8,0.2-4.2l-0.1-0.8l0.8-0.2c0.6-0.2,1.3,0,1.7,0.4c0.8,0.8,0.8,2.3,0.8,3.2v0.1c0,0.9-0.1,1.7-0.1,2.6
				c-0.1,1.3-0.2,2.7-0.1,3.9c0.1,0.5,0.1,0.9,0.2,1.3c0.2,1.4,0.4,2.6-0.5,4.5c-1.1,2.1-2.5,4.5-4.4,7.2c-0.9,1.2-2,2.4-3.4,3.5
				c-0.4,0.3-0.9,0.6-1.3,0.9c-0.8,0.5-1.5,1-2,1.6c-0.5,0.7-0.3,2.1-0.2,3.5c0.1,1.2,0.3,2.4,0.1,3.4c-0.6,2.4-1.4,3.9-3,5.4
				c-0.7,0.7-1.1,1.4-1.2,2.2c0,0.3,0,0.7,0.1,1.1c0.1,0.9,0.2,1.9-0.3,2.9c-0.3,0.6-1,1.3-2.3,1.5c0.4,0.5,0.5,1.1,0.3,1.9
				c-0.1,0.9-0.4,1.7-0.6,2.5c-0.2,0.6-0.3,1.2-0.4,1.8c0.1-0.1,0.3-0.2,0.4-0.4c0.5-0.6,1-1.2,1.6-1.9c1.3-1.7,2.5-3.4,4.7-4.1
				c0.5-0.1,1.1-0.3,1.8-0.3c0.9,0,3.2,0,4.3,1.3c0.5,0.6,0.7,1.4,0.6,2.2c-0.2,1.2-1,1.9-1.7,2.5c-0.3,0.3-0.6,0.5-0.7,0.7
				c-0.2,0.3-0.4,0.6-0.5,1c-0.2,0.5-0.5,1.2-1.1,1.7c-0.9,0.9-2,1.6-3,2.2c-1.3,0.8-2.5,1.6-3.4,2.7c0,0,0,0,0,0.1c0.1,0,0.2,0,0.4,0
				c0.5,0,1.2-0.1,1.8-0.2c0.7-0.1,1.5-0.2,1.9-0.1c1.6,0.2,2.9,0.8,3.6,1.9c0.1,0.2,0.2,0.3,0.3,0.5c0-0.6,0-1.2,0.1-1.7l0-0.2
				c0-0.2,0-0.5,0-0.7c0-0.9,0.1-1.9,0.4-2.8c0.5-1.2,1.2-1.8,1.9-2.5c0.3-0.2,0.5-0.5,0.8-0.7c1.2-1.3,2.1-2.6,2.5-4.4
				c0.3-1.1,1.7-1.9,2.8-2c0,0,0,0,0,0c-0.3-0.4-0.5-0.9-0.7-1.3c-0.2-0.6-0.4-0.9-0.7-1c-0.7-0.3-1.5-0.6-1.7-1.5
				c-0.2-0.8,0.3-1.5,0.9-2.1l0.2-0.2l0.2-0.1c0.3-0.1,0.7-0.1,1.1-0.2c0.5,0,0.7-0.1,0.8-0.1c0.1-1.9,0.6-3.8,1.7-5.7
				c0.5-0.9,1.4-2.1,2.4-2.1l0.1,0c0.5,0,1.3,0.2,1.6,1.5c0.2,0.7,0.8,3,0.4,4.4c-0.3,1-0.8,1.9-1.3,2.8c-0.5,0.8-0.9,1.6-1.1,2.5
				l0,0.1c-0.6,2.4-1.3,3.6-2.4,3.8c0.8,1.2,0.3,2.8-0.1,3.8l0,0.1c-0.1,0.3-0.2,0.6-0.4,1c-1,2.1-1,2.7-0.9,2.8c0,0,0.1,0,0.3,0
				c1.1,0,1.4-0.5,1.9-1.5c0.4-0.8,0.9-1.6,1.9-2.2c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.6,0,0.8,0l0,0.2l0,0l0-0.2c0.2,0,0.3,0,0.4,0
				c0-0.7-0.3-1.6-0.6-2.4c-0.6-1.3-1.5-3.4,0.2-5.2c0.2-0.2,0.3-0.3,0.5-0.5c0.4-0.3,0.5-0.5,0.6-0.8c0.5-2.1,0.3-4.4,0.1-6.7l0-0.2
				c0-0.3,0-0.6-0.1-0.9c-0.1-1.8-0.1-3.5,1.2-5.3l0-0.1c0.9-1.2,1.7-2.1,2.6-2.3c0.4-0.1,0.8,0,1.2,0.4c0.9,1,0.5,4.9,0.4,5.4
				c-0.1,0.9-0.3,1.6-0.6,2.4c-0.5,1.7-0.9,2.9,0.2,4.3c0.1,0.2,0.3,0.4,0.5,0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.2-0.4-0.2-0.5
				c-0.6-2-0.6-3.3-0.1-4.1c0.3-0.4,0.8-0.7,1.3-0.6c1,0.1,1.9,0.8,2.6,1.4l0,0c0.2,0.2,0.3,0.3,0.5,0.4c0.1,0.1,0.2,0.1,0.2,0.2
				c0.3,0.2,1,0.8,1.3,0.8c0.1-0.1,0.2-0.4,0.3-0.6c0.1-0.3,0.2-0.6,0.4-0.9c0.5-0.8,1.1-1,1.5-1l0.1,0c1,0,1.8,0.9,2.1,1.7
				c0.3,0.7,0.5,0.9,1,1.3c0.2,0.2,0.4,0.3,0.7,0.6c0.5,0.5,0.7,1,0.6,1.5c-0.1,1-1,1.5-1.9,2c-0.8,0.5-1.1,0.7-1.2,0.8c0,0,0,0,0,0.1
				c0.7,0.9,1.7,1.4,2.7,1.4l0.1,0c0.8,0,1.6-0.4,2.2-1.1c0.1-0.1,0.1-0.2,0.2-0.3l0.1-0.1c1.1-1.5,2.5-3.2,3.8-3.3l0.1,0
				c0.4,0,1.1,0.1,1.6,1.1c1,1.9,0.4,4.2-1.6,6.5c-0.5,0.6-0.8,1.4-1,2.2c-0.1,0.2-0.1,0.4-0.2,0.5c-0.3,0.8-0.7,1.1-1.1,1.4
				c-0.1,0.1-0.2,0.1-0.3,0.3c-0.3,0.2-0.6,0.7-1,1.2c-1,1.3-2.2,3-3.9,3.1c-0.3,0-0.6,0-0.9-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
				c0.4,0.4,0.8,0.7,1.4,1.1c0.8,0.6,1.2,1.3,1.1,2c-0.1,1.4-1.6,2.2-2.9,2.9c-0.6,0.3-0.9,0.5-0.9,0.5c0,0.1-0.1,0.3-0.1,0.4
				c-0.1,0.3-0.2,0.6-0.3,0.9c-0.8,1.3-2.3,1.7-3.4,1.8c-0.9,0.1-1.8,0.5-2.7,0.9c-1.2,0.5-2.4,1.1-3.9,1.1c-0.5,0-1.4,0.3-2.3,0.7
				c-1.4,0.5-2.8,0.9-3.9,1c-0.5,0-0.9,0-1.3-0.2c-0.1,0.9-0.6,1.7-1.5,2.5c-0.6,0.5-1.2,0.9-1.7,1.3c0.2,0,0.4,0,0.7,0
				c0.8,0,1.9,0.1,2.9,0.9c0.8,0.6,1.3,0.8,2,0.6c0.5-0.1,0.9-0.6,1.4-1.2c0.1-0.2,0.3-0.3,0.4-0.5c0.8-0.9,1.7-1.1,2.6-1.3
				c0.4-0.1,0.8-0.2,1.2-0.3l0.3-0.2c2-1,3.5-1.8,5.4-1.9c1.2,0,2.4,0.2,3.8,0.8c0,0,0,0,0,0c0-0.2,0-0.4,0-0.7
				c0.3-2.1,2.9-4.7,4.3-5.2c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0,1.4,0.1c0.3,0,0.6,0.1,0.9,0.1l0.1,0c0.3,0,0.7,0,0.9-1.1
				c0.8-3.1,3.6-3.7,6.1-4.1c0.6-0.1,1.5,0,2.1,0.1c0.4,0.1,0.8,0.1,1.1,0.1c0.4,0,0.8-0.1,1.2-0.8c0.8-1.4,1.1-3.3,1.3-5.1
				c0.1-0.9,0.2-1.8,0.4-2.7c0.1-0.4,0.6-2.3,1.9-2.4l0.1,0C1275.4,1705.5,1276,1706.1,1276.2,1707.2z M1229.2,1721.8l-1.1,0.4
				c1.5,0.2,3.2,0.5,3.5,0.6c0.9,0.3,1.6,0.8,2.1,1.3c0.6-0.8,2.1-1.4,5-2.1l0.1,0c0.1,0,0.2,0,0.2-0.1c1.9-0.6,3.7-1.6,5.4-2.5
				c0.4-0.2,1.1-0.7,1.7-1.1c-0.5,0-1.1,0-1.7,0l-1,0c-0.3,0-0.5,0-0.7,0l-0.1,0c-0.3,0-0.5,0-0.8,0c-0.5,0-0.9,0-1.3,0
				c-1,0-1.8,0.2-2.5,0.7l-0.2,0.1c-0.9,0.6-1.9,1.2-3.1,1.4c-0.7,0.1-1.4-0.1-1.8-0.3c-0.3-0.1-0.5-0.2-0.7-0.2l-0.1,0
				c-1,0.1-2,0.7-3,1.3c-0.3,0.2-0.5,0.3-0.8,0.5L1229.2,1721.8z M1253.3,1713c-0.5-0.8-0.5-1.9-0.4-3c-0.1,0.2-0.1,0.3-0.1,0.4
				C1252.5,1711.4,1252.7,1712.2,1253.3,1713z M1209.4,1703L1209.4,1703c-0.5,0-0.8,0.2-1.2,0.5c0.5-0.2,0.9-0.3,1.4-0.4
				c0.1,0,0.2,0,0.2-0.1C1209.7,1703.1,1209.5,1703,1209.4,1703z M1216.9,1729.8c0.5-0.1,1-0.2,1.5-0.4c0.3-0.1,0.5-0.1,0.8-0.2
				c-0.6,0-1.2-0.1-1.8-0.1c-1.1-0.1-2.4-0.2-3.6-0.2c-0.2,0-0.4,0-0.7,0c-1.8,0.1-2.1,0.4-2.1,0.5c0,0,0,0,0,0.2
				c0.4,1.6,1.6,1.8,4,1.9c0.4,0,0.7,0.1,1.1,0.2c-0.2-0.3-0.3-0.5-0.3-0.9C1215.9,1730.6,1216.1,1730,1216.9,1729.8z M1223.6,1721.8
				c0,0.5-0.1,1-0.2,1.5c-0.1,0.4-0.1,0.9-0.1,1.5c0,1.2-0.1,2.6-0.9,3.6c0.8-0.3,1.6-0.8,2.3-1.3c1.3-1,1.2-1.2,0.4-2.8l-0.2-0.4
				c-0.2-0.3-0.2-0.7,0-1c-0.3-0.1-0.5-0.3-0.7-0.5C1223.9,1722.2,1223.7,1722,1223.6,1721.8z M1190.5,1906.8L1190.5,1906.8
				L1190.5,1906.8L1190.5,1906.8z M1115,2046.1c0,0,0.1,0,0.3,0l0.1,0c1.1,0,2.8-1.1,3.8-1.7c0.2-0.1,0.4-0.2,0.6-0.3
				c0.8-0.4,1.6-0.9,2.3-1.4c0.8-0.5,1.6-1,2.4-1.5c-0.6,0.1-1.2,0.1-1.7,0.2c-2.6,0.3-5.4,0.6-7.3,1.9c-0.4,0.3-0.7,0.9-0.7,1.5
				C1114.6,2045.2,1114.6,2045.8,1115,2046.1z M927.9,2352.8c-0.6,0.5-0.9,0.9-1.1,1c0.1,0,0.2,0,0.3,0l0,0c1.1,0,2.3-2,3-3.2
				c0.2-0.3,0.4-0.6,0.6-0.9c1.4-2,3-3.9,4.8-5.5c-1.4,0.7-2.6,1.6-3.7,2.7c-0.7,0.8-1.2,1.6-1.7,2.6c-0.5,0.8-1,1.7-1.6,2.4
				C928.5,2352.3,928.2,2352.5,927.9,2352.8z M670.9,2190c0.1,0.6,0.2,1.2,0.3,2c0.1,1.3,0.3,3,1,3.4
				C672.2,2194.6,671.6,2192.3,670.9,2190z M588,2309c-0.2,0.1-0.3,0.2-0.5,0.2c1.2-0.3,2.4-0.4,3.6-0.4c1.5-0.1,2.9-0.2,4.2-0.7
				c0.6-0.2,1.6-1.3,1.7-1.8c0-0.1-0.1-0.1-0.3-0.2c-0.2-0.1-0.5-0.2-0.8-0.2c0,0-0.1,0-0.1,0c-1.3,0.1-2.9,0.8-4.1,1.4l-0.1,0
				c-0.3,0.1-0.5,0.2-0.7,0.3C589.9,2308.1,588.9,2308.5,588,2309z M561.1,2358.9c0.1,0.5,0.2,1,0.3,1.5c0.1,0.4,0.1,0.8,0.2,1.1
				c0,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.1,0.7,0.2,0.9c0-0.4,0-0.9-0.1-1.3c-0.1-0.8-0.3-1.8-0.6-2.8C561.1,2359,561.1,2359,561.1,2358.9z
				M720.7,2522.2c-0.5-4-2.2-4.8-3.5-4.8c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.1-0.9,0.2c-1,0.3-1.9,1-2.8,1.6c-1,0.7-2,1.4-3,1.5
				c-0.3,0-0.7-0.3-0.8-0.6c-0.1-0.2,0-0.2,0-0.3c0.4-0.5,1.1-0.7,1.8-1c0.3-0.1,0.5-0.2,0.8-0.3c1-0.4,1-1.1,0.9-1.4
				c-0.2-1-1.5-1.1-2.5-1.2l-0.1,0c-0.4,0-0.8-0.1-1.3-0.1c-1.5-0.1-3-0.2-4.4-0.7c-0.3-0.1-1-0.7-1.4-1.1c-0.9-0.8-1.8-1.5-2.6-1.5
				l-0.1,0c-0.1,0-0.2,0-0.3,0c-1,0.2-1.8,0.8-2.6,1.6c0-0.1,0-0.2,0-0.2c0-0.9-0.7-1.6-1.5-2.4c-0.1-0.1-0.3-0.3-0.5-0.5
				c0,0,0.1,0,0.1,0h0l0.1,0c1.6-0.1,2.1-1.9,2.3-3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.1c0.1,0,0.2,0.1,0.4,0.3
				c0,0,0.1,0.2,0.1,0.3c0.1,0.3,0.3,0.7,0.7,1c1.1,1,3,1.2,4.3,1.2c0.5,0,1.1,0.1,1.7,0.2c1.1,0.1,2.3,0.2,3.4,0.2
				c1.3-0.1,1.8-0.8,2.1-1.3c0.6-1.4-0.1-3.4-0.9-4.4c-1.5-2-3.6-3.4-5.6-4.8c-1.7-1.1-3.4-2.3-4.7-3.8c-1.1-1.3-2.1-3-3-4.5
				c-1.5-2.7-3.1-5.4-5.9-7.2c-1.4-0.9-2.9-1.3-4.6-1.3c-0.6,0-1.2,0.1-1.8,0.2c-0.7,0.1-1.3,0.4-1.9,0.6c-0.9,0.3-1.8,0.6-2.6,0.6
				c-0.3,0-0.7,0-1-0.1c-0.6-0.2-1.3-0.2-2-0.2c-2.6,0.1-5.9,1.5-7.1,3.9c-1.3,2.5-1.2,4.5-0.8,6.8c0.1,0.5,0,1.1-0.1,1.6
				c-0.1,0.8-0.2,1.6,0,2.5c0.3,1.2,1.1,2.1,1.9,2.9c0.2,0.3,0.5,0.5,0.7,0.8c1.6,2,3.3,4.4,4,7c0.1,0.2,0.1,0.5,0.2,0.7
				c0.5,1.7,1.3,4.7,0.8,6c-0.6,1.7-2.5,2.4-4.4,3c-0.5,0.2-0.9,0.3-1.4,0.5c-0.9,0.4-1.5,0.9-1.7,1.6c-0.4,1.2,0.4,2.4,1.1,3.5
				l0.2,0.3c0,0.1,0.1,0.1,0.2,0.2l0.1,0.1c2.6,3.8,3.3,5.5,3.6,6.2c-0.7-0.1-1.4-0.2-2.1-0.3c-1.1-0.2-2.3-0.5-3.6-0.4
				c-0.6,0-1.2,0.1-1.8,0.3c-3,0.9-5.5,5.4-6.2,8.3c-0.3,1.5-0.1,3.1,0.8,5c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.5,0.9,0.5,1.1
				c-0.1,0.4-0.6,0.8-1,1.2c-0.4,0.3-0.7,0.6-1,1c-0.4,0.5-0.7,1-0.9,1.5c-0.4,0.7-0.7,1.3-1.2,1.7c-0.4,0.3-0.7,0.7-1.1,1
				c-1.8,1.5-3.3,2.9-3.9,5.9c-0.3,1.4-1.1,5.6,1.7,6.4c1.5,0.4,3.5,0.9,5.5,0.9c0.2,0,0.3,0,0.5,0c0.9,0,1.8-0.2,2.6-0.4
				c1.8-0.6,2.9-1.5,3.3-2.7c0.5-1.4-0.4-2.4-0.9-3c-0.2-0.2-0.3-0.4-0.4-0.5c-0.1-0.2-0.1-0.2,0.5-0.9c0.2-0.2,0.4-0.4,0.5-0.6
				c0.8-1.1,1-2.3,1.2-3.4l0-0.3c0.1-0.5,0.1-0.5,0.8-0.7c1.2-0.3,1.9-1.3,2.6-2.1l0,0c0.1-0.1,0.2-0.2,0.3-0.3
				c0.1-0.1,0.2-0.2,0.3-0.4c0.7-0.9,1.8-2.2,2.7-2.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.5,0.4,1.1,0.8,1.9,1
				c0.2,0,0.5,0.1,0.7,0.1l0.1,0c0.7,0,1.3-0.3,1.8-0.5c0.4-0.2,0.8-0.3,1.1-0.4c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3
				c0.4,0.6,1.1,0.9,2,0.9c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0l0.2,0c2.8-0.1,5.3-1.2,8.1-3.5
				c0.5-0.4,0.9-0.8,1.3-1.2c1-0.9,2.1-1.9,3.2-2.3c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0c1.1-0.2,1.9-1,2.7-1.6c0.3-0.3,0.6-0.5,0.8-0.7
				c0.8-0.5,1.3-0.6,1.7-0.6c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.5,0.1l0.1,0c1.9-0.1,2-2.1,2.1-3.1c0-0.6,0.1-1,0.2-1.1
				c0.7-0.1,1.6,0.2,2.5,0.5l0,0c0.8,0.3,1.6,0.5,2.4,0.6c0.3,0,0.6,0,0.8,0c3-0.1,3-2.8,3-4.7c0-0.3,0-0.5,0-0.8
				c0.1-1.6,0.3-2,1.8-2.6c3-1.3,2.6-4.6,2.2-7L720.7,2522.2z M1334.2,1096.8c0.5,0.4,0.9,0.7,1.3,1.1c1.2,1.1,2.1,1.7,3.3,1.6
				c0.7,0,1.4-0.3,2.3-0.7c0.1,0,0.1-0.1,0.2-0.1c0.1,0.2,0.1,0.7,0,2.1c0,0.6-0.1,1,0,1.4c0.2,1.7,1.2,1.9,1.7,1.9c0,0,0.1,0,0.1,0
				c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0c0.3,0.2,0.5,0.8,0.7,1.2c0.1,0.3,0.3,0.7,0.5,1
				c0.7,1.2,2,2.1,2.9,2.6c0.9,0.5,1.7,0.8,2.5,0.8c0.1,0,0.1,0,0.2,0c1.6-0.1,2.6-1.2,2.9-3.1c0.5-3.2-1.6-5.6-3.4-7.6l-0.4-0.4
				c-2.1-2.5-2.8-4.5-2.1-6.9c0.1-0.3,0.2-0.7,0.4-1.1c0.6-1.7,1.7-4.5-0.7-6c-0.8-0.5-1.7-0.8-2.7-1.1c-1.5-0.5-3-0.9-3.5-1.8
				c-0.1-0.1,0.1-0.5,0.1-0.7c0-0.1,0.1-0.2,0.1-0.3c0.3-1.1,0.2-2,0.1-3c0-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.8,0-1.3
				c0.1-1.9,0.3-4.6-1.6-5.6c-0.7-0.4-1.5-0.5-2.6-0.5c-2.2,0.1-4.8,1.2-6.1,2.4c-0.9,0.9-0.7,2-0.5,2.7c0,0.1,0.1,0.2,0.1,0.4l0.1,0.4
				l0.3,0.2c0.4,0.3,2.2,1.8,1.5,3.3c-0.1,0.3-0.4,0.7-0.7,1c-0.5,0.5-0.9,1.1-1.2,1.9c-0.3,0.8,0.1,1.6,0.3,2.2
				c0.1,0.3,0.3,0.6,0.3,0.8c-0.1,0.8-0.4,1.7-0.8,2.5c-0.6,1.2-1.2,2.4-1,4C1330.2,1093.6,1332.3,1095.3,1334.2,1096.8z
				M1309.5,1092.1c0.1,0,0.1,0,0.2,0c0.8,0,1.6-0.4,2.1-0.9c1.6-1.8-0.1-6.9-0.7-8c-0.6-1-1.5-1.6-2.6-1.5c-1.2,0.1-2.5,0.8-3.5,1.5
				l-0.2,0.2l-0.3,0.7c-0.6,1.5-1.4,3.7-0.2,5.4C1305.1,1090.7,1307.6,1092.1,1309.5,1092.1z M1656.3,1237.2c-0.6-0.4-1.2-0.7-1.5-1
				c-0.2-0.2-0.5-0.4-0.7-0.6l-0.1-0.1c-1.9-1.7-3.8-3.4-6.4-4c-1.2-0.3-2.4-0.4-3.6-0.5c-1.6-0.1-3.2-0.3-4.8-0.9l-0.2-0.1
				c-2.9-1.2-4.5-2.2-4.6-3c0,0,0-0.4,1.6-1.6c0.3-0.3,0.6-0.5,0.8-0.7c0.3-0.3,1-1.1,0.7-2.1c-0.4-1.1-1.8-1.2-3.1-1.3
				c-0.3,0-0.6,0-0.8-0.1c-1.2-0.2-2.4-0.7-3.6-1.1c-0.8-0.3-1.6-0.6-2.4-0.9c-1.6-0.5-3.2-0.7-5.1-0.7c-0.5,0-1,0-1.5,0
				c-1,0.1-1.9,0.1-3,0.2l-0.4,0c-1.2,0.1-2.5,0.2-3.8,0.2c-1.9,0-3.9-0.1-6-0.4c-0.3,0-0.8-0.1-1.3-0.3c-1.2-0.3-2.8-0.6-4-0.6
				c-0.7,0-1.2,0.2-1.6,0.5c-1.2,1-1.1,3-1,4.4c0,0.3,0,0.6,0,0.8c0,2-0.4,3.5-1.8,3.9c-0.3,0.1-0.7,0.1-1.5-0.1
				c-0.5-0.1-1-0.2-1.5-0.2c-0.3,0-0.6,0.1-0.9,0.2c-1.1,0.5-1.8,1.6-2.3,2.5l-0.4,0.7c-0.9,1.4-1.7,2.8-2.9,3.8
				c-1.5,1.3-3.5,1.5-5.6,1.6c-2,0.2-4.1,0.4-6,1.5c-1.1,0.7-1.7,1.7-2.4,2.6c-0.6,0.8-1.1,1.6-2,2.2c-1.1,0.7-2.5,1.6-2.7,3
				c-0.2,0.9,0.1,1.8,0.8,2.6c0,0.1-0.1,0.3-0.6,1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.8,1.2-1.1,2.4-1.3,3.8c-0.1,1-0.5,1.1-1.6,1.2
				c-0.9,0.1-2.1,0.3-3,1.3c-0.4,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3,0.2-0.9,0.6-1.1,1.4
				c-0.2,0.8-0.3,1.5-0.3,2.1c-0.1,1.2-0.1,1.5-1.2,1.8c-1.4,0.4-1.4,1.8-1.4,2.5c0,0.1,0,0.2,0,0.3c-0.1,0.9-0.6,1.4-1.5,2.1l-0.2,0.1
				c-0.7,0.6-1.3,1.4-1.9,2.2c-0.8,1.1-1.6,2.2-2.8,2.5c-1,0.3-1.8,1-2.5,1.7c-0.6,0.6-1.2,1.1-1.8,1.3c-1.4,0.5-3,0.6-4.6,0.7
				c-0.5,0-1,0.1-1.5,0.1c-5.7,0.5-10.4,0.9-14.7,1.3c-0.5,0-1.1,0.1-1.6,0.1c-1,0.1-2.1,0.1-3.1,0.3c-1.7,0.3-3.1,1-3.9,2.1
				c-0.3,0.3-0.4,0.9-0.6,1.7c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.2-0.4-0.3-0.5-0.4c-0.7-0.5-1.5-0.9-2.2-1.2
				c-0.3-0.2-0.7-0.3-1-0.5c-0.6-0.3-0.9-1.1-1.3-1.8l-0.2-0.3c-0.8-1.7-1.7-2.4-3.1-3.2c-0.7-0.4-1.4-1-2.1-1.6c-1-0.9-2-1.9-3.5-2.4
				c-0.9-0.3-1.8-0.3-2.7-0.4c-0.7-0.1-1.4-0.1-2-0.3c-5.7-1.4-11.3-3.6-16.8-5.7c-2.6-1-5.3-2-8-3c-0.6-0.2-1.3-0.4-1.9-0.7
				c-2.7-0.9-5.2-1.7-7.6-3.4c-1.9-1.3-3.7-2.7-5.4-4.1l-0.7-0.6c-0.4-0.3-0.9-0.7-1.4-1c-1.2-0.8-2.4-1.7-2.9-2.8c-0.8-1.7-2-2-3-2
				c-0.1,0-0.3,0-0.4,0l-0.6,0c-0.4,0-0.8,0-1.1,0c-2-0.3-3.9-1.4-5.8-2.3l-0.4-0.2c-1-0.5-2-1.1-3-1.6l0,0c-1.1-0.6-2.3-1.3-3.5-1.9
				c-0.8-0.4-1.7-0.8-2.5-1.1c-1.2-0.5-2.4-1-3.5-1.7c-0.8-0.6-1.7-1.3-2.5-2c-1.3-1.1-2.6-2.3-4.1-3.1c-0.5-0.3-0.9-0.5-1.4-0.7
				c-1.3-0.6-2.3-1.1-3.4-2.2c-1.4-1.3-2.6-2.9-3.8-4.4c-1.5-2-3.1-4-5.1-5.7c-1.3-1.1-2.3-2.8-3.2-4.5c-1.2-2.1-2.3-4.2-4.4-5.6
				c-3.4-2.2-3.8-5.2-4.2-9.1c-0.1-0.6-0.1-1.3-0.2-1.9c-0.7-5.1-2.3-10.1-3.9-14.9l-0.1-0.4c-0.2-0.7-0.4-1.3-0.6-2
				c-1.3-3.9-1.5-7.8-1.7-12l0-0.7c-0.1-2.4-0.3,5.1-0.4,3.2c0-1,0-1.2-0.3-1.5l-0.3-0.3l-0.4,0c-0.3,0-0.8,0.2-0.9,0.7l-0.1,0l0,0.3
				c0,0.2,0,0.3,0,0.5l0,0.2c0,0.6,0,1.1,0,1.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.5-0.9-0.9-1.1-1.5c-0.4-1-0.3-2.3-0.2-3.4
				c0.1-0.5,0.1-1,0.2-1.5c0.3-1.9,0.7-4-0.3-5.9c-0.3-0.5-0.5-1-0.7-1.6c-0.4-0.9-0.8-1.8-1.3-2.6c-0.2-0.3-0.4-0.6-0.6-0.9
				c-0.4-0.6-0.8-1.2-1.1-1.9c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.7-0.2-1.4-0.6-2.2c-0.6-1.2-1.7-2.3-3-3.1c-0.8-0.5-1.3-0.7-1.9-0.7
				c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.2-1.2,0.6c-0.3,0.5-0.4,1.1-0.2,2.1c-0.2-0.2-0.5-0.5-0.7-0.6c-0.7-0.6-1.4-0.9-2-0.8
				c-2.3,0.1-3,3.8-2.9,5.6c0,0.9-0.1,1.7-0.2,2.2c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.2-0.5l0-0.1c-0.4-0.8-0.9-1.7-0.7-2.4
				c0.1-0.5,0.6-1,1-1.5c0.5-0.6,0.9-1.1,1.2-1.8c0.1-0.3,0.3-0.6,0.4-0.9c0.4-0.9,0.7-1.7,1.3-2.3c0.3-0.4,0.8-0.7,1.2-1
				c0.9-0.7,1.7-1.4,2.3-2.5l0-0.1c0.6-1.2,1.1-2.2,2.1-2.9c0.4-0.3,1-0.4,1.6-0.6c0.7-0.2,1.3-0.4,2-0.8c1-0.6,1.8-2.1,1.9-3.1
				c0.2-1.6-1.3-2.1-2-2.3c-0.3-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.3-0.6-0.7-1.4-1.6-1.6c-0.2-0.1-0.4-0.1-0.6-0.1
				c-0.7,0-1.7,0.5-2.5,0.8c-0.4,0.2-0.8,0.4-1,0.4c-3.4,0.9-8.8,7.9-10.4,10.4c-0.5,0.8-1.6,2.4-2.3,2.5c-0.3,0-0.7-0.4-1.1-1.1
				c-0.4-0.8-0.4-1.5-0.3-2.4c0.1-0.6,0.1-1.2,0.1-1.9c-0.1-1.5-0.8-3.8-1.8-4.9c-0.5-0.6-1.3-0.8-2-1c-0.9-0.3-1.2-0.4-1.2-0.7
				c0-0.4,0.2-1,0.5-1.6c0.3-0.6,0.5-1.2,0.6-1.8c0.3-1.9-0.2-3.8-1.3-5c-0.7-0.8-1.5-1.2-2.1-1.5c-0.9-0.4-1.3-0.6-1.5-1.9
				c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.7-0.2-1.5-0.5-2.2c-0.5-1.3-1.7-1.7-2.8-2.1c-0.4-0.1-0.7-0.2-1-0.4c-2-1.1-4-2.2-5.7-3.8
				c-0.2-0.2-0.5-0.5-0.8-0.8c-1.4-1.5-3.2-3.3-5.3-3.3c0,0-0.1,0-0.1,0c-2.2,0.1-9.5,3.3-9.6,6.6c-0.1,2.6,2.3,5.1,3.9,6.8l0.3,0.3
				c1.9,2,4.2,3.9,7.3,5.9c0.4,0.3,0.8,0.5,1.2,0.7c0.8,0.5,1.6,0.9,2.2,1.5c-0.2,0.5-0.7,1.4-0.9,1.8c-0.4,0.8-0.7,1.3-0.8,1.6
				c-0.4,1.7-0.5,3.5-0.6,5.2c-0.1,1.2-0.1,2.4-0.3,3.5c-0.4,3,1.1,3.6,2.4,4.1c0.5,0.2,1.1,0.4,1.6,0.8c1.4,1.1,2.4,3,2.6,4.6
				c0,0.4-0.3,0.7-2,1.1c-0.4,0.1-0.7,0.2-1,0.3c-3.3,1.2-3.7,2.7-3.9,5.6c0,0.6-0.1,1.2-0.2,1.9c-0.2,2.1-0.5,4.3,0.6,6.3
				c1,1.9,2.3,3.7,3.5,5.4c1.4,1.9,2.8,3.9,3.8,6c0.5,1,0.6,2,0.7,3.2c0,0.4,0.1,0.8,0.2,1.3c0.4,2.3,0.8,4.6,1.3,6.8l0.2,1.2
				c0.2,0.8,0.3,1.6,0.4,2.2c0.2,1,0.3,2,0.6,3c0.2,0.8,0.5,1.7,0.7,2.5c0.4,1.4,0.9,2.8,0.9,4.2c0,0.4-0.2,0.6-1.1,1.1
				c-0.3,0.2-0.6,0.3-0.8,0.5c-0.7,0.5-1.3,0.9-2,1.4c-1.3,0.9-2.7,1.9-4.1,2.8c-1.9,1.2-3.6,1.8-5.2,1.9c-2.1,0.1-3.9-0.9-5.2-2.9
				c-1.4-2.1-2.2-4.7-2.9-7c-0.1-0.3-0.2-0.7-0.3-1.1c-0.4-1.3-0.9-2.8-0.8-4c0-0.9,0.1-1.8,0.2-2.7c0.3-2.9,0.6-5.7-1.1-8.7
				c-1-1.8-2.3-4-3.9-5.8c-1.5-1.7-3.1-2.2-4.8-2.7c-0.6-0.2-1.3-0.4-2-0.7c0,0-0.1,0-0.1,0c-0.2-0.7-0.4-1.5-0.5-2l-0.1-0.4
				c-0.1-0.6-0.2-1.3-0.3-1.9l0-0.9l-0.9-0.1c-2.3-0.2-2.6-0.6-2.5-2c0-0.3,0-0.6,0-1c-0.1-1-0.1-1.4,0-1.5c0.1,0,0.2-0.1,0.2-0.1
				c0.3-0.1,0.9-0.3,1.3-0.8c1.1-1.6,1.1-4.3,0.5-5.9c-0.4-1.1-1.2-1.7-2.3-1.6c-1.6,0.1-3.5,1.6-4.1,2.5c-1.1,1.5-2.6,3-4.3,4.3
				c-0.4,0.3-0.7,0.5-0.8,0.6c-0.1-0.1-0.1-0.3-0.2-0.5c-0.4-1.1-1-2.3-2.4-2.3c0,0-0.1,0-0.1,0c-0.7,0-1.3,0.4-2.3,0.9
				c-0.2,0.1-0.5,0.2-0.7,0.2c0,0,0,0-0.1,0c-0.4,0-0.7-0.2-0.9-0.6l-0.2-0.3l-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.2
				c-0.5,0-1.5,0.3-1.9,1.9c-0.4,1.3-0.4,3.5,0.8,4.3c0.4,0.3,0.8,0.4,1.3,0.4c0.5,0,1-0.2,1.4-0.4c0.1-0.1,0.4-0.2,0.5-0.2
				c0,0,0,0.1,0,0.2c-0.5,0.3-1,0.6-1.4,0.9c-0.8,0.8-1.1,1.8-1.4,2.8c-0.2,0.7-0.4,1.4-0.8,1.9c-0.1-0.3-0.2-0.9-0.3-1.9
				c0-0.4-0.1-0.8-0.1-1.1c-0.5-2.1-1.8-5.7-4.4-6.2c-0.1,0-0.2,0-0.3,0l-0.1,0c-0.8,0-1.7,0.8-2.4,1.6c0.1-1.3,0.1-2.9-1.4-3.6
				c-0.4-0.2-0.9-0.3-1.3-0.2c-1.3,0.1-2.5,0.9-3.6,1.7c-0.7,0.5-1.3,0.9-1.7,1c-0.1,0-0.3,0.1-0.4,0.1c-0.9,0.1-1.8-0.5-2.8-1.1l0,0
				c-1.1-0.7-2.3-1.4-3.7-1.4c-0.1,0-0.1,0-0.2,0c-1.7,0.1-3.1,1.7-4.4,3.2c-0.7,0.8-1.4,1.6-2,1.9c-0.4,0.2-0.6,0.3-0.6,0.3
				c-0.2,0-0.5-1.1-0.6-1.5c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.1-0.1-0.3-0.2-0.5c-1.1-2.8-1.1-3.7-1.1-4c0.2,0,0.3-0.1,0.5-0.1
				c0.8,0,1.7,0.2,2.6,0.5l0,0c0.9,0.3,2,0.6,3.1,0.6c0.3,0,0.5,0,0.8-0.1c1.3-0.2,5.4-2,5.7-4.2c0.3-1.8-0.4-3.7-1-5.3l0,0
				c-0.1-0.3-0.2-0.7-0.3-1c-0.4-1.1-0.6-2.3-0.8-3.5c-0.2-1-0.3-2-0.6-3c-0.2-0.8-0.7-1.5-1.2-2.4c-0.8-1.3-1.6-2.6-1.1-3.5
				c0.9-1.5,3.4-3,5.3-3.1c1.2-0.1,2-0.7,2.7-1.3c0.4-0.4,0.8-0.7,1.4-0.9c0.3-0.1,3.5-1.4,3.3-3c0-0.4-0.3-1.2-1.8-1.3
				c-0.2,0-0.5,0-0.7,0c-1.8,0.1-3.2,0.9-4.5,1.7c-0.7,0.4-1.3,0.8-2.1,1.1c-1,0.4-2.7,0.7-4,0.8c-0.6,0-1.1,0-1.4,0
				c-0.3-0.1-0.6-0.2-0.7-0.5c-1-1.7,0.7-6.5,1.3-8.3l0-0.1c0.1-0.2,0.1-0.4,0.2-0.6c0.6-1.8,0-3.5-0.5-5.1c-0.2-0.5-0.3-1-0.4-1.5
				c-0.1-0.6-0.2-1.1-0.3-1.7c0.7,1.6,1.6,2.8,2.8,2.8c0,0,0.1,0,0.1,0c0.8,0,1.5-0.6,2.1-1.7c1.8-3.3-0.1-5.9-1.9-7.7
				c-0.1-0.1-0.2-0.2-0.4-0.4c-0.4-0.4-1.3-1.2-1.2-1.6c0-0.1,0.2-0.7,3.1-1.6l0.3-0.1c1.1-0.4,2.3-0.5,3.6-0.7
				c1.1-0.2,2.3-0.3,3.5-0.7c2.4-0.7,3.6-1.2,3.5-2.3c-0.1-1.1-1.4-1.2-1.8-1.2c-2.8-0.3-4.9-0.9-6.3-1.9c-0.4-0.3-0.6-0.5-0.6-0.7
				c-0.1-0.6,0.8-1.6,1.4-2.2c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.1c1.1-1.2,3.2-3.4,1.7-5.1c-0.6-0.8-1.7-1.1-2.6-1.5
				c-0.2-0.1-0.5-0.2-0.6-0.2c-1.6-0.7-3-1.3-4.4-2c-5.2-2.7-10.3-8.3-12.6-13.9c-0.7-1.6-0.9-3.3-1.2-5.1c-0.1-1-0.3-2.1-0.5-3.1
				c-0.8-3.6-2.8-4.8-6.1-5.8c-4.7-1.3-7.3-4.3-8.4-9.7c-0.6-2.7-0.8-5.4-0.7-8c0.1-1.4,0.5-2.8,0.9-4.1c0.4-1.2,0.7-2.3,0.9-3.6
				c0.1-0.5,0.1-0.9,0-1.2c0.2,0.1,0.4,0.1,0.7,0.2c0.2,0,0.4,0.1,0.5,0.2c0.3,0.2,0.4,0.6,0.6,1.1c0.3,0.7,0.7,1.7,1.9,2.2
				c0.6,0.3,2.1,0.6,3.5,0.5c1.2-0.1,2.1-0.4,2.5-1c0.3-0.3,0.5-0.9,0.3-1.7c-0.3-1.5-1.3-2.7-2.3-4c-0.7-0.8-1.3-1.6-1.6-2.4
				c-0.3-0.6-0.1-1.5,0.2-2.4c0.4-1.5,0.8-3.2-0.6-4.6c-0.4-0.4-1-0.5-1.5-0.6c0.3-0.4,0.5-1,0.4-1.7c-0.2-0.7-0.7-1.2-1.5-1.2
				c0,0-0.1,0-0.1,0c-0.9,0-2.1,0.7-3.5,1.7c0.3-0.6,0.7-1.4,1-1.7c1.6-1.8,1.6-3,1.3-5.2c0-0.3,0-0.7-0.1-1.1c0-1.4,0-3.1-1.4-3.6
				c0,0-0.1,0-0.1,0c0.4,0,0.8,0.1,1.1,0.1c1.1-0.1,1.8-0.5,2.2-1.3c1.2-2.4,1.3-6.9-0.4-9.1c-0.8-1-2-1.3-3-1.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c-1.2-0.5-2.4-1-3.4-1.9l-0.1-0.1c-1.6-1.5-2.3-2.4-2.7-2.9c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.2
				c0.8,0,1.1-0.4,1.3-0.7c0.4-0.6,0.2-1.4-0.4-2.6c-0.5-0.9-1.1-1.7-1.6-2.4c-1-1.3-1.9-2.6-2-4.1c-0.1-1-0.3-2.7-1.5-2.7l-0.1,0
				c-0.7,0-1.1,0.5-1.8,1.7l0,0c-1.2,0-3.6-1.7-3.8-2.1c-0.4-0.9-0.8-1.9-1.3-3c-0.2-0.6-0.5-1.2-0.7-1.8c0.3,0.1,0.6,0.2,0.9,0.2
				c1-0.1,1.9-0.8,2.2-1.9c0.6-1.9-0.6-2.8-1.4-3.4c-0.2-0.1-0.4-0.3-0.5-0.4c-1.3-1.2-2.4-2.8-3.5-4.4c-0.6-0.9-1.2-1.7-1.9-2.6
				c-1-1.3-1.5-2.3-1.5-3.2c0,0,0-0.1,0-0.1c0,0,0.2-0.1,0.3-0.2c0.4-0.2,1.1-0.6,1.2-1.5c0.1-0.7-0.2-1.5-0.4-2.3
				c-0.4-1.4-0.5-1.8-0.2-2c1-0.6,2.3-2.7,2.7-4.3c0.2-0.9,0.1-1.6-0.3-2.2c-0.3-0.5-0.8-0.7-1.3-0.7l-0.1,0c-1.6,0.1-3.3,3.1-4.2,4.8
				l-0.1,0.2c-0.2,0.4-0.6,1.2-0.9,1.3c-1.7,0.4-1.9,2.4-1.9,3.3c0,0.1,0,0.3,0,0.4c-0.1,0.8-0.6,1.3-1.1,1.6c-0.2,0.1-0.4,0.2-0.7,0.2
				c-0.6,0.1-1.5,0.3-1.9,1.5c-0.8,2-1.1,2.4-2.9,2.5c-0.2,0-0.4,0-0.6,0c-1.1,0-2.2-1.3-3.1-2.4c-0.4-0.5-0.7-0.9-1.1-1.2
				c-0.7-0.7-1.1-0.9-1.6-0.9l-0.1,0c-0.4,0-0.7,0.2-0.9,0.5c-0.4,0.6-0.2,1.4,0.8,3.6l0,0c0,0.1,0.1,0.2,0.1,0.3
				c-0.1,0.1-0.2,0.3-0.3,0.5c-0.6,1.1-1.3,2.3-0.7,3.3c0.3,0.6,1,0.8,1.9,0.8l0.1,0c0.7,0,1.4-0.1,2.2-0.2c0.8-0.1,1.6-0.2,2.4-0.2
				c0.8,0,1.5,0,2,0.1c0.2,0.1,1,1.1,1.4,1.8c-0.3-0.1-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.2-0.9-0.2c-0.2,0-0.4,0-0.6,0
				c-1.1,0.1-2,0.5-2.8,0.9c-0.3,0.2-0.7,0.3-1,0.4c-0.6,0.2-1.1,0.3-1.5,0.4c-0.9,0.1-1.3-0.5-2.2-2.7l0-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
				c-0.4-0.9-0.8-2.1-1.7-2.7c-0.2-0.2-0.5-0.3-0.6-0.3c-0.5-0.2-0.5-0.2-0.6-0.8c-0.1-0.3,0-0.8,0.1-1.3c0.1-1.2,0.3-2.5-0.6-3.4
				c-0.3-0.3-0.6-0.5-0.9-0.6c-0.4-0.3-0.6-0.4-0.8-0.9c-0.8-2.5-4.2-2.7-6.2-2.7c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0
				c0.2-0.2,0.6-0.4,1.1-0.6c0.1,0,0.6-0.1,0.8-0.2c1.7-0.3,3.2-0.5,3.1-1.8c0-0.1,0-0.2,0-0.2c0.3,0.5,0.7,0.8,1.2,0.8l0.1,0
				c0.7,0,1.3-0.5,1.7-0.9c0,0,0.1-0.1,0.1-0.1c0.6-0.5,1.3-0.5,2.2-0.6c0.5,0,1-0.1,1.5-0.2c0.6-0.1,1.9-0.5,2.1-1.6
				c0.1-0.7-0.3-1.3-1.2-1.7c-0.7-0.4-1-0.8-1.4-1.8l0-0.1c-1.4-3-4.4-3.4-6.5-3.4c-0.4,0-0.7,0-1.1,0c-0.7,0-1.3,0-1.8,0
				c-4.7,0-7.8-1-8.7-5.8c0-0.3-0.1-0.5-0.1-0.9c-0.1-0.9-0.3-1.9-0.8-2.8c-0.5-0.9-1.2-1.4-1.9-1.8c-0.3-0.2-0.5-0.4-0.8-0.5
				c-1.1-0.9-2.4-1.2-3.6-1.5c-0.8-0.2-1.6-0.4-2.2-0.7c-1.4-0.7-3-1.4-4.5-1.3c-1.4,0.1-2.5,0.8-3.3,2.1c-0.6,1.1-0.5,2.1-0.3,3
				c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0.1,0,0.1c0,0-0.2,0.1-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.5,0.2-0.7,0.4
				c0.1-0.2,0.2-0.4,0.3-0.7c0.3-1.2-0.2-2.6-1.5-4c-1-1.1-2-2.2-2.1-3.5c0-0.1,0-0.4,0-0.6c0.1-0.8,0.2-2.4-1.2-2.9
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.8,0-1.5,0.4-2.2,0.6c-0.4,0.2-0.8,0.3-1.1,0.4c-0.7,0.2-1.1-0.1-1.9-0.9c-1.5-1.4-3-2-4.6-2.6
				c-0.6-0.2-1.2-0.5-1.8-0.8c-0.7-0.3-1.5-0.5-2.2-0.4c-1.4,0.1-2.7,0.7-3.4,1.8c-0.7,1-0.9,2.2-0.6,3.4c0.3,1,0.9,1.7,1.4,2.4
				c0.2,0.3,0.5,0.5,0.7,0.8c0.6,0.8,1,1.8,1.3,2.9c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.3
				c-2-1.2-4-1.3-5.9-1.5c-1.2-0.1-2.4-0.2-3.6-0.5c-2.7-0.8-5.2-2.9-6.9-5.7c-1.3-2.1-2.4-5.7-1.3-6.6c0,0,0.1,0,0.1,0
				c0.1,0,0.4,0.1,0.6,0.1c0.3,0.1,0.8,0.2,1.2,0.2c0.1,0,0.2,0,0.4,0c1.1-0.2,2-0.8,2.8-1.4c0.1-0.1,0.3-0.2,0.4-0.3
				c0.3-0.2,0.8-0.4,1.3-0.5c1.2-0.4,2.4-0.8,2.7-1.9c0.2-0.7-0.1-1.5-0.8-2.3c-0.6-0.7-1.4-1.2-2.3-1.8c-1.1-0.8-2.3-1.6-2.5-2.4
				c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.7-0.2-1.4-0.7-2c-0.4-0.5-0.9-0.6-1.2-0.6l-0.1,0c-0.3,0-0.7,0.1-1.1,0.6c-0.5,0.6-0.8,1.6-0.8,2.2
				c0,0.2,0,0.5,0.1,0.7c0.2,1.7,0.2,2.1-1.1,2.8c-1.8,1.1-3.9,2.1-6.2,3.3c-0.2,0.1-0.5,0.2-0.8,0.3c-1,0.3-2.7,1-2.2,3.1
				c0.5,2,1.7,3.4,2.8,4.8c0.9,1.1,1.7,2.1,2.1,3.3c0.2,0.5,0.1,1.2,0,1.8c-0.1,0.6-0.1,1.2-0.1,1.8c0,0.2,0.1,0.5,0.1,0.8
				c0.1,0.6,0.2,1.2,0,1.6c-0.3,0.5-1.2,0.9-2,1.2c-0.4,0.2-0.8,0.3-1.1,0.5l-0.4,0.2c-0.8,0.5-1.8,1-2.6,1c-0.2,0-0.5,0-0.7-0.1
				c-1.4-0.5-1.9-2.7-2.1-4c-0.3-1.6,0-2.1,1.1-3.3c1.1-1.3,2.4-2.8,3.4-4.4c0.4-0.6,0.5-1.2,0.3-1.8c-0.3-1.2-1.5-1.9-2.5-2.4
				c-0.1-0.1-0.3-0.2-0.4-0.2c-1-0.6-2.2-0.9-3.3-1.1c-1.2-0.3-2.3-0.5-3-1.1c-0.3-0.3-0.6-0.6-1-1c-0.3-0.3-0.6-0.7-0.9-1
				c-0.6-0.6-1.4-1-2.1-1.4c-0.5-0.3-0.9-0.5-1.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c0.3,0.1,0.7,0.1,1.1,0.1c0.5,0,2.2-0.2,2.6-1.4
				c0.2-0.5,0.2-1.3-0.7-2.2c-1.9-1.9-2.9-3.9-4.2-6.4l-0.1-0.2c-1-1.9-2.1-2.6-3.4-3.5c-0.3-0.2-0.7-0.4-1.1-0.7c-2-1.4-4-3.4-5.8-6.1
				c-1.4-2-2.3-4.2-2.7-7c0-0.2,0-0.3-0.1-0.6c-0.2-1.6-0.5-4.1-2.4-4.1c0,0-0.1,0-0.1,0c-0.6,0-1.1,0.3-1.7,0.9
				c-0.3,0.3-0.5,0.7-0.8,1.1c-0.3,0.5-0.5,0.7-0.5,0.8c-0.3-0.2-1-0.8-1.3-1.5c-0.2-0.5-0.2-0.8-0.2-1.2c0.1-1.5-0.6-2.1-2.7-2.4
				c-0.3,0-0.7-0.1-1.1-0.1c-0.9-0.1-2.4-0.1-2.5-0.7l0-0.1c-0.2-0.8-0.2-1.2-0.2-1.4c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.3,1.4-0.8
				c0.7-1.3,0.5-2.8,0.3-4.3c-0.1-1-0.3-1.9-0.1-2.7c0.5,0.3,1.3,1,2.8,2.4c0.3,0.2,0.4,0.4,0.5,0.5c0.2,0.1,0.5,0.6,0.8,1
				c0.8,1.1,1.9,2.6,3.3,2.5c0.6,0,1.1-0.3,1.5-0.8c1.2-1.4,1-3.9,0.7-6.1c-0.1-1-0.2-1.9-0.1-2.5c0.2-1.6,0.5-2.8,0.9-3.8
				c0.2-0.4,0.4-0.7,0.7-1.1c0.4-0.5,0.8-1,1.1-1.7c0.5-1.4,0.2-2.4-0.2-2.9c-0.5-0.7-1.4-1.1-2.5-1.1c-1.1,0.1-2.1,0.6-2.8,1.4
				c-0.5,0.6-0.9,1.3-1.2,1.9c-0.7,1.3-1.1,1.9-2.1,1.9l-0.1,0c-1,0-1.9-0.2-2.9-0.5c-1.2-0.3-2.5-0.6-4-0.5c-0.2,0-0.5,0-0.7,0.1
				c-1.8,0.3-2.7,1.3-3.4,2.2c-0.4,0.5-0.8,1-1.4,1.4c-1.1,0.6-2.4,1-4,1.1c-1.6,0.1-3.2-0.1-4.7-0.6c-0.3-0.1-0.9-0.3-1.4-0.6
				c-1.6-0.7-3.5-1.5-5.1-1.5c-0.8,0-1.4,0.3-1.9,0.7c-1.2,1.2-1,2.7-0.8,3.9c0.1,0.4,0.1,0.8,0.1,1.2c0,0.2-0.7,0.8-1,1
				c-0.4,0.3-0.8,0.6-1,1l-0.8,1l1.2,0.5c1.5,0.6,2.7,1.4,3.8,2.2c0.9,0.7,1.4,1.4,1.6,2c0.1,0.2,0.1,0.3,0.1,0.5
				c0.3,1,0.7,2.4,2.5,2.7l0.3,0.1c1.3,0.2,2.1,0.3,3,1.2c1.3,1.2,2.6,2.6,3.9,3.9l0.1,0.1c4.1,4.3,9.3,10,14,16.5
				c5.4,7.5,10.9,15.1,17,23.1c3.6,4.7,6.8,9.1,9.3,13.8c1.3,2.5,1.5,5.2,1.8,8l0,0.2c0,0.3,0.1,0.7,0.1,1c0,0.5,0.1,1,0.1,1.5
				c0.3,3.3,0.7,7.4-0.7,10.1c-0.4,0.7-2.8,0.8-3.7,0.9l-1.3,0.1c-2.1,0.1-4,0.3-4.9,1.5c-0.4,0.6-0.6,1.2-0.4,2
				c0.6,2.9,3.5,5.7,5.9,7.9c0.6,0.5,1.2,1.1,1.6,1.6c0.3,0.3,0.6,0.6,0.9,0.9c0.8,0.8,1.7,1.7,2.4,2.6c0.3,0.3,0.7,0.8,1.3,0.8
				c-0.3,0.5-0.5,1-0.5,1.5c-0.1,1.6,1.3,3.6,2.4,5c0.2,0.3,0.4,0.6,0.6,0.8c0.1,0.1,0.1,0.2,0.2,0.4l0,0.1c0.8,1.2,1.8,2.9,3.3,2.9
				c0,0,0.1,0,0.1,0c0.3,0,0.6-0.1,0.8-0.3c0,0.5,0,0.9,0.2,1.3c0.7,1.6,1.9,2.9,3,4.2c0.3,0.3,0.6,0.7,0.9,1c2.3,2.7,4.1,5.4,6.1,8.3
				c0.7,1,1.3,2,2,2.9c0.1,0.1,0.1,0.2,0.2,0.3c0.7,1.1,1.6,2.4,2.9,2.3c0.1,0,0.3,0,0.4-0.1c-0.3,0.7-0.5,1.5,0,2.6
				c1.2,2.8,3.3,5,5.2,7.2c0.9,1,1.8,2,2.6,3.1c1.5,1.9,2.9,3.9,4.2,5.7l0,0c1.5,2,3,4.1,4.5,6.1c1.5,1.9,3,3.8,4.5,5.8l0.5,0.7
				c6.8,8.6,13.8,17.6,20.3,26.6c2.6,3.5,5.4,7.1,8.1,10.5l0,0c7.1,9,14.4,18.4,19.2,29c0.7,1.5,0.9,3.3,1.2,5.1
				c0.1,0.9,0.2,1.8,0.4,2.6c0.1,0.5,0.2,1.1,0.3,1.7c0.3,2.6,0.8,5.9,4.4,6.2c1,0.1,2.4,0.2,3.9,0.2c0.4,0,0.9,0,1.3,0
				c3.9-0.2,6.4-1.5,7.4-3.7c0.3-0.6,0.4-1.2,0.5-1.8c0.1-0.3,0.1-0.7,0.2-1c0.1-0.3,0.2-0.7,0.4-1c0.2-0.5,0.5-1.1,0.6-1.8
				c0.1-0.8,0-1.5-0.5-2.2c-1.2-1.7-4.2-2.4-5.4-2.6c-0.1,0-0.2,0-0.2,0c0.1,0,0.2-0.1,0.3-0.1c1.2-0.6,2.3-1.1,2.4-2.1
				c0.1-0.4-0.1-0.9-0.4-1.2c-0.8-0.9-1.9-1.5-3-2c-0.8-0.4-1.5-0.8-2.1-1.3c-0.9-0.7-1.5-2-2.1-3.2c-0.2-0.5-0.4-0.9-0.7-1.3
				c-1-1.8-2.3-2.9-3.7-3.9c-0.7-0.6-1.4-1.1-2.1-1.8c-0.1-0.1-0.2-0.2-0.4-0.4l0,0c-0.9-0.9-1.7-1.7-2.4-2.4c0.6,0.5,1.2,0.9,1.6,1.3
				c2.1,1.6,2.4,1.9,2.9,1.9c0,0,0,0,0,0l0.2,0c0.7-0.1,1.3-0.4,1.9-0.6c0.6-0.2,1-0.4,1.5-0.5c0.4,0,0.8,0.1,1.3,0.3
				c0.8,0.4,1.3,1.3,1.8,2.2c0.2,0.3,0.4,0.7,0.6,1c2.4,3.6,4.6,6.2,6.8,8.2l0.1,0.1c0.7,0.6,2,1.8,3.4,1.8c0,0,0.1,0,0.1,0
				c0.2,0,0.4-0.1,0.6-0.1c1.1-0.4,2-1.3,2.8-2l0.2-0.2c0.2-0.1,0.3-0.4,0.5-0.6c0.1-0.1,0.4-0.5,0.5-0.7c0.1,0,0.3,0.1,0.4,0.1
				c0.3,0.1,0.7,0.2,1,0.1c0.6,0,0.9-0.3,1.1-0.6c0.3-0.5,0.4-1.1,0.2-2.1c-0.1-0.5-0.3-1-0.6-1.4c0.5,0.3,1.1,0.4,1.7,0.3
				c0.8,0,1.7-0.4,2.5-0.9c2.8-2,6.2-6.8,5.1-10.4c-0.1-0.2-0.1-0.3-0.3-0.5c0,0,0.1,0,0.1,0.1l0,0c0.9,0.5,1.5,1,1.9,1.7
				c0.1,0.2,0.1,0.4-0.6,1.2c-0.2,0.2-0.4,0.5-0.6,0.8c-0.6,1-1.1,2-1.6,3.1c-0.7,1.7-1.5,3.2-2.8,4.3c-0.1,0.1-0.4,0.3-0.6,0.4
				c-1.1,0.8-2.6,1.8-2.6,3.3c0,1.9,2.6,2.9,4.3,3.5c0.3,0.1,0.6,0.2,0.7,0.3c1.9,0.9,3.2,1.8,3.3,3.7c0.1,0.7-0.1,0.9-1.6,1.3
				c-0.6,0.1-1.2,0.3-1.7,0.6c-0.2,0.1-0.5,0.2-0.9,0.2c-0.9,0.1-2.1-0.2-3.3-0.5c-1.3-0.3-2.6-0.6-3.8-0.5c-2.3,0.1-3.5,1.6-3.5,4.3
				c0,0.3,0,0.6-0.1,1c-0.2,2.1-0.4,4.9,2.5,6c1.4,0.5,2.7,0.2,3.6-0.8c0.1-0.1,0.3-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4
				c0.4,0.3,0.7,0.9,1,1.5c0.4,1,1,2.1,2.1,2.6c0.4,0.2,0.8,0.3,1.3,0.2c1.7-0.1,3.7-1.5,5-2.4c0.2-0.1,0.3-0.2,0.4-0.3
				c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.4,0.3,0.9,0.4,1.3c0.4,1.1,0.7,2.2,0.4,3.2c-0.8,2.8-1.6,5.5-0.3,8.6c0.3,0.7,0.6,1.3,0.9,1.8
				c0.7,1.4,1.2,2.1,0.8,3.9c-0.1,0.5-0.3,1-0.5,1.6c-0.5,1.5-1.1,3.2-0.5,4.7c0.2,0.5,0.6,1.1,1,1.8c0.7,1,1.8,2.8,1.6,3.6
				c-0.1,0.2-0.2,0.3-0.5,0.5c0,0,0,0-0.1,0l-0.1,0c-1.3,0-4-1.8-4.4-2.6c-0.1-0.2,0-0.7,0.1-1.1c0-0.2,0.1-0.4,0.1-0.7
				c0.1-1.7,0.1-3.3-0.1-4.6c-0.4-2.6-2.5-3.1-4.2-3.6c-0.6-0.2-1.1-0.3-1.6-0.5c-2.2-1-3.8-2.8-4.7-5.5c-0.1-0.3-0.2-0.7-0.3-1l0-0.1
				c-0.6-1.8-1.2-3.7-2.2-5.5c-1-1.8-2.2-2.6-3.8-2.5c-0.7,0-1.4,0.2-2.5,0.6c-2,0.7-3.2,1.9-3.8,3.6c-1.3,4,2.1,9.5,3.6,11.6
				c1.3,1.9,2.9,3.8,4.4,5.6c1.5,1.8,3.1,3.7,4.5,5.8c1.3,1.9,2.3,3.9,3.4,5.8c0.8,1.6,1.7,3.2,2.7,4.7c0.5,0.7,0.9,1.5,1.4,2.2
				c2.2,3.5,4.5,7.2,6.3,11c1.2,2.5,1.6,5.2,2.1,8c0.3,2,0.7,4.1,1.3,6.1c1.7,5.5,2.2,11.3,2.6,16.3c0.3,3.3,1.8,3.9,3,3.9
				c2.2-0.1,4.9-2.8,7.2-5.3c0.2-0.2,0.4-0.4,0.6-0.6l0.1-0.1c0.5-0.5,1.1-1.2,1.7-1.3c0.2,0,0.4-0.1,0.6-0.1c0.9-0.1,1.8-0.3,2.6-1.7
				c0.9-1.6,2.5-3.1,4.1-4.5c0.3-0.3,0.6-0.5,0.9-0.8c0.1-0.1,0.3-0.3,0.5-0.5l0.1-0.1c1-1,2.5-2.4,3.6-2.4l0,0c0.6,0,1.1,0,1.7,0
				c1.3-0.1,2.7-0.3,4-0.5l0,0c-0.6,0.5-1.2,1-1.7,1.6c-0.7,0.8-1.6,2.1-1.5,3.3c0.2,2.2,2.4,6.3,5.2,6.3c0.1,0,0.1,0,0.2,0l0.1,0
				c0.8-0.1,1.9-0.5,2.5-1.4c0.4-0.5,0.6-1.1,0.5-1.8c-0.1-0.3-0.2-0.6-0.4-0.9c0.5,0,1.1,0,1.2,0.1c0.1,0,0.2,0,0.2,0
				c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.5-0.3,1-0.2,1.6c0.3,4.2,3.7,4.6,6.8,5c1.5,0.2,3,0.4,4.1,1c-0.2,0.1-0.3,0.3-0.5,0.5
				c-0.5,0.8-0.6,1.9-0.4,2.8c-0.8-1.3-1.6-2.3-2.7-2.3l-0.1,0c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.7,0.8
				c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.5-0.4-1.1-0.4c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5,0.1c-1.7,0.5-3.6,1.2-5,2.7l-0.2,0.2
				c-0.6,0.7-0.7,0.8-1.4,0.8c-0.1,0-0.1,0-0.2,0c-1.7,0-3,0.7-4.1,2.1c-0.1,0.1-0.6,0.6-1,0.9c-2,1.7-3.5,3.1-3.4,4.4
				c0,0.5,0.3,1,0.7,1.3c1.5,1.1,3,1.3,4.5,1.4c0.8,0.1,1.6,0.2,2.4,0.5l0.1,0c0.2,0.1,0.3,0.1,0.5,0.2c0,0-0.1,0-0.1,0
				c-0.2,0.1-0.3,0.1-0.5,0.2c-0.5,0.2-1.2,0.5-1.7,0.6l-0.1,0c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.4-0.9-0.8-1.7-1
				c-0.8-0.2-1.5,0.1-1.9,0.7c-0.7,1.2,0.2,3.5,1.3,5.9c0.1,0.3,0.2,0.5,0.3,0.6c0,0,0,0.1,0,0.1c-0.4-0.3-0.8-0.7-1.3-1.1
				c-1.4-1.6-1.7-3.6-2-5.8c-0.1-0.6-0.2-1.3-0.3-2c-0.5-2.5-1.2-4.5-2.2-6.5c-0.5-1-0.4-2.1-0.2-3.3c0.1-1.3,0.3-2.6-0.3-3.9
				c-0.6-1.5-1.8-2.1-3.5-2.1c-0.7,0-1.4,0.2-2.1,0.3c-0.6,0.1-1.1,0.2-1.5,0.3l0,0c-0.3,0-0.7,0-1.1,0l-0.1,0c-0.5,0-1,0-1.6,0
				c-2.2,0.1-3.4,1.1-3.6,2.9c-0.4,3.3,0.9,5.9,2.2,8.5c0.3,0.6,0.7,1.4,1,2.1c1.8,4.1,3.6,8.4,5.3,12.5c1.5,3.6,3,7.3,4.6,10.9
				c0.2,0.5,0.5,1.1,0.7,1.7c0.8,2,1.7,4.2,3,5.9c0.6,0.8,1.3,0.9,1.7,0.9c0,0,0.1,0,0.1,0c-0.8,0.6-1.5,1.5-1.6,2.9
				c-0.3,4.5,1.8,9.4,3.6,13.7c0.5,1.2,1,2.4,1.4,3.5c1.1,2.9,2.4,5.8,3.6,8.6c0.9,2,1.8,4,2.6,6c1,2.4,2,5,2.4,7.7
				c0.2,1.1,0.3,2.3,0.4,3.4c0.1,1.7,0.3,3.5,0.8,5.2l0.1,0.4c0.4,1.4,0.8,3.3,1.8,4.3c0.6,0.6,1.4,0.9,2.2,0.9c2.1-0.1,4-2.9,4.5-4.3
				l0-0.1c1.6-4.3,2.7-4.9,3.2-4.9c0,0,0.1,0,0.1,0c0.2,0,0.4,0.2,0.6,0.6c0.7,1.2,0.9,3.7,0.6,4.6l0,0.1c-0.5,1.6-0.9,2.9-2.3,3.5
				c-0.9,0.4-2.1,0.7-3.6,0.7c-0.5,0-1,0-1.7,0c-0.3,0-0.7,0-1,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.6,0-1.1,0-1.5,0l0,0
				c-2.1,0.1-4.5,0.5-6.5,2.6c-2.3,2.4-1.9,6.6-1,9.6c0.6,2.2,0.8,4.4,1.1,6.9c0,0.3,0.1,0.6,0.1,1c0.2,2.2,0.4,5.3,2.4,6.7
				c0.3,0.2,0.5,0.3,0.8,0.4c-0.4,0.5-0.6,1.1-0.8,1.7l-0.1,0.2c0,0.1-0.1,0.2-0.2,0.4c-0.7,1.9-2.1,5.4-0.2,6.9
				c0.6,0.5,1.4,0.7,2.2,0.7c2.2-0.1,4.4-2.4,5.2-3.8c0.5-0.9,1.5-1.6,2.3-1.6c0.5,0,0.9,0.3,1.2,0.9c0.7,1.5-0.9,4.6-2.2,5.7
				c-0.8,0.6-1.5,1.4-2.2,2.1c-0.9,1-1.8,1.9-2.8,2.5c-0.3,0.2-0.6,0.3-0.9,0.3l-0.1,0c-0.9,0-1.6-1.3-1.7-2.6
				c-0.1-0.9-0.3-2.5-1.7-2.5l-0.1,0c-0.6,0-1,0.4-1.6,0.9c-0.3,0.2-0.6,0.5-0.9,0.7c-1,0.7-2,1-3.2,0.9c0,0-0.2,0-0.3-0.1
				c-0.3-0.1-0.7-0.2-1.1-0.2c-0.8,0-1.2,0.6-1.4,0.9c-1,2.1-0.3,5.5,0.3,8.2c0.1,0.6,0.3,1.3,0.4,1.8c0.2,1,0.4,2.1,0.5,3.1
				c0.5,2.9,1.1,5.9,1.2,8.8c0.2,3.4-2.1,7.2-4,10.3l-0.5,0.8c-0.2,0.4-0.5,0.7-0.7,1c-0.6,0.7-1.1,1.4-1.4,2.5
				c-0.5,1.8-0.4,3.7-0.3,5.4c0,0.6,0.1,1.3,0.1,2c0,5.1-0.2,10.4-0.5,15.5l0,0.5c-0.1,2.1-0.2,4.2-0.2,6.1c-0.3,8.9-0.6,18-2.9,26.5
				c-1,3.5-3.2,6.6-5.3,9.7l0,0.1c-0.5,0.8-1.2,1.7-1.7,2.5c-1.6,2.4-3.1,4.6-4.7,6.8l-0.4,0.5c-1.2,1.7-2.4,3.4-4.3,4
				c-1,0.3-2.2,0.6-3.6,0.6c-0.4,0-0.8,0-1.3,0c-0.9,0-1.8,0-2.6-0.1c-0.9,0-1.8-0.1-2.7-0.1c-0.5,0-1,0-1.5,0
				c-1.3,0.1-2.4,0.2-3.4,0.5c-2.7,0.7-5.3,1.6-7.5,2.7c-1.5,0.7-2.6,1.7-3.7,2.6c-0.5,0.4-1,0.9-1.5,1.3c-1.8,1.4-4,2.6-6.2,3.4
				c-0.1,0-0.3,0.1-0.4,0.1c-0.6,0.1-1.4,0.4-1.9,1.3c-0.2,0.3-0.3,0.7-0.5,1c-0.4,1-0.8,1.8-1.6,2.5c-1.5,1.3-2.9,2.2-4.3,2.8
				c-0.7,0.3-1.4,0.5-2.2,0.6c-0.7,0.2-1.5,0.3-2.2,0.6c-2.1,0.9-3.5,2.5-4.8,4c-0.7,0.8-1.3,1.5-2.1,2.2c-0.4,0.4-0.9,0.8-1.4,1.2
				c-1.5,1.1-2.9,2.3-3.8,3.9c-0.4,0.7-0.5,1.6-0.6,2.4c0,0.3-0.1,0.6-0.1,0.9c-0.3,1.3-0.6,3-1.3,4.5c-1.7,3.8-0.1,9.1,1.1,13l0.1,0.3
				c0.7,2.4,1.6,4.7,2.7,6.8c0.9,1.8,1.9,2.9,3.2,4.2c0.3,0.3,0.5,0.6,0.8,0.9c1.2,1.3,2.3,2.9,3.4,4.3c1.9,2.6,3.8,5.3,6.7,7.2
				c1.8,1.2,4.4,2.9,6.9,3c1.2,0.1,2.4,0.9,3.7,1.7c1,0.7,2.1,1.4,3.3,1.7c1,0.3,2.2,0.5,3.6,0.5c0.4,0,0.8,0,1.3,0
				c0.7,0,1.4-0.1,2.1-0.2c0.6-0.1,1.3-0.1,1.9-0.1c0.5,0,0.9,0,1.3,0c1.8,0,3.1,0.3,4.3,0.8c1.7,0.9,3.4,2.1,5,3.3
				c0.4,0.3,0.7,0.6,1.1,0.8c0.6,0.4,1.2,0.9,1.8,1.2c1.4,0.9,2.9,1.9,4,3.1c1.3,1.4,2.6,3,3.7,4.6c1.9,2.5,3.9,5.2,6.5,7.1
				c1.1,0.8,2.2,1.7,3.4,2.7c2.8,2.2,5.6,4.6,8.9,5.8c1.1,0.4,2.2,0.7,3.3,0.9c1.5,0.4,3,0.7,4.4,1.4c0.6,0.3,1.3,0.7,1.9,1.1
				c1.6,0.9,3.3,1.9,5.2,2.4c1,0.3,2,0.3,3.2,0.3c0.8,0,1.6-0.2,2.4-0.3c0.9-0.1,2-0.3,3-0.3l0.2,0c2.7,0.1,5.6,1,8.6,2.8
				c2.9,1.8,5.2,4.1,7.6,6.6c0.9,0.9,1.8,1.9,2.8,2.8l1.2,1.1c3.7,3.5,7.5,7.2,10.6,11.3c2.8,3.7,4.4,8.3,6,12.7c0.4,1,0.7,2,1.1,3
				c2.3,6.2,4.7,13.6,4.6,21.3c0,5.7-0.6,11.5-1.2,17.1c-0.1,1-0.2,1.9-0.3,2.9l-0.1,1.1c-0.5,4.9-0.9,10-2,14.8
				c-0.6,2.7-1.6,5.4-2.5,8l-0.1,0.1c-0.3,0.9-0.7,1.8-1,2.7c-1.4,4.2-3.2,9.3-5.1,14.2c-1.2,3.1-3.1,5.6-5,8.1
				c-0.7,0.9-1.4,1.9-2.1,2.9c-1.6,2.2-1.9,4.1-2.3,6.3c-0.1,0.4-0.1,0.8-0.2,1.3c-0.5,2.4-1.8,5-3.8,7.7c-1.3,1.7-2.8,2.9-4.5,4.1l0,0
				c-0.6,0.4-1.1,0.9-1.7,1.4c-1.3,1-2.1,2.4-2.1,3.7c0,1,0.3,1.9,1.1,2.6c1,0.9,2.3,1.4,3.5,1.9c0,0,0,0,0,0c-0.4,0-0.8,0-1.2,0
				c-1.7,0.1-3.1,0.4-4.3,1.1c-2.6,1.5-4,4.5-5.3,7.1c-0.3,0.6-0.6,1.3-0.9,1.8c-1.2,2.3-2.8,3.8-4.7,5.5c-0.4,0.3-0.7,0.6-1.1,1
				c-0.5,0.5-1.1,1-1.7,1.5c-2.1,1.8-4.5,3.9-5.7,6.2c-1,1.8-1.1,3.6-0.4,5.4c1.1,2.9,4.4,5.1,7.2,6c1.6,0.5,3,0.7,4.4,0.6
				c2.1-0.1,4.1-0.8,6.1-2.3c1-0.7,1.9-1,2.5-1c0.2,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.3,0.2c0.4,0.3,0.8,0.6,1.3,0.6
				c0.4,0,0.8-0.3,0.9-0.6c0.6-1.3-1.9-6.4-2.1-6.5c-1.2-1.6-1.1-3.3,0.3-5.3c1.1-1.6,2.7-2,4.6-1c1.7,0.9,1.2,2.7,0.1,5.3l-0.2,0.5
				c-0.1,0.4-0.3,0.7-0.4,1.1c-1,2.5-2,5.1-2.6,7.7c-0.4,1.7,0.6,2.9,1.3,3.8c0.8,1,1,1.3,0.7,2c0,0,0,0.1-0.1,0.1l0,0.1
				c-0.4,0.5-1.3,2-0.2,2.8c0.4,0.3,0.8,0.7,1.2,1.2c0.3,0.3,0.6,0.7,0.9,1c0.8,0.7,1.7,1.1,2.7,1c1.8-0.1,3.5-1.5,4.9-2.8
				c0.4-0.3,0.8-0.7,1.1-0.9c0.6-0.5,1.2-1,1.9-1.7c1.5-1.5,3.2-3.1,4.8-3.1c1.4,0,2.9,0.8,4.4,1.5c0.9,0.4,1.8,0.9,2.7,1.2
				c0.6,0.2,1.2,0.4,1.8,0.5c2.1,0.6,4.3,1.2,5.5,2.8c1.3,1.7,0.4,5-0.2,7.2l-0.2,0.6c-0.1,0.2-0.1,0.5-0.2,0.8l0,0.1
				c-0.4,1.2-0.8,2.6-0.4,4c0.3,0.9,0.8,1.5,1.3,2.2c0.3,0.4,0.6,0.7,0.8,1.1c0.3,0.6,0.3,1.5,0.4,2.6c0.1,2.1,0.3,4.5,2.5,5.4
				c0.2,0.1,0.5,0.2,0.7,0.3c1.2,0.6,2.8,1.2,4.3,1.2c0.1,0,0.2,0,0.2,0c0.9,0,1.6-0.3,2.2-0.9c1-0.9,1.7-1.9,2.4-2.9
				c0.6-0.9,1.3-1.8,2-2.5c0.4-0.3,0.9-0.5,1.6-0.6c0.8,0,1.8,0.1,2.6,0.2c0.9,0.1,1.8,0.3,2.7,0.2c1.5-0.1,2.8-1.8,3.8-3.3
				c0.2-0.3,0.4-0.6,0.6-0.8c1.6-1.8,3.4-3.7,5.5-4.8c2.5-1.3,10.1-15.7,12.3-18c0.8-0.8,1.5-1.7,2.2-2.5c1-1.2,2.1-2.5,3.3-3.6
				c1.2-1.2,2.5-1.7,3.9-2.4c0.8-0.4,1.7-0.8,2.6-1.3c3.3-2,6.1-5.2,8.7-8.3c0.8-1,1.6-1.9,2.4-2.7c1-1.1,1.7-2.4,2.4-3.7
				c0.4-0.7,0.7-1.3,1.1-1.9c1.3-2.1,2.7-3,5.3-3.7c2.2-0.5,3.5-2.3,3.9-5c0.1-0.5,0-1,0-1.6c0-0.7-0.1-2.1,0.2-2.5
				c0.5-0.6,1-1.2,1.6-1.8c2.5-2.9,4.9-5.6,5.8-9.8c0.5-2.3,1.4-4.6,2.3-6.8c0.7-1.7,1.4-3.5,1.9-5.3c0.6-2.3,1.4-4.2,2.4-5.9
				c0.7-1.2,1.3-2.2,2.1-2.9c0.4-0.4,0.9-0.8,1.4-1.1c0.8-0.6,1.6-1.2,2.3-2.1c1-1.5,1.2-3,1.4-4.5c0.1-0.6,0.1-1.2,0.3-1.8
				c0.4-1.6,1.2-3.1,2-4.7c0.3-0.6,0.6-1.2,0.9-1.8c1.1-2.3,1.9-4.6,2.6-6.9c0.7-2,1.4-4.1,2.2-6.1c0.9-2,2-3.8,3.5-5.7
				c1.1-1.4,1.7-3,2.3-4.5c0.5-1.5,1.1-2.9,2-4.1c0.6-0.7,1.3-1.2,2-1.7c1-0.7,1.9-1.4,2.7-2.5c0.8-1.1,1.4-2.3,2-3.4
				c0.9-1.8,1.9-3.7,3.4-4.9c1.1-0.9,2.8-1.2,4.7-1.5c2.8-0.5,5.7-1,7.2-3.6c0.7-1.2,0.3-2.8-0.1-4.1c-0.1-0.6-0.3-1.1-0.3-1.5
				c-0.1-2.1,0.6-4.2,1.4-6.2c0.4-1.1,0.8-2.2,1.1-3.4c0.7-2.9,1.2-5.8,1.4-8.6c0-0.6,0.1-1.1,0.1-1.7c0-1.8,0.1-3.3,1.2-4.6
				c2.1-2.4,5.7-4.1,8.1-5.3c0.3-0.1,0.6-0.3,0.9-0.4c1.2-0.5,2.5-1.1,3.2-3c0.9-2.4,1.9-4.6,3.3-6.4c1.6-2.2,3.4-4.7,3.8-7.8
				c0.2-1.5,0.1-2.9,0.1-4.3c-0.1-1.3-0.1-2.6,0-3.9c0.4-3,1.9-5.7,3.1-7.7c0.3-0.5,0.6-1.1,0.8-1.6c0.3-0.7,0.7-1.3,1.1-1.9
				c2-2.9,2.9-5.7,3.8-8.6c0.3-0.8,0.6-1.6,1-2.4c0.7-1.4,1.4-2.8,1.5-4.4c0.1-1.2-0.4-1.9-0.8-2.6c-0.2-0.3-0.4-0.6-0.5-0.9
				c-0.5-1.7,0.3-3.9,1-5.8c0.1-0.4,0.3-0.7,0.4-1c1.1-3.2,2.1-5.8,3.4-8.2c0.6-1.1,1.3-2.2,2-3.2c0.6-0.8,1.1-1.7,1.7-2.5l0.1-0.2
				c0.5-0.9,1.5-2.5,0.6-3.6c-0.3-0.4-0.9-0.6-1.5-0.6c-0.7,0-1.6,0.3-2.2,0.6c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0.1-0.9,0.2-1.3,0.2
				c-4.8,0.3-9.2-4.6-10.2-9.2c-0.2-0.9-0.3-1.9-0.5-2.8c-0.3-2-0.5-4-1.6-6c-0.2-0.5-5.9,12.3-6.2,11.9c-1.2-2.2-2.4-4.4-2.2-6.8
				c0.4-5.6,3.6-10.1,7.3-15c0.4-0.5,0.7-1,1-1.4c0.5-0.8,1-1.5,1.7-2.2c0.5-0.5,1.1-1,1.7-1.5l0,0c0.4-0.3,0.9-0.7,1.3-1.1
				c2-1.8,1.9-3.9,1.8-6c0-0.8-0.1-1.6,0-2.4c0.1-0.9,1.8-2.3,2.8-3.1c0.4-0.3,0.7-0.6,1-0.8c7.4-6.7,15.9-11.5,24.6-13.9
				c5.1-1.4,10.8-2.3,17.1-2.6c2-0.1,4-0.2,6.1-0.2c4.6,0,9.4,0.2,14.8,0.7c2.1,0.2,4.9,0.5,7.7,1.1c0.1,0,0.3,0.1,0.5,0.1
				c0.3,0.1,0.5,0.2,0.9,0.2c0.1,0,0.3,0,0.4,0c0.8,0,1.5-0.5,2.3-1c0.5-0.3,1.2-0.7,1.5-0.7c0,0,0.2,0.1,0.3,0.7
				c0.4,1.5-0.6,3.5-1.6,5.2c-0.4,0.7-0.8,1.4-1,2.1c-0.9,2.3-1.1,4.5-0.7,7.6c0.3,2.3,0.9,4.6,1.5,6.9c0.1,0.2,0.1,0.4,0.2,0.7
				c0.4,1.4,0.9,3.4,2.1,4.3c0.2,0.1,0.5,0.2,0.7,0.2l0.1,0c1.1-0.1,1.7-1.2,2.6-4.7c0-0.1,0-0.1,0-0.2c0.7-2.5,2-4.7,3.2-7l0,0
				c0.4-0.8,0.9-1.6,1.4-2.5l0.3-0.6c0.5-0.9,1-1.9,1.6-2.6c0.2-0.2,0.5-0.4,0.8-0.5c0.4-0.2,0.7-0.3,1.1-0.6c1.1-0.8,2.1-2.4,2-3.8
				c-0.1-0.6-0.4-1.5-1.5-2.1c-0.8-0.4-1.5-0.5-2.3-0.6c-0.5-0.1-1-0.1-1.4-0.3c-0.9-0.4-1.8-1-2.6-1.8c-1.6-1.8-2.7-4.2-2.9-6.5
				c-0.1-2,0.4-4,1-6.1l0,0c0.2-0.6,0.3-1.1,0.4-1.6c0.4-1.4,0.7-2.8,0.8-4.3c0.1-0.8,0-1.5-0.1-2.2c-0.1-0.9-0.1-1.7,0-2.4
				c0.1-0.4,0.2-0.8,0.2-1.2c0.2-1,0.4-11.8,0.4-12.9c0-0.7-0.1-1.3-0.2-2c-0.1-0.5-0.2-1-0.2-1.4c-0.1-1.6,0.8-2.9,1.7-4.3
				c0.5-0.8,1.1-1.7,1.5-2.7c0.7-1.6,0.3-3.1-0.1-4.6c-0.2-0.9-0.5-1.8-0.4-2.6c0.2-1.9,1.9-5.2,3.9-5.8c0.1,0,0.2,0,0.3,0
				c0.8-0.1,1.9,0.6,3,1.3c0.7,0.4,1.3,0.9,2,1.1c0.4,0.2,0.8,0.2,1.2,0.2c0.1,0,0.1,0,0.2,0c1.8-0.1,3.1-1.6,4.1-2.9
				c0.2-0.3,0.4-0.5,0.6-0.7c0.6-0.7,1.4-1.6,2.4-2.1c0.2-0.1,0.5-0.2,0.9-0.3c0.5-0.1,1-0.2,1.5-0.5c1.8-1.1,3.1-3.1,4.2-4.9
				c0.2-0.3,0.4-0.6,0.6-0.9l0.2-0.4c0.8-1.3,1.6-2.5,2.8-3.2c2-1.1,4.4-2.5,6.1-4.5c1.2-1.4,0.8-3,0.4-4.4c-0.1-0.3-0.2-0.6-0.2-0.8
				c0-0.2-0.1-0.5-0.2-0.7l0-0.1c-0.4-1.9-0.9-4.1-0.4-5.7c0.3-0.8,1.3-1.5,2.3-2.1c0.9-0.6,1.9-1.2,2.6-2.1c2.4-3.3-0.7-5.9-2-7
				c-0.1-0.2-0.6-1.9,1.3-11.4c1.1-5.6,2.5-11.1,2.8-11.8c0.7-1.7,0.9-3.4,1.1-5.2c0.1-0.6,0.1-1.1,0.2-1.7c0.1-0.5,0.3-1,0.5-1.5
				c0.4-0.9,0.8-1.8,0.6-3.1c-0.1-1-0.6-2-1-2.9c-0.9-1.8-1.3-2.8-0.2-4c0.3-0.3,0.8-0.7,1.4-1.1c1.5-1.1,3.5-2.5,3.4-4.6
				c-0.1-1.5-1.6-2.3-3.2-3.1c-0.6-0.3-1.3-0.7-1.6-0.9c-0.5-0.6-0.5-1.3-0.4-1.7c0.2-0.9,0.8-1.7,1.6-2.1c1.4-0.7,2.4-1.7,2.7-2.8
				c0.4-1.3,0.2-2.7-0.6-4.4c-0.2-0.4-0.4-0.9-0.7-1.3c-0.9-1.7-1.7-3.1-1.2-5.1c0.6-2.6,2.3-4.6,4-6.7c0.4-0.4,0.7-0.9,1.1-1.3
				c1.7-2.1,1.8-4.6,1.8-7.1c0.1-2.3,0.1-4.4,1.4-6c0.4-0.5,0.9-0.9,1.4-1.2c0.9-0.6,1.8-1.3,2.4-2.6c0.8-1.7,1.2-3.4,1.7-5.1
				c0.1-0.6,0.3-1.1,0.5-1.7l0.1-0.5c0.4-1.5,0.9-3.3,1.9-4.3c0.5-0.5,1.2-0.9,2-1.4c1.6-0.9,3.5-2,3.7-4.3
				C1660.3,1239.6,1658.2,1238.3,1656.3,1237.2z M1311.8,1171.3c-0.9-0.2-2-0.3-3.2-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.6,0-0.9,0
				c0.1,0.5,0.1,1,0.2,1.4c0.1,0.6,0.1,1.2,0.2,1.8c0.1,0.6,0.2,0.8,0.3,0.8c0.3-0.1,0.9-0.5,1.4-0.8c0.7-0.5,1.4-1,2.2-1.2
				c0.3-0.1,0.7-0.2,1.1-0.2C1311.9,1171.8,1311.9,1171.4,1311.8,1171.3z M1290.6,1194.8c0.1,0.2,0.2,0.4,0.3,0.5
				c0.1,0.2,0.4,0.4,0.7,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.4-0.4C1290.9,1195.1,1290.7,1194.9,1290.6,1194.8z M1279.2,1173.4
				c-0.1,0-0.3,0-0.5,0l0.1,0c0.3,0.1,0.7,0.3,0.9,0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1
				C1279.7,1173.5,1279.5,1173.4,1279.2,1173.4z M1220.9,1078.6c-0.2-0.4-0.4-0.9-0.5-1.5l0-0.1c-0.1-0.7-0.4-1.8,0.3-2.7
				c-0.2,0-0.4-0.1-0.6-0.1c-1.1-0.1-2-0.3-2.5-0.6c-1.3-0.7-1.7-1.6-1.8-2.3c-0.1-0.4,0-0.8,0-1.2c-0.1,0-0.2,0.1-0.4,0.1
				c-0.5,0-1-0.2-1.3-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.5-0.7,1c-0.2,0.5-0.2,1.1-0.1,1.4
				c0.8,1.6,2.4,3.2,4.3,4.3c0.4,0.2,0.7,0.4,1.2,0.6l0.1,0C1219,1077.5,1220,1078,1220.9,1078.6z M1235.8,1026.8
				c0.4-0.1,1-0.1,1.6-0.1c0.3,0,0.6,0,0.8,0.1c0.9,0.1,1.8,0.1,2.5,0.5c-0.1-0.5-0.1-1-0.1-1.5c0-0.6-0.1-1.1-0.2-1.6
				c-0.3-0.7-1-1.2-1.8-1.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.5,0.4-0.8,0.7c-0.6,0.6-1.3,1.4-2.4,1.4
				c-0.3,0-0.7,0-1-0.1c-2.1-0.7-4-2.1-5.7-3.3c-0.5-0.3-1-0.5-1.6-0.5c-0.7,0-1.4,0.3-2,0.8c-0.4,0.3-0.5,0.5-0.6,0.8
				c0,0.5,0.4,1.1,1.2,2.1c0.3,0.4,0.7,0.8,0.9,1.3c0.4,0.7,0.4,1.5,0.4,2.3c0,0.3,0,0.6,0,0.8c0-0.1,0.1-0.2,0.2-0.3l0.2-0.4
				c0.6-1.4,1.2-2.7,2.7-2.8c0.6,0,1.3,0.2,2.1,0.6c0.3,0.2,0.7,0.3,1,0.5c0.7,0.4,1.4,0.8,2.1,1
				C1234.7,1026.9,1235.2,1026.9,1235.8,1026.8z M1239.2,1003.3c0.2,0,0.5,0.1,0.7,0.1c0.1,0,0.2,0,0.4,0c-0.1,0-0.2,0-0.3,0
				C1239.9,1003.3,1239.6,1003.3,1239.2,1003.3z M1235.8,1099.3c0.5-1.1,0.6-1.7,0.7-2c-1.8,0.4-2.2,0.8-2.3,1c0,0-0.1,0.1,0,0.3
				c0,0.1,0.2,0.4,0.3,0.6c0.3,0.6,0.6,1.1,0.7,1.6c0,0,0,0,0-0.1C1235.3,1100.5,1235.5,1100,1235.8,1099.3z M1159.7,926.7
				c-0.4,0.3-0.9,0.6-1.6,0.8c-0.7,0.3-1,0.6-0.8,1.3c0.2,1.1,1.4,2.2,1.8,2.4c0.3-0.3,0.3-0.7,0.2-1.8
				C1159.3,928.7,1159.2,927.6,1159.7,926.7z M1140.2,973.1c-0.4,0.3-0.9,0.4-1.4,0.4c-1.3,0.1-2.4-0.7-3.5-1.4
				c-0.8-0.5-1.5-1.1-2.1-1.1c-0.1,0-0.2,0-0.4,0c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0.3,0.1l0.1,0c1.6,0.8,2.5,1.3,2.8,2
				c0.1,0.3,0.1,0.7-0.1,1c-0.3,0.6-0.8,0.9-1.6,1.1c0,0,0.1,0,0.1,0c0.4,0,1.7,0.1,2.6,0.7c0-0.2,0.1-0.3,0.2-0.5
				c0.2-0.3,0.5-0.5,0.9-0.5l0.1,0c0.3,0,0.7,0.1,1.3,0.6C1139.3,974.7,1139.7,973.8,1140.2,973.1z M1133.4,975.5c-0.2,0-0.5,0-0.6,0
				c-0.7,0-1.5-0.4-2.4-0.9c-0.5-0.3-1.4-0.7-1.9-0.9c0,0.1,0,0.3,0,0.4c0,0.7-0.1,2.2-1.5,2.2c-0.2,0-0.5,0-0.7-0.1
				c0,0,0.1,0.1,0.1,0.1c0.5,0.7,1.1,1.4,1.2,2.1c0.1,0.5-0.1,0.9-0.2,1c-0.2,0.2-0.4,0.5-1,0.5c-0.4,0-0.8-0.1-1.4-0.2
				c-0.3-0.1-0.7-0.2-1-0.2c0.3,1,0.7,2,1.2,3c0.4,0.8,0.8,1.6,1.1,2.4c0.2,0.6,0.3,1.3,0.3,2c0-0.1,0.1-0.1,0.1-0.2
				c0.6-0.7,0.8-1,0.9-1.7c0.5-2.9,1.3-4.5,2.4-4.8c0.1,0,0.3-0.1,0.5-0.1c0.6,0,1.2,0.2,1.7,0.5c-0.2-1-0.5-2.1-0.7-2.5
				c-0.3-0.8-0.1-1.3,0.2-1.7C1132.2,975.9,1132.8,975.6,1133.4,975.5z M1143.3,968.5c0,0.1,0,0.3-0.1,0.4c0.6-0.5,1.4-0.6,2-0.8
				c0.4-0.1,0.7-0.2,1-0.3c0.4-0.2,0.5-0.6,0.5-1.7c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.4-0.6,0.9-1.1c-0.4,0.2-0.8,0.5-1.3,0.9
				c-0.5,0.4-0.9,0.6-1.4,0.6c-0.9,0-1.4-0.6-1.8-1c0,0,0,0,0,0c0,0.6-0.1,1.5-0.9,1.9c-0.1,0.1-0.3,0.1-0.4,0.2
				c0.2,0.2,0.4,0.4,0.6,0.7C1143.1,967.2,1143.3,967.8,1143.3,968.5z M1109.8,970.1c0.5-0.5,1.1-1,1.8-1.6c0.3-0.2,0.3-2.4,0.3-3.1
				c0-0.8,0-1.7,0.1-2.3c-0.2,0.4-0.3,0.8-0.5,1.1c-0.3,0.6-0.7,0.9-1.1,1.2c-0.2,0.2-0.4,0.4-0.5,0.5c-0.4,0.6-0.3,1.4-0.2,2.3
				C1109.8,968.8,1109.9,969.4,1109.8,970.1z M1104.2,960.5c0.4-0.3,0.7-0.5,1-0.8C1104.9,960,1104.5,960.3,1104.2,960.5z
				M1271.4,1232.7c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,1-0.4,1.4-0.6c0.2-0.1,1.1-1.4,1.7-2.6c-0.8,0.3-1.6,0.8-2.2,1.6l-0.1,0.2
				C1272.2,1231.6,1271.9,1232.2,1271.4,1232.7z M1288.4,1310.3c0,0-0.3-0.2-0.9-0.2c-1.2,0.1-2.5,0.8-2.8,1.4c0,0.1,0,0.8-0.1,1
				c-0.1,1.4-0.1,3.2-0.7,4.3c0.3,0,0.6,0.1,0.9,0.1c0.5,0.1,1,0.2,1.4,0.2c0.2,0,0.4-0.1,0.4-0.1c0.8-0.7,1-2.2,1.1-3.6
				c0.1-0.9,0.2-1.8,0.5-2.5C1288.5,1310.6,1288.5,1310.4,1288.4,1310.3z M1192.9,940.3c-0.3,0.2-0.5,0.4-0.7,0.5
				c-0.3,0.2-0.5,0.4-0.7,0.5c-0.2,0.1-0.4,0.2-0.7,0.2l-0.1,0c-1,0-1.9-1-2.5-1.8c-0.1-0.1-0.3-0.4-0.4-0.5c-0.2-0.2-0.3-0.2-0.4-0.3
				c0,0,0,0.1,0,0.1c-0.1,0.7,0.3,1,1.4,1.6c1.3,0.7,2.7,1.6,3.2,2.7C1192,942.6,1192.4,941.6,1192.9,940.3z M1136.4,1011.8
				L1136.4,1011.8L1136.4,1011.8L1136.4,1011.8z"
      />
      <path
        fill="none"
        stroke="#3A5686"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M1221.4,1698.7c-0.2,0.1-0.5,0.3-0.7,0.4c0.2,0.2,0.6,0.4,0.9,0.6c0.7,0.4,1.5,0.9,1.8,1.8
				c0.1,0.4,0.1,0.8-0.1,1.2c-0.4,0.6-1,0.7-1.5,0.7c-0.1,0-0.1,0-0.2,0c-0.7,0-1.6-0.1-1.8-0.3c-0.7-0.3-1.2-0.8-1.6-1.2
				c-0.4-0.4-0.7-0.7-1.2-0.8c-0.8-0.2-1.7-0.4-2.7-0.4l-1.5-0.1l0.7-1.4c1.2-2.2,2.5-3.4,4.1-4.9c0.4-0.4,0.9-0.8,1.3-1.3
				c0.8-0.8,1.6-1.3,2.3-1.9c0.4-0.3,0.8-0.6,1.2-0.9c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-1-0.8-1-1.7-0.9-2.3
				c0.5-2.2,4.5-3.6,5.7-4c0.1,0,0.2-0.1,0.3-0.1l0.1,0c0.6-0.2,1.4-0.5,2.2-0.5c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.4,0.7,0.7
				c0.3,0.8,0,1.9-0.9,3.8l-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c-0.2,0.5-0.2,1.2-0.2,2c-0.1,1.6-0.2,3.5-1.4,4.5
				c-0.3,0.2-0.7,0.4-1.1,0.4c-0.5,0-1.1-0.1-1.7-0.2l-0.1,0c-0.4-0.1-0.7-0.2-0.9-0.1c-0.5,0.1-0.6,0.2-0.9,1.2
				c-0.1,0.5-0.3,1-0.5,1.5C1222.8,1697.9,1222.1,1698.3,1221.4,1698.7z M1656.2,1239.3c-0.6-0.4-1.2-0.7-1.5-1
				c-0.2-0.2-0.5-0.4-0.7-0.6l-0.1-0.1c-1.9-1.7-3.8-3.4-6.4-4c-1.2-0.3-2.4-0.4-3.6-0.5c-1.6-0.1-3.2-0.3-4.8-0.9l-0.2-0.1
				c-2.9-1.2-4.5-2.2-4.6-3c0,0,0-0.4,1.6-1.6c0.3-0.3,0.6-0.5,0.8-0.7c0.3-0.3,1-1.1,0.7-2.1c-0.4-1.1-1.8-1.2-3.1-1.3
				c-0.3,0-0.6,0-0.8-0.1c-1.2-0.2-2.4-0.7-3.6-1.1c-0.8-0.3-1.6-0.6-2.4-0.9c-1.6-0.5-3.2-0.7-5.1-0.7c-0.5,0-1,0-1.5,0
				c-1,0.1-1.9,0.1-3,0.2l-0.4,0c-1.2,0.1-2.5,0.2-3.8,0.2c-1.9,0-3.9-0.1-6-0.4c-0.3,0-0.8-0.1-1.3-0.3c-1.2-0.3-2.8-0.6-4-0.6
				c-0.7,0-1.2,0.2-1.6,0.5c-1.2,1-1.1,3-1,4.4c0,0.3,0,0.6,0,0.8c0,2-0.4,3.5-1.8,3.9c-0.3,0.1-0.7,0.1-1.5-0.1
				c-0.5-0.1-1-0.2-1.5-0.2c-0.3,0-0.6,0.1-0.9,0.2c-1.1,0.5-1.8,1.6-2.3,2.5l-0.4,0.7c-0.9,1.4-1.7,2.8-2.9,3.8
				c-1.5,1.3-3.5,1.5-5.6,1.6c-2,0.2-4.1,0.4-6,1.5c-1.1,0.7-1.7,1.7-2.4,2.6c-0.6,0.8-1.1,1.6-2,2.2c-1.1,0.7-2.5,1.6-2.7,3
				c-0.2,0.9,0.1,1.8,0.8,2.6c0,0.1-0.1,0.3-0.6,1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.8,1.2-1.1,2.4-1.3,3.8c-0.1,1-0.5,1.1-1.6,1.2
				c-0.9,0.1-2.1,0.3-3,1.3c-0.4,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3,0.2-0.9,0.6-1.1,1.4
				c-0.2,0.8-0.3,1.5-0.3,2.1c-0.1,1.2-0.1,1.5-1.2,1.8c-1.4,0.4-1.4,1.8-1.4,2.5c0,0.1,0,0.2,0,0.3c-0.1,0.9-0.6,1.4-1.5,2.1l-0.2,0.1
				c-0.7,0.6-1.3,1.4-1.9,2.2c-0.8,1.1-1.6,2.2-2.8,2.5c-1,0.3-1.8,1-2.5,1.7c-0.6,0.6-1.2,1.1-1.8,1.3c-1.4,0.5-3,0.6-4.6,0.7
				c-0.5,0-1,0.1-1.5,0.1c-5.7,0.5-10.4,0.9-14.7,1.3c-0.5,0-1.1,0.1-1.6,0.1c-1,0.1-2.1,0.1-3.1,0.3c-1.7,0.3-3.1,1-3.9,2.1
				c-0.3,0.3-0.4,0.9-0.6,1.7c0,0.1-0.1,0.2-0.1,0.3c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.2-0.4-0.3-0.5-0.4c-0.7-0.5-1.5-0.9-2.2-1.2
				c-0.3-0.2-0.7-0.3-1-0.5c-0.6-0.3-0.9-1.1-1.3-1.8l-0.2-0.3c-0.8-1.7-1.7-2.4-3.1-3.2c-0.7-0.4-1.4-1-2.1-1.6c-1-0.9-2-1.9-3.5-2.4
				c-0.9-0.3-1.8-0.3-2.7-0.4c-0.7-0.1-1.4-0.1-2-0.3c-5.7-1.4-11.3-3.6-16.8-5.7c-2.6-1-5.3-2-8-3c-0.6-0.2-1.3-0.4-1.9-0.7
				c-2.7-0.9-5.2-1.7-7.6-3.4c-1.9-1.3-3.7-2.7-5.4-4.1l-0.7-0.6c-0.4-0.3-0.9-0.7-1.4-1c-1.2-0.8-2.4-1.7-2.9-2.8c-0.8-1.7-2-2-3-2
				c-0.1,0-0.3,0-0.4,0l-0.6,0c-0.4,0-0.8,0-1.1,0c-2-0.3-3.9-1.4-5.8-2.3l-0.4-0.2c-1-0.5-2-1.1-3-1.6l0,0c-1.1-0.6-2.3-1.3-3.5-1.9
				c-0.8-0.4-1.7-0.8-2.5-1.1c-1.2-0.5-2.4-1-3.5-1.7c-0.8-0.6-1.7-1.3-2.5-2c-1.3-1.1-2.6-2.3-4.1-3.1c-0.5-0.3-0.9-0.5-1.4-0.7
				c-1.3-0.6-2.3-1.1-3.4-2.2c-1.4-1.3-2.6-2.9-3.8-4.4c-1.5-2-3.1-4-5.1-5.7c-1.3-1.1-2.3-2.8-3.2-4.5c-1.2-2.1-2.3-4.2-4.4-5.6
				c-3.4-2.2-3.8-5.2-4.2-9.1c-0.1-0.6-0.1-1.3-0.2-1.9c-0.7-5.1-2.3-10.1-3.9-14.9l-0.1-0.4c-0.2-0.7-0.4-1.3-0.6-2
				c-1.3-3.9-1.5-7.8-1.7-12l0-0.7c-0.1-2.4-0.3,5.1-0.4,3.2c0-1,0-1.2-0.3-1.5l-0.3-0.3l-0.4,0c-0.3,0-0.8,0.2-0.9,0.7l-0.1,0l0,0.3
				c0,0.2,0,0.3,0,0.5l0,0.2c0,0.6,0,1.1,0,1.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.5-0.9-0.9-1.1-1.5c-0.4-1-0.3-2.3-0.2-3.4
				c0.1-0.5,0.1-1,0.2-1.5c0.3-1.9,0.7-4-0.3-5.9c-0.3-0.5-0.5-1-0.7-1.6c-0.4-0.9-0.8-1.8-1.3-2.6c-0.2-0.3-0.4-0.6-0.6-0.9
				c-0.4-0.6-0.8-1.2-1.1-1.9c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.7-0.2-1.4-0.6-2.2c-0.6-1.2-1.7-2.3-3-3.1c-0.8-0.5-1.3-0.7-1.9-0.7
				c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.2-1.2,0.6c-0.3,0.5-0.4,1.1-0.2,2.1c-0.2-0.2-0.5-0.5-0.7-0.6c-0.7-0.6-1.4-0.9-2-0.8
				c-2.3,0.1-3,3.8-2.9,5.6c0,0.9-0.1,1.7-0.2,2.2c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.2-0.5l0-0.1c-0.4-0.8-0.9-1.7-0.7-2.4
				c0.1-0.5,0.6-1,1-1.5c0.5-0.6,0.9-1.1,1.2-1.8c0.1-0.3,0.3-0.6,0.4-0.9c0.4-0.9,0.7-1.7,1.3-2.3c0.3-0.4,0.8-0.7,1.2-1
				c0.9-0.7,1.7-1.4,2.3-2.5l0-0.1c0.6-1.2,1.1-2.2,2.1-2.9c0.4-0.3,1-0.4,1.6-0.6c0.7-0.2,1.3-0.4,2-0.8c1-0.6,1.8-2.1,1.9-3.1
				c0.2-1.6-1.3-2.1-2-2.3c-0.3-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.3-0.6-0.7-1.4-1.6-1.6c-0.2-0.1-0.4-0.1-0.6-0.1
				c-0.7,0-1.7,0.5-2.5,0.8c-0.4,0.2-0.8,0.4-1,0.4c-3.4,0.9-8.8,7.9-10.4,10.4c-0.5,0.8-1.6,2.4-2.3,2.5c-0.3,0-0.7-0.4-1.1-1.1
				c-0.4-0.8-0.4-1.5-0.3-2.4c0.1-0.6,0.1-1.2,0.1-1.9c-0.1-1.5-0.8-3.8-1.8-4.9c-0.5-0.6-1.3-0.8-2-1c-0.9-0.3-1.2-0.4-1.2-0.7
				c0-0.4,0.2-1,0.5-1.6c0.3-0.6,0.5-1.2,0.6-1.8c0.3-1.9-0.2-3.8-1.3-5c-0.7-0.8-1.5-1.2-2.1-1.5c-0.9-0.4-1.3-0.6-1.5-1.9
				c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.7-0.2-1.5-0.5-2.2c-0.5-1.3-1.7-1.7-2.8-2.1c-0.4-0.1-0.7-0.2-1-0.4c-2-1.1-4-2.2-5.7-3.8
				c-0.2-0.2-0.5-0.5-0.8-0.8c-1.4-1.5-3.2-3.3-5.3-3.3c0,0-0.1,0-0.1,0c-2.2,0.1-9.5,3.3-9.6,6.6c-0.1,2.6,2.3,5.1,3.9,6.8l0.3,0.3
				c1.9,2,4.2,3.9,7.3,5.9c0.4,0.3,0.8,0.5,1.2,0.7c0.8,0.5,1.6,0.9,2.2,1.5c-0.2,0.5-0.7,1.4-0.9,1.8c-0.4,0.8-0.7,1.3-0.8,1.6
				c-0.4,1.7-0.5,3.5-0.6,5.2c-0.1,1.2-0.1,2.4-0.3,3.5c-0.4,3,1.1,3.6,2.4,4.1c0.5,0.2,1.1,0.4,1.6,0.8c1.4,1.1,2.4,3,2.6,4.6
				c0,0.4-0.3,0.7-2,1.1c-0.4,0.1-0.7,0.2-1,0.3c-3.3,1.2-3.7,2.7-3.9,5.6c0,0.6-0.1,1.2-0.2,1.9c-0.2,2.1-0.5,4.3,0.6,6.3
				c1,1.9,2.3,3.7,3.5,5.4c1.4,1.9,2.8,3.9,3.8,6c0.5,1,0.6,2,0.7,3.2c0,0.4,0.1,0.8,0.2,1.3c0.4,2.3,0.8,4.6,1.3,6.8l0.2,1.2
				c0.2,0.8,0.3,1.6,0.4,2.2c0.2,1,0.3,2,0.6,3c0.2,0.8,0.5,1.7,0.7,2.5c0.4,1.4,0.9,2.8,0.9,4.2c0,0.4-0.2,0.6-1.1,1.1
				c-0.3,0.2-0.6,0.3-0.8,0.5c-0.7,0.5-1.3,0.9-2,1.4c-1.3,0.9-2.7,1.9-4.1,2.8c-1.9,1.2-3.6,1.8-5.2,1.9c-2.1,0.1-3.9-0.9-5.2-2.9
				c-1.4-2.1-2.2-4.7-2.9-7c-0.1-0.3-0.2-0.7-0.3-1.1c-0.4-1.3-0.9-2.8-0.8-4c0-0.9,0.1-1.8,0.2-2.7c0.3-2.9,0.6-5.7-1.1-8.7
				c-1-1.8-2.3-4-3.9-5.8c-1.5-1.7-3.1-2.2-4.8-2.7c-0.6-0.2-1.3-0.4-2-0.7c0,0-0.1,0-0.1,0c-0.2-0.7-0.4-1.5-0.5-2l-0.1-0.4
				c-0.1-0.6-0.2-1.3-0.3-1.9l0-0.9l-0.9-0.1c-2.3-0.2-2.6-0.6-2.5-2c0-0.3,0-0.6,0-1c-0.1-1-0.1-1.4,0-1.5c0.1,0,0.2-0.1,0.2-0.1
				c0.3-0.1,0.9-0.3,1.3-0.8c1.1-1.6,1.1-4.3,0.5-5.9c-0.4-1.1-1.2-1.7-2.3-1.6c-1.6,0.1-3.5,1.6-4.1,2.5c-1.1,1.5-2.6,3-4.3,4.3
				c-0.4,0.3-0.7,0.5-0.8,0.6c-0.1-0.1-0.1-0.3-0.2-0.5c-0.4-1.1-1-2.3-2.4-2.3c0,0-0.1,0-0.1,0c-0.7,0-1.3,0.4-2.3,0.9
				c-0.2,0.1-0.5,0.2-0.7,0.2c0,0,0,0-0.1,0c-0.4,0-0.7-0.2-0.9-0.6l-0.2-0.3l-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.2
				c-0.5,0-1.5,0.3-1.9,1.9c-0.4,1.3-0.4,3.5,0.8,4.3c0.4,0.3,0.8,0.4,1.3,0.4c0.5,0,1-0.2,1.4-0.4c0.1-0.1,0.4-0.2,0.5-0.2
				c0,0,0,0.1,0,0.2c-0.5,0.3-1,0.6-1.4,0.9c-0.8,0.8-1.1,1.8-1.4,2.8c-0.2,0.7-0.4,1.4-0.8,1.9c-0.1-0.3-0.2-0.9-0.3-1.9
				c0-0.4-0.1-0.8-0.1-1.1c-0.5-2.1-1.8-5.7-4.4-6.2c-0.1,0-0.2,0-0.3,0l-0.1,0c-0.8,0-1.7,0.8-2.4,1.6c0.1-1.3,0.1-2.9-1.4-3.6
				c-0.4-0.2-0.9-0.3-1.3-0.2c-1.3,0.1-2.5,0.9-3.6,1.7c-0.7,0.5-1.3,0.9-1.7,1c-0.1,0-0.3,0.1-0.4,0.1c-0.9,0.1-1.8-0.5-2.8-1.1l0,0
				c-1.1-0.7-2.3-1.4-3.7-1.4c-0.1,0-0.1,0-0.2,0c-1.7,0.1-3.1,1.7-4.4,3.2c-0.7,0.8-1.4,1.6-2,1.9c-0.4,0.2-0.6,0.3-0.6,0.3
				c-0.2,0-0.5-1.1-0.6-1.5c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.1-0.1-0.3-0.2-0.5c-1.1-2.8-1.1-3.7-1.1-4c0.2,0,0.3-0.1,0.5-0.1
				c0.8,0,1.7,0.2,2.6,0.5l0,0c0.9,0.3,2,0.6,3.1,0.6c0.3,0,0.5,0,0.8-0.1c1.3-0.2,5.4-2,5.7-4.2c0.3-1.8-0.4-3.7-1-5.3l0,0
				c-0.1-0.3-0.2-0.7-0.3-1c-0.4-1.1-0.6-2.3-0.8-3.5c-0.2-1-0.3-2-0.6-3c-0.2-0.8-0.7-1.5-1.2-2.4c-0.8-1.3-1.6-2.6-1.1-3.5
				c0.9-1.5,3.4-3,5.3-3.1c1.2-0.1,2-0.7,2.7-1.3c0.4-0.4,0.8-0.7,1.4-0.9c0.3-0.1,3.5-1.4,3.3-3c0-0.4-0.3-1.2-1.8-1.3
				c-0.2,0-0.5,0-0.7,0c-1.8,0.1-3.2,0.9-4.5,1.7c-0.7,0.4-1.3,0.8-2.1,1.1c-1,0.4-2.7,0.7-4,0.8c-0.6,0-1.1,0-1.4,0
				c-0.3-0.1-0.6-0.2-0.7-0.5c-1-1.7,0.7-6.5,1.3-8.3l0-0.1c0.1-0.2,0.1-0.4,0.2-0.6c0.6-1.8,0-3.5-0.5-5.1c-0.2-0.5-0.3-1-0.4-1.5
				c-0.1-0.6-0.2-1.1-0.3-1.7c0.7,1.6,1.6,2.8,2.8,2.8c0,0,0.1,0,0.1,0c0.8,0,1.5-0.6,2.1-1.7c1.8-3.3-0.1-5.9-1.9-7.7
				c-0.1-0.1-0.2-0.2-0.4-0.4c-0.4-0.4-1.3-1.2-1.2-1.6c0-0.1,0.2-0.7,3.1-1.6l0.3-0.1c1.1-0.4,2.3-0.5,3.6-0.7
				c1.1-0.2,2.3-0.3,3.5-0.7c2.4-0.7,3.6-1.2,3.5-2.3c-0.1-1.1-1.4-1.2-1.8-1.2c-2.8-0.3-4.9-0.9-6.3-1.9c-0.4-0.3-0.6-0.5-0.6-0.7
				c-0.1-0.6,0.8-1.6,1.4-2.2c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.1c1.1-1.2,3.2-3.4,1.7-5.1c-0.6-0.8-1.7-1.1-2.6-1.5
				c-0.2-0.1-0.5-0.2-0.6-0.2c-1.6-0.7-3-1.3-4.4-2c-5.2-2.7-10.3-8.3-12.6-13.9c-0.7-1.6-0.9-3.3-1.2-5.1c-0.1-1-0.3-2.1-0.5-3.1
				c-0.8-3.6-2.8-4.8-6.1-5.8c-4.7-1.3-7.3-4.3-8.4-9.7c-0.6-2.7-0.8-5.4-0.7-8c0.1-1.4,0.5-2.8,0.9-4.1c0.4-1.2,0.7-2.3,0.9-3.6
				c0.1-0.5,0.1-0.9,0-1.2c0.2,0.1,0.4,0.1,0.7,0.2c0.2,0,0.4,0.1,0.5,0.2c0.3,0.2,0.4,0.6,0.6,1.1c0.3,0.7,0.7,1.7,1.9,2.2
				c0.6,0.3,2.1,0.6,3.5,0.5c1.2-0.1,2.1-0.4,2.5-1c0.3-0.3,0.5-0.9,0.3-1.7c-0.3-1.5-1.3-2.7-2.3-4c-0.7-0.8-1.3-1.6-1.6-2.4
				c-0.3-0.6-0.1-1.5,0.2-2.4c0.4-1.5,0.8-3.2-0.6-4.6c-0.4-0.4-1-0.5-1.5-0.6c0.3-0.4,0.5-1,0.4-1.7c-0.2-0.7-0.7-1.2-1.5-1.2
				c0,0-0.1,0-0.1,0c-0.9,0-2.1,0.7-3.5,1.7c0.3-0.6,0.7-1.4,1-1.7c1.6-1.8,1.6-3,1.3-5.2c0-0.3,0-0.7-0.1-1.1c0-1.4,0-3.1-1.4-3.6
				c0,0-0.1,0-0.1,0c0.4,0,0.8,0.1,1.1,0.1c1.1-0.1,1.8-0.5,2.2-1.3c1.2-2.4,1.3-6.9-0.4-9.1c-0.8-1-2-1.3-3-1.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c-1.2-0.5-2.4-1-3.4-1.9l-0.1-0.1c-1.6-1.5-2.3-2.4-2.7-2.9c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.2
				c0.8,0,1.1-0.4,1.3-0.7c0.4-0.6,0.2-1.4-0.4-2.6c-0.5-0.9-1.1-1.7-1.6-2.4c-1-1.3-1.9-2.6-2-4.1c-0.1-1-0.3-2.7-1.5-2.7l-0.1,0
				c-0.7,0-1.1,0.5-1.8,1.7l0,0c-1.2,0-3.6-1.7-3.8-2.1c-0.4-0.9-0.8-1.9-1.3-3c-0.2-0.6-0.5-1.2-0.7-1.8c0.3,0.1,0.6,0.2,0.9,0.2
				c1-0.1,1.9-0.8,2.2-1.9c0.6-1.9-0.6-2.8-1.4-3.4c-0.2-0.1-0.4-0.3-0.5-0.4c-1.3-1.2-2.4-2.8-3.5-4.4c-0.6-0.9-1.2-1.7-1.9-2.6
				c-1-1.3-1.5-2.3-1.5-3.2c0,0,0-0.1,0-0.1c0,0,0.2-0.1,0.3-0.2c0.4-0.2,1.1-0.6,1.2-1.5c0.1-0.7-0.2-1.5-0.4-2.3
				c-0.4-1.4-0.5-1.8-0.2-2c1-0.6,2.3-2.7,2.7-4.3c0.2-0.9,0.1-1.6-0.3-2.2c-0.3-0.5-0.8-0.7-1.3-0.7l-0.1,0c-1.6,0.1-3.3,3.1-4.2,4.8
				l-0.1,0.2c-0.2,0.4-0.6,1.2-0.9,1.3c-1.7,0.4-1.9,2.4-1.9,3.3c0,0.1,0,0.3,0,0.4c-0.1,0.8-0.6,1.3-1.1,1.6c-0.2,0.1-0.4,0.2-0.7,0.2
				c-0.6,0.1-1.5,0.3-1.9,1.5c-0.8,2-1.1,2.4-2.9,2.5c-0.2,0-0.4,0-0.6,0c-1.1,0-2.2-1.3-3.1-2.4c-0.4-0.5-0.7-0.9-1.1-1.2
				c-0.7-0.7-1.1-0.9-1.6-0.9l-0.1,0c-0.4,0-0.7,0.2-0.9,0.5c-0.4,0.6-0.2,1.4,0.8,3.6l0,0c0,0.1,0.1,0.2,0.1,0.3
				c-0.1,0.1-0.2,0.3-0.3,0.5c-0.6,1.1-1.3,2.3-0.7,3.3c0.3,0.6,1,0.8,1.9,0.8l0.1,0c0.7,0,1.4-0.1,2.2-0.2c0.8-0.1,1.6-0.2,2.4-0.2
				c0.8,0,1.5,0,2,0.1c0.2,0.1,1,1.1,1.4,1.8c-0.3-0.1-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.2-0.9-0.2c-0.2,0-0.4,0-0.6,0
				c-1.1,0.1-2,0.5-2.8,0.9c-0.3,0.2-0.7,0.3-1,0.4c-0.6,0.2-1.1,0.3-1.5,0.4c-0.9,0.1-1.3-0.5-2.2-2.7l0-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
				c-0.4-0.9-0.8-2.1-1.7-2.7c-0.2-0.2-0.5-0.3-0.6-0.3c-0.5-0.2-0.5-0.2-0.6-0.8c-0.1-0.3,0-0.8,0.1-1.3c0.1-1.2,0.3-2.5-0.6-3.4
				c-0.3-0.3-0.6-0.5-0.9-0.6c-0.4-0.3-0.6-0.4-0.8-0.9c-0.8-2.5-4.2-2.7-6.2-2.7c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0
				c0.2-0.2,0.6-0.4,1.1-0.6c0.1,0,0.6-0.1,0.8-0.2c1.7-0.3,3.2-0.5,3.1-1.8c0-0.1,0-0.2,0-0.2c0.3,0.5,0.7,0.8,1.2,0.8l0.1,0
				c0.7,0,1.3-0.5,1.7-0.9c0,0,0.1-0.1,0.1-0.1c0.6-0.5,1.3-0.5,2.2-0.6c0.5,0,1-0.1,1.5-0.2c0.6-0.1,1.9-0.5,2.1-1.6
				c0.1-0.7-0.3-1.3-1.2-1.7c-0.7-0.4-1-0.8-1.4-1.8l0-0.1c-1.4-3-4.4-3.4-6.5-3.4c-0.4,0-0.7,0-1.1,0c-0.7,0-1.3,0-1.8,0
				c-4.7,0-7.8-1-8.7-5.8c0-0.3-0.1-0.5-0.1-0.9c-0.1-0.9-0.3-1.9-0.8-2.8c-0.5-0.9-1.2-1.4-1.9-1.8c-0.3-0.2-0.5-0.4-0.8-0.5
				c-1.1-0.9-2.4-1.2-3.6-1.5c-0.8-0.2-1.6-0.4-2.2-0.7c-1.4-0.7-3-1.4-4.5-1.3c-1.4,0.1-2.5,0.8-3.3,2.1c-0.6,1.1-0.5,2.1-0.3,3
				c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0.1,0,0.1c0,0-0.2,0.1-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.5,0.2-0.7,0.4
				c0.1-0.2,0.2-0.4,0.3-0.7c0.3-1.2-0.2-2.6-1.5-4c-1-1.1-2-2.2-2.1-3.5c0-0.1,0-0.4,0-0.6c0.1-0.8,0.2-2.4-1.2-2.9
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.8,0-1.5,0.4-2.2,0.6c-0.4,0.2-0.8,0.3-1.1,0.4c-0.7,0.2-1.1-0.1-1.9-0.9c-1.5-1.4-3-2-4.6-2.6
				c-0.6-0.2-1.2-0.5-1.8-0.8c-0.7-0.3-1.5-0.5-2.2-0.4c-1.4,0.1-2.7,0.7-3.4,1.8c-0.7,1-0.9,2.2-0.6,3.4c0.3,1,0.9,1.7,1.4,2.4
				c0.2,0.3,0.5,0.5,0.7,0.8c0.6,0.8,1,1.8,1.3,2.9c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.3
				c-2-1.2-4-1.3-5.9-1.5c-1.2-0.1-2.4-0.2-3.6-0.5c-2.7-0.8-5.2-2.9-6.9-5.7c-1.3-2.1-2.4-5.7-1.3-6.6c0,0,0.1,0,0.1,0
				c0.1,0,0.4,0.1,0.6,0.1c0.3,0.1,0.8,0.2,1.2,0.2c0.1,0,0.2,0,0.4,0c1.1-0.2,2-0.8,2.8-1.4c0.1-0.1,0.3-0.2,0.4-0.3
				c0.3-0.2,0.8-0.4,1.3-0.5c1.2-0.4,2.4-0.8,2.7-1.9c0.2-0.7-0.1-1.5-0.8-2.3c-0.6-0.7-1.4-1.2-2.3-1.8c-1.1-0.8-2.3-1.6-2.5-2.4
				c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.7-0.2-1.4-0.7-2c-0.4-0.5-0.9-0.6-1.2-0.6l-0.1,0c-0.3,0-0.7,0.1-1.1,0.6c-0.5,0.6-0.8,1.6-0.8,2.2
				c0,0.2,0,0.5,0.1,0.7c0.2,1.7,0.2,2.1-1.1,2.8c-1.8,1.1-3.9,2.1-6.2,3.3c-0.2,0.1-0.5,0.2-0.8,0.3c-1,0.3-2.7,1-2.2,3.1
				c0.5,2,1.7,3.4,2.8,4.8c0.9,1.1,1.7,2.1,2.1,3.3c0.2,0.5,0.1,1.2,0,1.8c-0.1,0.6-0.1,1.2-0.1,1.8c0,0.2,0.1,0.5,0.1,0.8
				c0.1,0.6,0.2,1.2,0,1.6c-0.3,0.5-1.2,0.9-2,1.2c-0.4,0.2-0.8,0.3-1.1,0.5l-0.4,0.2c-0.8,0.5-1.8,1-2.6,1c-0.2,0-0.5,0-0.7-0.1
				c-1.4-0.5-1.9-2.7-2.1-4c-0.3-1.6,0-2.1,1.1-3.3c1.1-1.3,2.4-2.8,3.4-4.4c0.4-0.6,0.5-1.2,0.3-1.8c-0.3-1.2-1.5-1.9-2.5-2.4
				c-0.1-0.1-0.3-0.2-0.4-0.2c-1-0.6-2.2-0.9-3.3-1.1c-1.2-0.3-2.3-0.5-3-1.1c-0.3-0.3-0.6-0.6-1-1c-0.3-0.3-0.6-0.7-0.9-1
				c-0.6-0.6-1.4-1-2.1-1.4c-0.5-0.3-0.9-0.5-1.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c0.3,0.1,0.7,0.1,1.1,0.1c0.5,0,2.2-0.2,2.6-1.4
				c0.2-0.5,0.2-1.3-0.7-2.2c-1.9-1.9-2.9-3.9-4.2-6.4l-0.1-0.2c-1-1.9-2.1-2.6-3.4-3.5c-0.3-0.2-0.7-0.4-1.1-0.7c-2-1.4-4-3.4-5.8-6.1
				c-1.4-2-2.3-4.2-2.7-7c0-0.2,0-0.3-0.1-0.6c-0.2-1.6-0.5-4.1-2.4-4.1c0,0-0.1,0-0.1,0c-0.6,0-1.1,0.3-1.7,0.9
				c-0.3,0.3-0.5,0.7-0.8,1.1c-0.3,0.5-0.5,0.7-0.5,0.8c-0.3-0.2-1-0.8-1.3-1.5c-0.2-0.5-0.2-0.8-0.2-1.2c0.1-1.5-0.6-2.1-2.7-2.4
				c-0.3,0-0.7-0.1-1.1-0.1c-0.9-0.1-2.4-0.1-2.5-0.7l0-0.1c-0.2-0.8-0.2-1.2-0.2-1.4c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.3,1.4-0.8
				c0.7-1.3,0.5-2.8,0.3-4.3c-0.1-1-0.3-1.9-0.1-2.7c0.5,0.3,1.3,1,2.8,2.4c0.3,0.2,0.4,0.4,0.5,0.5c0.2,0.1,0.5,0.6,0.8,1
				c0.8,1.1,1.9,2.6,3.3,2.5c0.6,0,1.1-0.3,1.5-0.8c1.2-1.4,1-3.9,0.7-6.1c-0.1-1-0.2-1.9-0.1-2.5c0.2-1.6,0.5-2.8,0.9-3.8
				c0.2-0.4,0.4-0.7,0.7-1.1c0.4-0.5,0.8-1,1.1-1.7c0.5-1.4,0.2-2.4-0.2-2.9c-0.5-0.7-1.4-1.1-2.5-1.1c-1.1,0.1-2.1,0.6-2.8,1.4
				c-0.5,0.6-0.9,1.3-1.2,1.9c-0.7,1.3-1.1,1.9-2.1,1.9l-0.1,0c-1,0-1.9-0.2-2.9-0.5c-1.2-0.3-2.5-0.6-4-0.5c-0.2,0-0.5,0-0.7,0.1
				c-1.8,0.3-2.7,1.3-3.4,2.2c-0.4,0.5-0.8,1-1.4,1.4c-1.1,0.6-2.4,1-4,1.1c-1.6,0.1-3.2-0.1-4.7-0.6c-0.3-0.1-0.9-0.3-1.4-0.6
				c-1.6-0.7-3.5-1.5-5.1-1.5c-0.8,0-1.4,0.3-1.9,0.7c-1.2,1.2-1,2.7-0.8,3.9c0.1,0.4,0.1,0.8,0.1,1.2c0,0.2-0.7,0.8-1,1
				c-0.4,0.3-0.8,0.6-1,1l-0.8,1l1.2,0.5c1.5,0.6,2.7,1.4,3.8,2.2c0.9,0.7,1.4,1.4,1.6,2c0.1,0.2,0.1,0.3,0.1,0.5
				c0.3,1,0.7,2.4,2.5,2.7l0.3,0.1c1.3,0.2,2.1,0.3,3,1.2c1.3,1.2,2.6,2.6,3.9,3.9l0.1,0.1c4.1,4.3,9.3,10,14,16.5
				c5.4,7.5,10.9,15.1,17,23.1c3.6,4.7,6.8,9.1,9.3,13.8c1.3,2.5,1.5,5.2,1.8,8l0,0.2c0,0.3,0.1,0.7,0.1,1c0,0.5,0.1,1,0.1,1.5
				c0.3,3.3,0.7,7.4-0.7,10.1c-0.4,0.7-2.8,0.8-3.7,0.9l-1.3,0.1c-2.1,0.1-4,0.3-4.9,1.5c-0.4,0.6-0.6,1.2-0.4,2
				c0.6,2.9,3.5,5.7,5.9,7.9c0.6,0.5,1.2,1.1,1.6,1.6c0.3,0.3,0.6,0.6,0.9,0.9c0.8,0.8,1.7,1.7,2.4,2.6c0.3,0.3,0.7,0.8,1.3,0.8
				c-0.3,0.5-0.5,1-0.5,1.5c-0.1,1.6,1.3,3.6,2.4,5c0.2,0.3,0.4,0.6,0.6,0.8c0.1,0.1,0.1,0.2,0.2,0.4l0,0.1c0.8,1.2,1.8,2.9,3.3,2.9
				c0,0,0.1,0,0.1,0c0.3,0,0.6-0.1,0.8-0.3c0,0.5,0,0.9,0.2,1.3c0.7,1.6,1.9,2.9,3,4.2c0.3,0.3,0.6,0.7,0.9,1c2.3,2.7,4.1,5.4,6.1,8.3
				c0.7,1,1.3,2,2,2.9c0.1,0.1,0.1,0.2,0.2,0.3c0.7,1.1,1.6,2.4,2.9,2.3c0.1,0,0.3,0,0.4-0.1c-0.3,0.7-0.5,1.5,0,2.6
				c1.2,2.8,3.3,5,5.2,7.2c0.9,1,1.8,2,2.6,3.1c1.5,1.9,2.9,3.9,4.2,5.7l0,0c1.5,2,3,4.1,4.5,6.1c1.5,1.9,3,3.8,4.5,5.8l0.5,0.7
				c6.8,8.6,13.8,17.6,20.3,26.6c2.6,3.5,5.4,7.1,8.1,10.5l0,0c7.1,9,14.4,18.4,19.2,29c0.7,1.5,0.9,3.3,1.2,5.1
				c0.1,0.9,0.2,1.8,0.4,2.6c0.1,0.5,0.2,1.1,0.3,1.7c0.3,2.6,0.8,5.9,4.4,6.2c1,0.1,2.4,0.2,3.9,0.2c0.4,0,0.9,0,1.3,0
				c3.9-0.2,6.4-1.5,7.4-3.7c0.3-0.6,0.4-1.2,0.5-1.8c0.1-0.3,0.1-0.7,0.2-1c0.1-0.3,0.2-0.7,0.4-1c0.2-0.5,0.5-1.1,0.6-1.8
				c0.1-0.8,0-1.5-0.5-2.2c-1.2-1.7-4.2-2.4-5.4-2.6c-0.1,0-0.2,0-0.2,0c0.1,0,0.2-0.1,0.3-0.1c1.2-0.6,2.3-1.1,2.4-2.1
				c0.1-0.4-0.1-0.9-0.4-1.2c-0.8-0.9-1.9-1.5-3-2c-0.8-0.4-1.5-0.8-2.1-1.3c-0.9-0.7-1.5-2-2.1-3.2c-0.2-0.5-0.4-0.9-0.7-1.3
				c-1-1.8-2.3-2.9-3.7-3.9c-0.7-0.6-1.4-1.1-2.1-1.8c-0.1-0.1-0.2-0.2-0.4-0.4l0,0c-0.9-0.9-1.7-1.7-2.4-2.4c0.6,0.5,1.2,0.9,1.6,1.3
				c2.1,1.6,2.4,1.9,2.9,1.9c0,0,0,0,0,0l0.2,0c0.7-0.1,1.3-0.4,1.9-0.6c0.6-0.2,1-0.4,1.5-0.5c0.4,0,0.8,0.1,1.3,0.3
				c0.8,0.4,1.3,1.3,1.8,2.2c0.2,0.3,0.4,0.7,0.6,1c2.4,3.6,4.6,6.2,6.8,8.2l0.1,0.1c0.7,0.6,2,1.8,3.4,1.8c0,0,0.1,0,0.1,0
				c0.2,0,0.4-0.1,0.6-0.1c1.1-0.4,2-1.3,2.8-2l0.2-0.2c0.2-0.1,0.3-0.4,0.5-0.6c0.1-0.1,0.4-0.5,0.5-0.7c0.1,0,0.3,0.1,0.4,0.1
				c0.3,0.1,0.7,0.2,1,0.1c0.6,0,0.9-0.3,1.1-0.6c0.3-0.5,0.4-1.1,0.2-2.1c-0.1-0.5-0.3-1-0.6-1.4c0.5,0.3,1.1,0.4,1.7,0.3
				c0.8,0,1.7-0.4,2.5-0.9c2.8-2,6.2-6.8,5.1-10.4c-0.1-0.2-0.1-0.3-0.3-0.5c0,0,0.1,0,0.1,0.1l0,0c0.9,0.5,1.5,1,1.9,1.7
				c0.1,0.2,0.1,0.4-0.6,1.2c-0.2,0.2-0.4,0.5-0.6,0.8c-0.6,1-1.1,2-1.6,3.1c-0.7,1.7-1.5,3.2-2.8,4.3c-0.1,0.1-0.4,0.3-0.6,0.4
				c-1.1,0.8-2.6,1.8-2.6,3.3c0,1.9,2.6,2.9,4.3,3.5c0.3,0.1,0.6,0.2,0.7,0.3c1.9,0.9,3.2,1.8,3.3,3.7c0.1,0.7-0.1,0.9-1.6,1.3
				c-0.6,0.1-1.2,0.3-1.7,0.6c-0.2,0.1-0.5,0.2-0.9,0.2c-0.9,0.1-2.1-0.2-3.3-0.5c-1.3-0.3-2.6-0.6-3.8-0.5c-2.3,0.1-3.5,1.6-3.5,4.3
				c0,0.3,0,0.6-0.1,1c-0.2,2.1-0.4,4.9,2.5,6c1.4,0.5,2.7,0.2,3.6-0.8c0.1-0.1,0.3-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4
				c0.4,0.3,0.7,0.9,1,1.5c0.4,1,1,2.1,2.1,2.6c0.4,0.2,0.8,0.3,1.3,0.2c1.7-0.1,3.7-1.5,5-2.4c0.2-0.1,0.3-0.2,0.4-0.3
				c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.4,0.3,0.9,0.4,1.3c0.4,1.1,0.7,2.2,0.4,3.2c-0.8,2.8-1.6,5.5-0.3,8.6c0.3,0.7,0.6,1.3,0.9,1.8
				c0.7,1.4,1.2,2.1,0.8,3.9c-0.1,0.5-0.3,1-0.5,1.6c-0.5,1.5-1.1,3.2-0.5,4.7c0.2,0.5,0.6,1.1,1,1.8c0.7,1,1.8,2.8,1.6,3.6
				c-0.1,0.2-0.2,0.3-0.5,0.5c0,0,0,0-0.1,0l-0.1,0c-1.3,0-4-1.8-4.4-2.6c-0.1-0.2,0-0.7,0.1-1.1c0-0.2,0.1-0.4,0.1-0.7
				c0.1-1.7,0.1-3.3-0.1-4.6c-0.4-2.6-2.5-3.1-4.2-3.6c-0.6-0.2-1.1-0.3-1.6-0.5c-2.2-1-3.8-2.8-4.7-5.5c-0.1-0.3-0.2-0.7-0.3-1l0-0.1
				c-0.6-1.8-1.2-3.7-2.2-5.5c-1-1.8-2.2-2.6-3.8-2.5c-0.7,0-1.4,0.2-2.5,0.6c-2,0.7-3.2,1.9-3.8,3.6c-1.3,4,2.1,9.5,3.6,11.6
				c1.3,1.9,2.9,3.8,4.4,5.6c1.5,1.8,3.1,3.7,4.5,5.8c1.3,1.9,2.3,3.9,3.4,5.8c0.8,1.6,1.7,3.2,2.7,4.7c0.5,0.7,0.9,1.5,1.4,2.2
				c2.2,3.5,4.5,7.2,6.3,11c1.2,2.5,1.6,5.2,2.1,8c0.3,2,0.7,4.1,1.3,6.1c1.7,5.5,2.2,11.3,2.6,16.3c0.3,3.3,1.8,3.9,3,3.9
				c2.2-0.1,4.9-2.8,7.2-5.3c0.2-0.2,0.4-0.4,0.6-0.6l0.1-0.1c0.5-0.5,1.1-1.2,1.7-1.3c0.2,0,0.4-0.1,0.6-0.1c0.9-0.1,1.8-0.3,2.6-1.7
				c0.9-1.6,2.5-3.1,4.1-4.5c0.3-0.3,0.6-0.5,0.9-0.8c0.1-0.1,0.3-0.3,0.5-0.5l0.1-0.1c1-1,2.5-2.4,3.6-2.4l0,0c0.6,0,1.1,0,1.7,0
				c1.3-0.1,2.7-0.3,4-0.5l0,0c-0.6,0.5-1.2,1-1.7,1.6c-0.7,0.8-1.6,2.1-1.5,3.3c0.2,2.2,2.4,6.3,5.2,6.3c0.1,0,0.1,0,0.2,0l0.1,0
				c0.8-0.1,1.9-0.5,2.5-1.4c0.4-0.5,0.6-1.1,0.5-1.8c-0.1-0.3-0.2-0.6-0.4-0.9c0.5,0,1.1,0,1.2,0.1c0.1,0,0.2,0,0.2,0
				c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.5-0.3,1-0.2,1.6c0.3,4.2,3.7,4.6,6.8,5c1.5,0.2,3,0.4,4.1,1c-0.2,0.1-0.3,0.3-0.5,0.5
				c-0.5,0.8-0.6,1.9-0.4,2.8c-0.8-1.3-1.6-2.3-2.7-2.3l-0.1,0c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.7,0.8
				c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.5-0.4-1.1-0.4c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5,0.1c-1.7,0.5-3.6,1.2-5,2.7l-0.2,0.2
				c-0.6,0.7-0.7,0.8-1.4,0.8c-0.1,0-0.1,0-0.2,0c-1.7,0-3,0.7-4.1,2.1c-0.1,0.1-0.6,0.6-1,0.9c-2,1.7-3.5,3.1-3.4,4.4
				c0,0.5,0.3,1,0.7,1.3c1.5,1.1,3,1.3,4.5,1.4c0.8,0.1,1.6,0.2,2.4,0.5l0.1,0c0.2,0.1,0.3,0.1,0.5,0.2c0,0-0.1,0-0.1,0
				c-0.2,0.1-0.3,0.1-0.5,0.2c-0.5,0.2-1.2,0.5-1.7,0.6l-0.1,0c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.4-0.9-0.8-1.7-1
				c-0.8-0.2-1.5,0.1-1.9,0.7c-0.7,1.2,0.2,3.5,1.3,5.9c0.1,0.3,0.2,0.5,0.3,0.6c0,0,0,0.1,0,0.1c-0.4-0.3-0.8-0.7-1.3-1.1
				c-1.4-1.6-1.7-3.6-2-5.8c-0.1-0.6-0.2-1.3-0.3-2c-0.5-2.5-1.2-4.5-2.2-6.5c-0.5-1-0.4-2.1-0.2-3.3c0.1-1.3,0.3-2.6-0.3-3.9
				c-0.6-1.5-1.8-2.1-3.5-2.1c-0.7,0-1.4,0.2-2.1,0.3c-0.6,0.1-1.1,0.2-1.5,0.3l0,0c-0.3,0-0.7,0-1.1,0l-0.1,0c-0.5,0-1,0-1.6,0
				c-2.2,0.1-3.4,1.1-3.6,2.9c-0.4,3.3,0.9,5.9,2.2,8.5c0.3,0.6,0.7,1.4,1,2.1c1.8,4.1,3.6,8.4,5.3,12.5c1.5,3.6,3,7.3,4.6,10.9
				c0.2,0.5,0.5,1.1,0.7,1.7c0.8,2,1.7,4.2,3,5.9c0.6,0.8,1.3,0.9,1.7,0.9c0,0,0.1,0,0.1,0c-0.8,0.6-1.5,1.5-1.6,2.9
				c-0.3,4.5,1.8,9.4,3.6,13.7c0.5,1.2,1,2.4,1.4,3.5c1.1,2.9,2.4,5.8,3.6,8.6c0.9,2,1.8,4,2.6,6c1,2.4,2,5,2.4,7.7
				c0.2,1.1,0.3,2.3,0.4,3.4c0.1,1.7,0.3,3.5,0.8,5.2l0.1,0.4c0.4,1.4,0.8,3.3,1.8,4.3c0.6,0.6,1.4,0.9,2.2,0.9c2.1-0.1,4-2.9,4.5-4.3
				l0-0.1c1.6-4.3,2.7-4.9,3.2-4.9c0,0,0.1,0,0.1,0c0.2,0,0.4,0.2,0.6,0.6c0.7,1.2,0.9,3.7,0.6,4.6l0,0.1c-0.5,1.6-0.9,2.9-2.3,3.5
				c-0.9,0.4-2.1,0.7-3.6,0.7c-0.5,0-1,0-1.7,0c-0.3,0-0.7,0-1,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.6,0-1.1,0-1.5,0l0,0
				c-2.1,0.1-4.5,0.5-6.5,2.6c-2.3,2.4-1.9,6.6-1,9.6c0.6,2.2,0.8,4.4,1.1,6.9c0,0.3,0.1,0.6,0.1,1c0.2,2.2,0.4,5.3,2.4,6.7
				c0.3,0.2,0.5,0.3,0.8,0.4c-0.4,0.5-0.6,1.1-0.8,1.7l-0.1,0.2c0,0.1-0.1,0.2-0.2,0.4c-0.7,1.9-2.1,5.4-0.2,6.9
				c0.6,0.5,1.4,0.7,2.2,0.7c2.2-0.1,4.4-2.4,5.2-3.8c0.5-0.9,1.5-1.6,2.3-1.6c0.5,0,0.9,0.3,1.2,0.9c0.7,1.5-0.9,4.6-2.2,5.7
				c-0.8,0.6-1.5,1.4-2.2,2.1c-0.9,1-1.8,1.9-2.8,2.5c-0.3,0.2-0.6,0.3-0.9,0.3l-0.1,0c-0.9,0-1.6-1.3-1.7-2.6
				c-0.1-0.9-0.3-2.5-1.7-2.5l-0.1,0c-0.6,0-1,0.4-1.6,0.9c-0.3,0.2-0.6,0.5-0.9,0.7c-1,0.7-2,1-3.2,0.9c0,0-0.2,0-0.3-0.1
				c-0.3-0.1-0.7-0.2-1.1-0.2c-0.8,0-1.2,0.6-1.4,0.9c-1,2.1-0.3,5.5,0.3,8.2c0.1,0.6,0.3,1.3,0.4,1.8c0.2,1,0.4,2.1,0.5,3.1
				c0.5,2.9,1.1,5.9,1.2,8.8c0.2,3.4-2.1,7.2-4,10.3l-0.5,0.8c-0.2,0.4-0.5,0.7-0.7,1c-0.6,0.7-1.1,1.4-1.4,2.5
				c-0.5,1.8-0.4,3.7-0.3,5.4c0,0.6,0.1,1.3,0.1,2c0,5.1-0.2,10.4-0.5,15.5l0,0.5c-0.1,2.1-0.2,4.2-0.2,6.1c-0.3,8.9-0.6,18-2.9,26.5
				c-1,3.5-3.2,6.6-5.3,9.7l0,0.1c-0.5,0.8-1.2,1.7-1.7,2.5c-1.6,2.4-3.1,4.6-4.7,6.8l-0.4,0.5c-1.2,1.7-2.4,3.4-4.3,4
				c-1,0.3-2.2,0.6-3.6,0.6c-0.4,0-0.8,0-1.3,0c-0.9,0-1.8,0-2.6-0.1c-0.9,0-1.8-0.1-2.7-0.1c-0.5,0-1,0-1.5,0
				c-1.3,0.1-2.4,0.2-3.4,0.5c-2.7,0.7-5.3,1.6-7.5,2.7c-1.5,0.7-2.6,1.7-3.7,2.6c-0.5,0.4-1,0.9-1.5,1.3c-1.8,1.4-4,2.6-6.2,3.4
				c-0.1,0-0.3,0.1-0.4,0.1c-0.6,0.1-1.4,0.4-1.9,1.3c-0.2,0.3-0.3,0.7-0.5,1c-0.4,1-0.8,1.8-1.6,2.5c-1.5,1.3-2.9,2.2-4.3,2.8
				c-0.7,0.3-1.4,0.5-2.2,0.6c-0.7,0.2-1.5,0.3-2.2,0.6c-2.1,0.9-3.5,2.5-4.8,4c-0.7,0.8-1.3,1.5-2.1,2.2c-0.4,0.4-0.9,0.8-1.4,1.2
				c-1.5,1.1-2.9,2.3-3.8,3.9c-0.4,0.7-0.5,1.6-0.6,2.4c0,0.3-0.1,0.6-0.1,0.9c-0.3,1.3-0.6,3-1.3,4.5c-1.7,3.8-0.1,9.1,1.1,13l0.1,0.3
				c0.7,2.4,1.6,4.7,2.7,6.8c0.9,1.8,1.9,2.9,3.2,4.2c0.3,0.3,0.5,0.6,0.8,0.9c1.2,1.3,2.3,2.9,3.4,4.3c1.9,2.6,3.8,5.3,6.7,7.2
				c1.8,1.2,4.4,2.9,6.9,3c1.2,0.1,2.4,0.9,3.7,1.7c1,0.7,2.1,1.4,3.3,1.7c1,0.3,2.2,0.5,3.6,0.5c0.4,0,0.8,0,1.3,0
				c0.7,0,1.4-0.1,2.1-0.2c0.6-0.1,1.3-0.1,1.9-0.1c0.5,0,0.9,0,1.3,0c1.8,0,3.1,0.3,4.3,0.8c1.7,0.9,3.4,2.1,5,3.3
				c0.4,0.3,0.7,0.6,1.1,0.8c0.6,0.4,1.2,0.9,1.8,1.2c1.4,0.9,2.9,1.9,4,3.1c1.3,1.4,2.6,3,3.7,4.6c1.9,2.5,3.9,5.2,6.5,7.1
				c1.1,0.8,2.2,1.7,3.4,2.7c2.8,2.2,5.6,4.6,8.9,5.8c1.1,0.4,2.2,0.7,3.3,0.9c1.5,0.4,3,0.7,4.4,1.4c0.6,0.3,1.3,0.7,1.9,1.1
				c1.6,0.9,3.3,1.9,5.2,2.4c1,0.3,2,0.3,3.2,0.3c0.8,0,1.6-0.2,2.4-0.3c0.9-0.1,2-0.3,3-0.3l0.2,0c2.7,0.1,5.6,1,8.6,2.8
				c2.9,1.8,5.2,4.1,7.6,6.6c0.9,0.9,1.8,1.9,2.8,2.8l1.2,1.1c3.7,3.5,7.5,7.2,10.6,11.3c2.8,3.7,4.4,8.3,6,12.7c0.4,1,0.7,2,1.1,3
				c2.3,6.2,4.7,13.6,4.6,21.3c0,5.7-0.6,11.5-1.2,17.1c-0.1,1-0.2,1.9-0.3,2.9l-0.1,1.1c-0.5,4.9-0.9,10-2,14.8
				c-0.6,2.7-1.6,5.4-2.5,8l-0.1,0.1c-0.3,0.9-0.7,1.8-1,2.7c-1.4,4.2-3.2,9.3-5.1,14.2c-1.2,3.1-3.1,5.6-5,8.1
				c-0.7,0.9-1.4,1.9-2.1,2.9c-1.6,2.2-1.9,4.1-2.3,6.3c-0.1,0.4-0.1,0.8-0.2,1.3c-0.5,2.4-1.8,5-3.8,7.7c-1.3,1.7-2.8,2.9-4.5,4.1l0,0
				c-0.6,0.4-1.1,0.9-1.7,1.4c-1.3,1-2.1,2.4-2.1,3.7c0,1,0.3,1.9,1.1,2.6c1,0.9,2.3,1.4,3.5,1.9c0,0,0,0,0,0c-0.4,0-0.8,0-1.2,0
				c-1.7,0.1-3.1,0.4-4.3,1.1c-2.6,1.5-4,4.5-5.3,7.1c-0.3,0.6-0.6,1.3-0.9,1.8c-1.2,2.3-2.8,3.8-4.7,5.5c-0.4,0.3-0.7,0.6-1.1,1
				c-0.5,0.5-1.1,1-1.7,1.5c-2.1,1.8-4.5,3.9-5.7,6.2c-1,1.8-1.1,3.6-0.4,5.4c1.1,2.9,4.4,5.1,7.2,6c1.6,0.5,3,0.7,4.4,0.6
				c2.1-0.1,4.1-0.8,6.1-2.3c1-0.7,1.9-1,2.5-1c0.2,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.3,0.2c0.4,0.3,0.8,0.6,1.3,0.6
				c0.4,0,0.8-0.3,0.9-0.6c0.6-1.3-1.9-6.4-2.1-6.5c-1.2-1.6-1.1-3.3,0.3-5.3c1.1-1.6,2.7-2,4.6-1c1.7,0.9,1.2,2.7,0.1,5.3l-0.2,0.5
				c-0.1,0.4-0.3,0.7-0.4,1.1c-1,2.5-2,5.1-2.6,7.7c-0.4,1.7,0.6,2.9,1.3,3.8c0.8,1,1,1.3,0.7,2c0,0,0,0.1-0.1,0.1l0,0.1
				c-0.4,0.5-1.3,2-0.2,2.8c0.4,0.3,0.8,0.7,1.2,1.2c0.3,0.3,0.6,0.7,0.9,1c0.8,0.7,1.7,1.1,2.7,1c1.8-0.1,3.5-1.5,4.9-2.8
				c0.4-0.3,0.8-0.7,1.1-0.9c0.6-0.5,1.2-1,1.9-1.7c1.5-1.5,3.2-3.1,4.8-3.1c1.4,0,2.9,0.8,4.4,1.5c0.9,0.4,1.8,0.9,2.7,1.2
				c0.6,0.2,1.2,0.4,1.8,0.5c2.1,0.6,4.3,1.2,5.5,2.8c1.3,1.7,0.4,5-0.2,7.2l-0.2,0.6c-0.1,0.2-0.1,0.5-0.2,0.8l0,0.1
				c-0.4,1.2-0.8,2.6-0.4,4c0.3,0.9,0.8,1.5,1.3,2.2c0.3,0.4,0.6,0.7,0.8,1.1c0.3,0.6,0.3,1.5,0.4,2.6c0.1,2.1,0.3,4.5,2.5,5.4
				c0.2,0.1,0.5,0.2,0.7,0.3c1.2,0.6,2.8,1.2,4.3,1.2c0.1,0,0.2,0,0.2,0c0.9,0,1.6-0.3,2.2-0.9c1-0.9,1.7-1.9,2.4-2.9
				c0.6-0.9,1.3-1.8,2-2.5c0.4-0.3,0.9-0.5,1.6-0.6c0.8,0,1.8,0.1,2.6,0.2c0.9,0.1,1.8,0.3,2.7,0.2c1.5-0.1,2.8-1.8,3.8-3.3
				c0.2-0.3,0.4-0.6,0.6-0.8c1.6-1.8,3.4-3.7,5.5-4.8c2.5-1.3,10.1-15.7,12.3-18c0.8-0.8,1.5-1.7,2.2-2.5c1-1.2,2.1-2.5,3.3-3.6
				c1.2-1.2,2.5-1.7,3.9-2.4c0.8-0.4,1.7-0.8,2.6-1.3c3.3-2,6.1-5.2,8.7-8.3c0.8-1,1.6-1.9,2.4-2.7c1-1.1,1.7-2.4,2.4-3.7
				c0.4-0.7,0.7-1.3,1.1-1.9c1.3-2.1,2.7-3,5.3-3.7c2.2-0.5,3.5-2.3,3.9-5c0.1-0.5,0-1,0-1.6c0-0.7-0.1-2.1,0.2-2.5
				c0.5-0.6,1-1.2,1.6-1.8c2.5-2.9,4.9-5.6,5.8-9.8c0.5-2.3,1.4-4.6,2.3-6.8c0.7-1.7,1.4-3.5,1.9-5.3c0.6-2.3,1.4-4.2,2.4-5.9
				c0.7-1.2,1.3-2.2,2.1-2.9c0.4-0.4,0.9-0.8,1.4-1.1c0.8-0.6,1.6-1.2,2.3-2.1c1-1.5,1.2-3,1.4-4.5c0.1-0.6,0.1-1.2,0.3-1.8
				c0.4-1.6,1.2-3.1,2-4.7c0.3-0.6,0.6-1.2,0.9-1.8c1.1-2.3,1.9-4.6,2.6-6.9c0.7-2,1.4-4.1,2.2-6.1c0.9-2,2-3.8,3.5-5.7
				c1.1-1.4,1.7-3,2.3-4.5c0.5-1.5,1.1-2.9,2-4.1c0.6-0.7,1.3-1.2,2-1.7c1-0.7,1.9-1.4,2.7-2.5c0.8-1.1,1.4-2.3,2-3.4
				c0.9-1.8,1.9-3.7,3.4-4.9c1.1-0.9,2.8-1.2,4.7-1.5c2.8-0.5,5.7-1,7.2-3.6c0.7-1.2,0.3-2.8-0.1-4.1c-0.1-0.6-0.3-1.1-0.3-1.5
				c-0.1-2.1,0.6-4.2,1.4-6.2c0.4-1.1,0.8-2.2,1.1-3.4c0.7-2.9,1.2-5.8,1.4-8.6c0-0.6,0.1-1.1,0.1-1.7c0-1.8,0.1-3.3,1.2-4.6
				c2.1-2.4,5.7-4.1,8.1-5.3c0.3-0.1,0.6-0.3,0.9-0.4c1.2-0.5,2.5-1.1,3.2-3c0.9-2.4,1.9-4.6,3.3-6.4c1.6-2.2,3.4-4.7,3.8-7.8
				c0.2-1.5,0.1-2.9,0.1-4.3c-0.1-1.3-0.1-2.6,0-3.9c0.4-3,1.9-5.7,3.1-7.7c0.3-0.5,0.6-1.1,0.8-1.6c0.3-0.7,0.7-1.3,1.1-1.9
				c2-2.9,2.9-5.7,3.8-8.6c0.3-0.8,0.6-1.6,1-2.4c0.7-1.4,1.4-2.8,1.5-4.4c0.1-1.2-0.4-1.9-0.8-2.6c-0.2-0.3-0.4-0.6-0.5-0.9
				c-0.5-1.7,0.3-3.9,1-5.8c0.1-0.4,0.3-0.7,0.4-1c1.1-3.2,2.1-5.8,3.4-8.2c0.6-1.1,1.3-2.2,2-3.2c0.6-0.8,1.1-1.7,1.7-2.5l0.1-0.2
				c0.5-0.9,1.5-2.5,0.6-3.6c-0.3-0.4-0.9-0.6-1.5-0.6c-0.7,0-1.6,0.3-2.2,0.6c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0.1-0.9,0.2-1.3,0.2
				c-4.8,0.3-9.2-4.6-10.2-9.2c-0.2-0.9-0.3-1.9-0.5-2.8c-0.3-2-0.5-4-1.6-6c-0.2-0.5-5.9,12.3-6.2,11.9c-1.2-2.2-2.4-4.4-2.2-6.8
				c0.4-5.6,3.6-10.1,7.3-15c0.4-0.5,0.7-1,1-1.4c0.5-0.8,1-1.5,1.7-2.2c0.5-0.5,1.1-1,1.7-1.5l0,0c0.4-0.3,0.9-0.7,1.3-1.1
				c2-1.8,1.9-3.9,1.8-6c0-0.8-0.1-1.6,0-2.4c0.1-0.9,1.8-2.3,2.8-3.1c0.4-0.3,0.7-0.6,1-0.8c7.4-6.7,15.9-11.5,24.6-13.9
				c5.1-1.4,10.8-2.3,17.1-2.6c2-0.1,4-0.2,6.1-0.2c4.6,0,9.4,0.2,14.8,0.7c2.1,0.2,4.9,0.5,7.7,1.1c0.1,0,0.3,0.1,0.5,0.1
				c0.3,0.1,0.5,0.2,0.9,0.2c0.1,0,0.3,0,0.4,0c0.8,0,1.5-0.5,2.3-1c0.5-0.3,1.2-0.7,1.5-0.7c0,0,0.2,0.1,0.3,0.7
				c0.4,1.5-0.6,3.5-1.6,5.2c-0.4,0.7-0.8,1.4-1,2.1c-0.9,2.3-1.1,4.5-0.7,7.6c0.3,2.3,0.9,4.6,1.5,6.9c0.1,0.2,0.1,0.4,0.2,0.7
				c0.4,1.4,0.9,3.4,2.1,4.3c0.2,0.1,0.5,0.2,0.7,0.2l0.1,0c1.1-0.1,1.7-1.2,2.6-4.7c0-0.1,0-0.1,0-0.2c0.7-2.5,2-4.7,3.2-7l0,0
				c0.4-0.8,0.9-1.6,1.4-2.5l0.3-0.6c0.5-0.9,1-1.9,1.6-2.6c0.2-0.2,0.5-0.4,0.8-0.5c0.4-0.2,0.7-0.3,1.1-0.6c1.1-0.8,2.1-2.4,2-3.8
				c-0.1-0.6-0.4-1.5-1.5-2.1c-0.8-0.4-1.5-0.5-2.3-0.6c-0.5-0.1-1-0.1-1.4-0.3c-0.9-0.4-1.8-1-2.6-1.8c-1.6-1.8-2.7-4.2-2.9-6.5
				c-0.1-2,0.4-4,1-6.1l0,0c0.2-0.6,0.3-1.1,0.4-1.6c0.4-1.4,0.7-2.8,0.8-4.3c0.1-0.8,0-1.5-0.1-2.2c-0.1-0.9-0.1-1.7,0-2.4
				c0.1-0.4,0.2-0.8,0.2-1.2c0.2-1,0.4-11.8,0.4-12.9c0-0.7-0.1-1.3-0.2-2c-0.1-0.5-0.2-1-0.2-1.4c-0.1-1.6,0.8-2.9,1.7-4.3
				c0.5-0.8,1.1-1.7,1.5-2.7c0.7-1.6,0.3-3.1-0.1-4.6c-0.2-0.9-0.5-1.8-0.4-2.6c0.2-1.9,1.9-5.2,3.9-5.8c0.1,0,0.2,0,0.3,0
				c0.8-0.1,1.9,0.6,3,1.3c0.7,0.4,1.3,0.9,2,1.1c0.4,0.2,0.8,0.2,1.2,0.2c0.1,0,0.1,0,0.2,0c1.8-0.1,3.1-1.6,4.1-2.9
				c0.2-0.3,0.4-0.5,0.6-0.7c0.6-0.7,1.4-1.6,2.4-2.1c0.2-0.1,0.5-0.2,0.9-0.3c0.5-0.1,1-0.2,1.5-0.5c1.8-1.1,3.1-3.1,4.2-4.9
				c0.2-0.3,0.4-0.6,0.6-0.9l0.2-0.4c0.8-1.3,1.6-2.5,2.8-3.2c2-1.1,4.4-2.5,6.1-4.5c1.2-1.4,0.8-3,0.4-4.4c-0.1-0.3-0.2-0.6-0.2-0.8
				c0-0.2-0.1-0.5-0.2-0.7l0-0.1c-0.4-1.9-0.9-4.1-0.4-5.7c0.3-0.8,1.3-1.5,2.3-2.1c0.9-0.6,1.9-1.2,2.6-2.1c2.4-3.3-0.7-5.9-2-7
				c-0.1-0.2-0.6-1.9,1.3-11.4c1.1-5.6,2.5-11.1,2.8-11.8c0.7-1.7,0.9-3.4,1.1-5.2c0.1-0.6,0.1-1.1,0.2-1.7c0.1-0.5,0.3-1,0.5-1.5
				c0.4-0.9,0.8-1.8,0.6-3.1c-0.1-1-0.6-2-1-2.9c-0.9-1.8-1.3-2.8-0.2-4c0.3-0.3,0.8-0.7,1.4-1.1c1.5-1.1,3.5-2.5,3.4-4.6
				c-0.1-1.5-1.6-2.3-3.2-3.1c-0.6-0.3-1.3-0.7-1.6-0.9c-0.5-0.6-0.5-1.3-0.4-1.7c0.2-0.9,0.8-1.7,1.6-2.1c1.4-0.7,2.4-1.7,2.7-2.8
				c0.4-1.3,0.2-2.7-0.6-4.4c-0.2-0.4-0.4-0.9-0.7-1.3c-0.9-1.7-1.7-3.1-1.2-5.1c0.6-2.6,2.3-4.6,4-6.7c0.4-0.4,0.7-0.9,1.1-1.3
				c1.7-2.1,1.8-4.6,1.8-7.1c0.1-2.3,0.1-4.4,1.4-6c0.4-0.5,0.9-0.9,1.4-1.2c0.9-0.6,1.8-1.3,2.4-2.6c0.8-1.7,1.2-3.4,1.7-5.1
				c0.1-0.6,0.3-1.1,0.5-1.7l0.1-0.5c0.4-1.5,0.9-3.3,1.9-4.3c0.5-0.5,1.2-0.9,2-1.4c1.6-0.9,3.5-2,3.7-4.3
				C1660.3,1241.6,1658.1,1240.4,1656.2,1239.3z M1311.8,1173.3c-0.9-0.2-2-0.3-3.2-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.6,0-0.9,0
				c0.1,0.5,0.1,1,0.2,1.4c0.1,0.6,0.1,1.2,0.2,1.8c0.1,0.6,0.2,0.8,0.3,0.8c0.3-0.1,0.9-0.5,1.4-0.8c0.7-0.5,1.4-1,2.2-1.2
				c0.3-0.1,0.7-0.2,1.1-0.2C1311.9,1173.8,1311.8,1173.4,1311.8,1173.3z M1290.5,1196.8c0.1,0.2,0.2,0.4,0.3,0.5
				c0.1,0.2,0.4,0.4,0.7,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.4-0.4C1290.9,1197.1,1290.7,1197,1290.5,1196.8z M1279.2,1175.5
				c-0.1,0-0.3,0-0.5,0l0.1,0c0.3,0.1,0.7,0.3,0.9,0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1
				C1279.7,1175.5,1279.5,1175.5,1279.2,1175.5z M1220.9,1080.7c-0.2-0.4-0.4-0.9-0.5-1.5l0-0.1c-0.1-0.7-0.4-1.8,0.3-2.7
				c-0.2,0-0.4-0.1-0.6-0.1c-1.1-0.1-2-0.3-2.5-0.6c-1.3-0.7-1.7-1.6-1.8-2.3c-0.1-0.4,0-0.8,0-1.2c-0.1,0-0.2,0.1-0.4,0.1
				c-0.5,0-1-0.2-1.3-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.5-0.7,1c-0.2,0.5-0.2,1.1-0.1,1.4
				c0.8,1.6,2.4,3.2,4.3,4.3c0.4,0.2,0.7,0.4,1.2,0.6l0.1,0C1219,1079.5,1220,1080,1220.9,1080.7z M1235.7,1028.8
				c0.4-0.1,1-0.1,1.6-0.1c0.3,0,0.6,0,0.8,0.1c0.9,0.1,1.8,0.1,2.5,0.5c-0.1-0.5-0.1-1-0.1-1.5c0-0.6-0.1-1.1-0.2-1.6
				c-0.2-0.7-1-1.2-1.8-1.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.5,0.4-0.8,0.7c-0.6,0.6-1.3,1.4-2.4,1.4
				c-0.3,0-0.7,0-1-0.1c-2.1-0.7-4-2.1-5.7-3.3c-0.5-0.3-1-0.5-1.6-0.5c-0.7,0-1.4,0.3-2,0.8c-0.4,0.3-0.5,0.5-0.6,0.8
				c0,0.5,0.4,1.1,1.2,2.1c0.3,0.4,0.7,0.8,0.9,1.3c0.4,0.7,0.4,1.5,0.4,2.3c0,0.3,0,0.6,0,0.8c0-0.1,0.1-0.2,0.2-0.3l0.2-0.4
				c0.6-1.4,1.2-2.7,2.7-2.8c0.6,0,1.3,0.2,2.1,0.6c0.3,0.2,0.7,0.3,1,0.5c0.7,0.4,1.4,0.8,2.1,1
				C1234.7,1028.9,1235.1,1028.9,1235.7,1028.8z M1239.1,1005.3c0.2,0,0.5,0.1,0.7,0.1c0.1,0,0.2,0,0.4,0c-0.1,0-0.2,0-0.3,0
				C1239.9,1005.4,1239.6,1005.3,1239.1,1005.3z M1235.7,1101.4c0.5-1.1,0.6-1.7,0.7-2c-1.8,0.4-2.2,0.8-2.3,1c0,0-0.1,0.1,0,0.3
				c0,0.1,0.2,0.4,0.3,0.6c0.3,0.6,0.6,1.1,0.7,1.6c0,0,0,0,0-0.1C1235.3,1102.5,1235.5,1102,1235.7,1101.4z M1159.6,928.8
				c-0.4,0.3-0.9,0.6-1.6,0.8c-0.7,0.3-1,0.6-0.8,1.3c0.2,1.1,1.4,2.2,1.8,2.4c0.3-0.3,0.3-0.7,0.2-1.8
				C1159.2,930.7,1159.2,929.7,1159.6,928.8z M1140.1,975.2c-0.4,0.3-0.9,0.4-1.4,0.4c-1.3,0.1-2.4-0.7-3.5-1.4
				c-0.8-0.5-1.5-1.1-2.1-1.1c-0.1,0-0.2,0-0.4,0c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0.3,0.1l0.1,0c1.6,0.8,2.5,1.3,2.8,2
				c0.1,0.3,0.1,0.7-0.1,1c-0.3,0.6-0.8,0.9-1.6,1.1c0,0,0.1,0,0.1,0c0.4,0,1.7,0.1,2.6,0.7c0-0.2,0.1-0.3,0.2-0.5
				c0.2-0.3,0.5-0.5,0.9-0.5l0.1,0c0.3,0,0.7,0.1,1.3,0.6C1139.3,976.7,1139.7,975.9,1140.1,975.2z M1133.4,977.5c-0.2,0-0.5,0-0.6,0
				c-0.7,0-1.5-0.4-2.4-0.9c-0.5-0.3-1.4-0.7-1.9-0.9c0,0.1,0,0.3,0,0.4c0,0.7-0.1,2.2-1.5,2.2c-0.2,0-0.5,0-0.7-0.1
				c0,0,0.1,0.1,0.1,0.1c0.5,0.7,1.1,1.4,1.2,2.1c0.1,0.5-0.1,0.9-0.2,1c-0.2,0.2-0.4,0.5-1,0.5c-0.4,0-0.8-0.1-1.4-0.2
				c-0.3-0.1-0.7-0.2-1-0.2c0.3,1,0.7,2,1.2,3c0.4,0.8,0.8,1.6,1.1,2.4c0.2,0.6,0.3,1.3,0.3,2c0-0.1,0.1-0.1,0.1-0.2
				c0.6-0.7,0.8-1,0.9-1.7c0.5-2.9,1.3-4.5,2.4-4.8c0.1,0,0.3-0.1,0.5-0.1c0.6,0,1.2,0.2,1.7,0.5c-0.2-1-0.5-2.1-0.7-2.5
				c-0.3-0.8-0.1-1.3,0.2-1.7C1132.1,977.9,1132.8,977.7,1133.4,977.5z M1143.2,970.5c0,0.1,0,0.3-0.1,0.4c0.6-0.5,1.4-0.6,2-0.8
				c0.4-0.1,0.7-0.2,1-0.3c0.4-0.2,0.5-0.6,0.5-1.7c0-0.5,0.1-1,0.2-1.5c0.1-0.3,0.4-0.6,0.9-1.1c-0.4,0.2-0.8,0.5-1.3,0.9
				c-0.5,0.4-0.9,0.6-1.4,0.6c-0.9,0-1.4-0.6-1.8-1c0,0,0,0,0,0c0,0.6-0.1,1.5-0.9,1.9c-0.1,0.1-0.3,0.1-0.4,0.2
				c0.2,0.2,0.4,0.4,0.6,0.7C1143.1,969.3,1143.3,969.8,1143.2,970.5z M1109.8,972.1c0.5-0.5,1.1-1,1.8-1.6c0.3-0.2,0.3-2.4,0.3-3.1
				c0-0.8,0-1.7,0.1-2.3c-0.2,0.4-0.3,0.8-0.5,1.1c-0.3,0.6-0.7,0.9-1.1,1.2c-0.2,0.2-0.4,0.4-0.5,0.5c-0.4,0.6-0.3,1.4-0.2,2.3
				C1109.8,970.8,1109.8,971.5,1109.8,972.1z M1104.2,962.6c0.4-0.3,0.7-0.5,1-0.8C1104.8,962,1104.5,962.3,1104.2,962.6z
				M1271.3,1234.7c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,1-0.4,1.4-0.6c0.2-0.1,1.1-1.4,1.7-2.6c-0.8,0.3-1.6,0.8-2.2,1.6l-0.1,0.2
				C1272.2,1233.7,1271.8,1234.3,1271.3,1234.7z M1288.4,1312.4c0,0-0.3-0.2-0.9-0.2c-1.2,0.1-2.5,0.8-2.8,1.4c0,0.1,0,0.8-0.1,1
				c-0.1,1.4-0.1,3.2-0.7,4.3c0.3,0,0.6,0.1,0.9,0.1c0.5,0.1,1,0.2,1.4,0.2c0.2,0,0.4-0.1,0.4-0.1c0.8-0.7,1-2.2,1.1-3.6
				c0.1-0.9,0.2-1.8,0.5-2.5C1288.4,1312.6,1288.4,1312.4,1288.4,1312.4z M1192.8,942.3c-0.3,0.2-0.5,0.4-0.7,0.5
				c-0.3,0.2-0.5,0.4-0.7,0.5c-0.2,0.1-0.4,0.2-0.7,0.2l-0.1,0c-1,0-1.9-1-2.5-1.8c-0.1-0.1-0.3-0.4-0.4-0.5c-0.2-0.2-0.3-0.2-0.4-0.3
				c0,0,0,0.1,0,0.1c-0.1,0.7,0.3,1,1.4,1.6c1.3,0.7,2.7,1.6,3.2,2.7C1192,944.7,1192.3,943.7,1192.8,942.3z M1136.4,1013.8
				L1136.4,1013.8L1136.4,1013.8L1136.4,1013.8z M1276.2,1709.2c0.5,2.1,0.4,4.4,0.3,6.4l0,0.1c0,0.2,0,0.5,0,0.8c0,1.3-0.1,3-0.8,4
				c-0.8,1.1-1.9,2-2.9,2.9c-0.5,0.4-0.9,0.7-1.3,1.1c-0.2,0.2-0.3,0.3-0.5,0.5l-0.1,0.1c-1.3,1.2-2.6,2.4-3.5,3.8
				c-0.9,1.6-2,2.7-3.2,3.9c-0.5,0.6-1.1,1.1-1.6,1.7c-0.5,0.5-0.7,1.4-0.9,2.4c-0.1,0.7-0.3,1.4-0.6,2c-0.8,1.9-2.6,4-5.2,4.1
				c-0.5,0-1,0-1.5-0.2c-1.9-0.5-1.5-2.6-1.2-3.7c0.1-0.4,0.2-1.2,0.2-1.4c-0.4-0.9-1-1.4-1.7-1.4l-0.1,0c-0.7,0-1.3,0.5-1.7,1.2
				c-0.9,1.6-2,3.3-3,4.8c-2.4,3.7-5,7.5-5.3,11.7c-0.2,2.3-0.2,4.9,1.3,7.3c1.1,1.7,2.4,2.5,4,3.5c0.8,0.5,1.6,1,2.4,1.6
				c5.2,4,6.5,12.7,7,16.9c0,0.2,0.1,0.5,0.1,0.9l0,0.1c0.2,1.5,0.6,4.9,1.8,5.5c1.4,0.6,2.9,0.9,4.4,1.3c0.9,0.2,1.8,0.4,2.7,0.7
				c0.7,0.2,1.6,0.6,1.9,1.5c0.3,1.1-0.4,2-1.2,2.9l-0.3,0.3c-2,2.5-3.7,5.2-5.4,7.8l-0.6,1c-0.9,1.4-1.7,2.8-2.6,4.3l0,0.1
				c-2.3,3.9-4.7,8-7.7,11.2c-1,1.1-1.9,2.1-2.9,3.2c-2.3,2.5-4.6,5-6.9,7.6c-2,2.3-5.4,6.7-6,11c-0.1,1,0.1,2.1,0.4,3.2
				c0.3,1.4,0.7,2.9,0.3,4.5c-0.5,1.8-2.2,3.3-3.6,4.6c-0.3,0.3-0.6,0.5-0.8,0.7c-2.3,2.2-4.2,4.5-6.3,6.9l-0.1,0.1
				c-0.1,0.2-0.3,0.3-0.4,0.5c-1,1.2-2,2.4-3.4,3.2c-0.9,0.5-2,1.1-2.6,1.9c-0.2,0.2-0.2,0.6-0.3,0.9c-0.1,0.6-0.2,1.2-0.8,1.9
				c-0.6,0.7-1.4,1.3-2.1,1.8c-0.9,0.6-1.6,1.3-2.2,2.1c-1.2,1.8-0.8,5.6-0.3,7.6c0.1,0.3,0.5,0.8,0.9,1.2c0.6,0.7,1.2,1.4,0.9,2.2
				c-0.1,0.3-0.4,0.7-1.3,0.8c-0.3,0-0.6,0-1-0.1c-1.1-0.2-1.9-0.5-2.8-0.9c-1-0.4-1.8-0.7-2.6-0.7c-0.6,0-1.2,0.3-1.8,0.7
				c-0.9,0.7-1.6,1.5-2.4,2.3c-0.6,0.7-1.3,1.4-2,2.1c-1.9,1.7-4.1,3.7-5.3,6c-0.6,1.3-0.7,2.8-0.8,4.5c-0.1,1.5-0.2,3-0.7,4.5
				c-0.5,1.4-1.1,2.7-1.6,4l0,0.1c-0.3,0.7-0.6,1.4-0.9,2c-1.8,4.3-3.7,8.6-5.5,12.8l0,0.1c-1.6,3.5-3.2,7.2-4.7,10.8
				c-0.6,1.5-1.4,3.3-2,5c-0.3,1-0.4,1.9-0.5,3c0,0.7-0.1,1.5-0.2,2.3c-0.3,1.6-1.2,2.9-2.1,4.1c-0.6,0.9-1.2,1.7-1.5,2.5
				c-1.1,2.8-3.3,5.1-6.2,6.4c-1.1,0.5-2.2,0.7-3.3,1c-1.1,0.2-2.1,0.5-3,0.9c-2.6,1.3-4.3,4-5.9,6.8c-0.1,0.2-0.2,0.4-0.3,0.6
				c-0.6,1.2-1.3,2.6-3,3c-0.7,0.2-1.3,0.2-2,0.3c-1.1,0.1-2.1,0.1-2.8,0.8c-1.8,1.7-3.7,2.7-6.1,3.8c-2.9,1.3-5.5,3.2-7.7,4.8
				c-0.1,0.1-0.3,0.2-0.4,0.3l-0.1,0.1c-1.1,0.9-2.6,2.1-4.3,2.1l-0.1,0c-0.9,0-1.4,1-2,2l0,0.1c-0.1,0.2-0.2,0.4-0.4,0.6
				c-5.9,9.9-8.6,21.9-7.1,32.2c0.8,5.7,1.9,12.2,4.4,17.2c0.7,1.5,2.1,2.6,3.5,3.8l0.1,0.1c0.2,0.2,0.5,0.4,0.7,0.6
				c0.3,0.3,1.4,1.2,1.1,2.3c0,0.2-0.1,0.3-0.2,0.4c0.3,0,0.6,0,0.9,0.1c1,0.3,1.3,1.1,1.6,1.6c0.2,0.3,0.2,0.5,0.3,0.5
				c0.1,0,0.2,0.1,0.3,0.1l0.1,0c0.6,0,1.4-0.4,2.2-0.8c0.7-0.3,1.4-0.7,2-0.8c0.2,0,0.4-0.1,0.6-0.1c1.8-0.1,3.8,0.8,5.6,1.6
				c0.6,0.3,1.1,0.5,1.5,0.7c0.1,0,0.2,0.1,0.4,0.1c1.7,0.7,4.6,1.7,4.8,4c0.4-0.2,0.9-0.5,1.2-0.7c0.4-0.3,0.8-0.5,1.1-0.6
				c0.2-0.1,0.5-0.1,0.7-0.1l0.1,0c2,0,2.5,2.5,2.7,4.2c0.1,0.3,0.1,0.7,0.2,0.9c0.2,0.6,0.3,0.6,1.3,0.7c0.9,0,2.3,0.1,3,1.6
				c0.4,0.9,0.2,1.7,0,2.3c0,0.2-0.1,0.3-0.1,0.5c0.2,0.1,0.4,0.2,0.5,0.4c1.1,1.5,0.9,3.9,0.8,5.6l0,0.3c0,0.5,0.1,1.2,0.2,1.9
				c0.2,1.3,0.4,2.6,0,3.7c-0.9,2.5-2.6,5.1-3.8,6.8c-1.5,2.2-4.5,4.6-7.3,4.7c-1.4,0.1-3.5-0.4-4.7-3.4c-0.8-1.9-0.9-4-1.1-5.9l0-0.2
				c0-0.3,0-0.6,0-0.9c0-1-0.1-2.5-0.6-3.1c-0.2-0.2-0.3-0.2-0.3-0.3c-0.3,0.2-0.9,2-1,3.7c0,0.8-0.2,1.5-0.3,2.3
				c-0.4,1.8-0.6,3.1,0.7,4.2c0.1,0.1,0.2,0.2,0.3,0.3l0,0c1.2,1,2.7,2.2,2.3,3.5c-0.3,1-1.5,1.2-2.4,1.3c-0.5,0-0.9,0.1-1.4,0.1
				c-0.4,0-0.8,0-1.2,0c-1.7,0-4.3-0.2-6.6-1.7c-0.8-0.5-1.6-0.9-2.5-1.3c-1-0.5-2.1-1-3.2-1.8l-0.3-0.2c-1.8-1.3-3.4-2.5-5.5-2.5l-2,0
				c-2,0-4.1,0-6.2,0.1c-2.2,0.1-4.1,0.3-5.9,0.5c-2,0.3-3.9,0.8-5.8,1.4c-1.6,0.5-3.3,1-5,1.3c-0.9,0.2-1.8,0.3-2.7,0.5l-0.3,0.1
				c-4,0.7-8.1,1.4-11.9,2.7c-2.2,0.7-4.3,1.9-6.3,3.1c-0.9,0.5-1.8,1-2.7,1.5c-2.8,1.5-5.6,2.9-8.1,4.2l-0.2,0.1
				c-1.7,0.9-3.5,1.8-5.2,2.7c-4.7,2.4-9.7,5.1-14.6,8c-2.6,1.5-5.1,3.2-7.6,4.9l0,0c-1.8,1.2-3.7,2.5-5.6,3.7c-2.2,1.4-4.6,2.7-6.8,4
				c-5.3,3.1-12,9.6-16.7,13.6c-1.7,1.5-3.6,3-5.3,4.4c-4.1,3.3-8.3,6.7-11.7,10.6c-2.3,2.7-5.3,6.3-7.8,10.2c-0.1,0.2-0.2,0.3-0.3,0.5
				c-0.7,1-1.4,2.1-1.6,3.1c0,0.2,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.6c0.6,1.8,0.8,3.4,0.8,4.9c0,2.2-0.8,3.8-1.7,5.6
				c-0.2,0.3-0.3,0.6-0.5,1c-1,2-1.9,4.1-2.9,6.8c-0.1,0.4-0.3,0.8-0.4,1.1l0,0.1c-1.2,3.1-2.6,6.7-2.2,9.8c0.4,3.1,0.6,6.3,0.9,9.4
				c0.4,4.4,0.7,8.9,1.3,13.2c0.1,0.5,0.2,1,0.2,1.5l0,0.1c0.3,1.9,0.6,4,0.8,6c0.2,2.6-0.2,4.8-0.6,7.2c-0.1,0.7-0.2,1.4-0.4,2.1
				c0,0.3-0.1,0.6-0.1,1c-0.4,2.8-0.8,5.8-2.5,8.3c-1,1.6-2.2,3-3.4,4.4l-0.4,0.5c-0.9,1-1.8,2.1-2.6,3l0,0c-2,2.3-4.1,4.7-5.8,7.2
				c-0.6,0.9-0.6,1.5-0.6,2.7c0.1,3.3-2.3,6-4.4,7.4c-0.2,0.2-0.5,0.3-0.7,0.4c-1.1,0.7-1.7,1.1-2,2.2c-0.5,1.7-1,3.4-1.6,5l-0.9,2.5
				c-1,3-2.1,6.1-3,9.2c0,0.1-0.1,0.3-0.1,0.4l0,0.1c-0.3,1.1-1.1,3.5-0.4,4.3c0.4,0.4,0.9,0.7,1.4,0.9c1.2,0.7,3,1.7,2.3,4.4
				c-0.4,1.6-1.1,3.1-1.7,4.6c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.2-0.2,0.4-0.4,0.7c-1.2,2.5-1.1,3-1.1,3c0.8,1.3-0.2,3.5-1.3,5.6
				c-0.1,0.3-0.3,0.5-0.3,0.6c-0.4,0.8-0.8,1.5-1.1,2.2c-0.9,1.8-1.9,3.6-2.3,5.5c-0.1,0.6-0.2,1.3-0.3,2c-0.2,1.5-0.4,3.1-1.3,4.5
				c-0.6,1-1.3,2.1-2.6,2.6c-0.3,0.1-0.7,0.2-1.1,0.2c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.5,0c-0.4,0-0.4,0.1-0.5,0.2
				c-0.7,0.7-0.7,2.2-0.8,3.4c0,0.3,0,0.6,0,0.8c-0.1,1.4-1,2.2-1.7,2.7c-0.3,0.2-0.5,0.4-0.7,0.6c-1.8,1.8-2.7,4-2.2,5.4
				c0.3,0.9,1.3,1.5,2.9,1.7c1.9,0.3,4,0.9,6.6,2.1c0.9,0.4,1.4,1,1.4,1.7c0,0.2,0,0.5-0.1,0.7c0.5-0.1,1,0,1.4,0.1
				c0.8,0.2,1.4,0.7,1.7,1.4c0.6,1.3,0,2.9-0.4,4l-0.1,0.3c-0.5,1.2-0.1,2,0.4,3.1c0.3,0.6,0.6,1.3,0.8,2.2c0.4,1.8,0.2,3.3-0.7,4.4
				c-1.7,2.3-5.4,2.6-8.4,2.8c-0.5,0-0.9,0.1-1.3,0.1c-6.8,0.6-13.6,2.7-20.1,4.7l-0.1,0c-5.3,1.6-9.5,4.7-12.8,9.2
				c-2.1,3-2.9,6.4-3.7,10c-0.1,0.5-0.2,0.9-0.3,1.4c-1,4.6-2,8.9-5.8,13c-2.2,2.4-4.7,4.7-7.1,6.9c-1.7,1.5-3.4,3.1-5,4.7
				c-1.8,1.8-3.9,3-5.9,4.1c-2,1.1-4.1,2.3-5.7,4c-2.9,3-6.8,7.5-6.5,11.3l0,0.4c0.2,3.2,0.4,7.2-1.1,10.1c-1.1,2.1-2.7,3.8-4.3,5.4
				c-0.3,0.3-0.5,0.5-0.8,0.8c-0.5,0.5-1,1-1.4,1.5c-1.5,1.6-3.1,3.3-4.9,4.8c-2.9,2.4-6.3,3.1-9.6,3.7c-1.9,0.3-3.8,0.7-5.6,1.4
				c-2.2,0.8-4.2,2.1-6.2,3.3l-0.5,0.3c-1,0.6-2.1,1.1-3.1,1.6c-1,0.4-1.9,0.8-2.8,1.3c-0.4,0.2-0.7,0.5-1.1,0.8
				c-0.4,0.3-0.9,0.7-1.4,0.9c-1.8,1-3.9,1.2-5.8,1.4l-0.1,0c-1,0.1-2,0.2-2.9,0.2c-2.2,0.1-4.4,0.3-6.5,0.7c-2,0.4-4.1,1-6,1.6
				l-0.2,0.1c-0.5,0.2-1,0.3-1.6,0.5c-3.9,1.1-7.5,1.8-10.7,1.9c-2.8,0.1-5.4-0.1-7.6-0.8c-1.8-0.5-2.6-1.7-3.3-2.8
				c-0.5-0.8-1-1.5-1.9-1.9c-1.4-0.6-3.1-0.8-4.7-1c-1.1-0.1-2.2-0.3-3.3-0.5c-3-0.7-5.9-1.9-8.4-2.9c-0.2-0.1-0.5-0.2-0.7-0.3
				c-0.9-0.4-2-0.9-2.9-0.9l-0.1,0c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.6,0.8-0.8,1.8c-0.1,0.5-0.2,1-0.4,1.5c-1.4,3.3-4.7,3.8-7.6,3.9
				c-0.3,0-0.7,0-1,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.9,0-1.9,0.1-2.7,0.3c-1.2,0.3-2.5,1.1-3.8,1.9c-1.9,1.2-3.9,2.4-6.1,2.5
				c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2-0.1-1.7-0.2c-2.9-0.8-4.8-3.3-6.5-5.4l0,0c-0.2-0.3-0.4-0.5-0.6-0.8c-0.4-0.5-0.9-1.1-1.4-1.6
				c-1-1.1-2-2.3-2.8-3.6c-0.3-0.6-0.3-1.1-0.1-1.4c0.3-0.7,1.1-0.9,1.6-1l0.1,0c1.8-0.4,3.6-1.1,5.3-2.2c3.2-1.9,6.6-4.9,6.4-6.8
				c-0.1-0.9-1.1-1.8-2.9-2.4l-0.2-0.1c-0.5-0.2-1.2-0.5-1.7-0.5l-0.1,0c-0.2,0-0.2,0-0.2,0.3c0,0.3,0,0.5-0.1,0.8l0,0.1
				c-0.1,1.7-0.2,3.3-0.9,5.1c-0.5,1.4-1.4,1.8-2,1.8l-0.1,0c-1.5,0-3-1.8-4.3-3.2c-0.3-0.4-0.6-0.8-0.9-1c-0.9-0.8-1.8-1.7-2.6-2.4
				l0,0c-1.8-1.6-3.6-3.3-5.2-5.1c-0.4-0.5-0.9-1-1.3-1.5c-0.7-0.9-1.5-1.9-2.3-2.6c-0.4-0.3-0.9-0.4-1.6-0.4c-0.1,0-0.2,0-0.4,0
				c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0.1-1.1,0.1l-0.1,0c-0.5,0-1,0-1.5-0.1c-0.6,0-1.1-0.1-1.5-0.1c-0.8,0-1.3,0.2-1.7,1
				c0.6,0.7,1.3,1.7,1.2,2.9c-0.1,0.7-0.5,1.8-2.7,1.9c-1.5,0.1-3.4-0.4-4.5-0.6l-0.1,0c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-1-0.2-1.6-0.4
				l-0.1,0c-1.4-0.3-2.9-0.7-4.1-0.7c-0.3,0-0.6,0-0.8,0.1c0,0,0,0-0.1,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c-0.1,1-0.8,1.3-1.4,1.3
				l-0.1,0c-0.6,0-1.1-0.3-1.6-0.5l0,0c-0.1-0.1-0.2-0.1-0.2-0.1c-0.4-0.2-0.8-0.3-1.1-0.3c-0.7,0-1.2,0.6-1.6,2
				c-0.5,1.8-1.8,2.1-2.5,2.1c-1.4,0-2.7-0.9-3.4-1.8c-0.6-0.7-1-1.5-1.5-2.2c-0.6-1-1.2-1.9-2-2.7c-0.4-0.4-1.2-0.4-2.1-0.4
				c-1.2-0.1-2.5-0.1-3.2-1.2c-1-1.6,1.1-3.5,3.7-5.7c0.3-0.3,0.7-0.6,0.8-0.7c0.4-0.4,0.5-0.7,0.5-0.7c-0.1-0.4-1.8-1-2.4-1.1
				c-2.8-1-5.2-3-7.4-5c-1.5-1.3-2.9-2.5-4.4-3.4c-2.9-1.8-6.7-2.6-10.9-2.5c-1.7,0.1-3.5,0.3-5.2,0.7c-3.9,0.9-4.4,3.7-5.1,7.6
				l-0.1,0.6c-0.6,3-2.7,4.7-6.4,4.9c-1.5,0.1-2.8,0.6-4.2,1c-1.1,0.4-2.2,0.8-3.4,1c-1.2,0.2-2.6,0.4-3.9,0.4c-0.4,0-0.9,0-1.4,0
				c-4.6,0-9.1-0.9-13.3-1.9c-0.7-0.1-1.4-0.2-2.2-0.2c-0.7,0-1.4,0.1-2,0.2c-0.7,0.1-1.4,0.2-2.2,0.2c-0.8,0-1.6,0-2.3-0.1
				c-0.9-0.2-1.6-0.4-2.4-0.7c-1-0.4-2-0.7-3-0.7c-0.3,0-0.5,0-0.7,0.1c-0.6,0.1-1.2,0.3-1.7,0.4l-0.2,0c-3.3,0.8-6.4,1.6-9.8,1.8
				c-1.1,0-2.2,0-3.3-0.1c-2-0.2-9.8-2-11.4-5.4c-0.5-1.1-0.4-2.3,0.3-3.5c0.9-1.4,2.1-2.6,3.2-3.8c0.9-0.9,1.7-1.8,2.5-2.8
				c0.8-1.1,0.9-1.5,0.9-1.6c-0.1-0.1-0.3-0.2-1.2-0.3c-0.6,0-1.5,0.2-2.4,0.4l-0.1,0c-0.9,0.3-1.9,0.5-2.9,0.6c-1,0-1.9-0.2-2.5-0.8
				c-1.7-1.5-1.5-4.9-1.3-6.9l0-0.2c0.1-1.7,0.6-4.2,2.2-6.2c1.7-2.2,4-2.5,6.2-2.8c0.7-0.1,1.3-0.2,2-0.3c2-0.5,4.3-1.3,5.2-1.9
				c-0.2,0-0.4,0-0.6,0c-1,0-2.2,0.3-3,0.6l-0.1,0c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-1,0.2c-1.2,0.3-2.8,0.7-4.1,0.7
				c-1.3,0-2.1-0.2-2.7-0.8c-1.5,1.7-3.2,3-4.9,4c-0.6,0.4-1.3,1.1-2.1,1.9c-1.8,1.8-3.8,3.8-6.1,3.9c-0.8,0-1.7-0.2-2.4-0.7
				c-2.5-1.8-3.1-4.6-3.4-6.8c-0.3-2.2-1.1-3.7-2.2-5.5c-0.3-0.6-0.7-1.2-1-1.8c-3.6-6.7,3.5-12.1,5.8-13.6c1.5-1,3.2-2.1,5.1-2.4
				c0.2,0,0.5-0.1,0.7-0.1c0.8,0,1.5,0.1,2.2,0.2c0.5,0.1,1,0.1,1.4,0.1c2.3,0.1,4.4-0.3,6.3-1c1.6-0.7,2.9-1.8,4.1-2.9
				c0.8-0.7,1.6-1.5,2.6-2.1c1.1-0.8,2.2-1.1,3.3-1.2c1.1,0,2.2,0.2,3.2,0.5c1.1,0.3,2.1,0.6,3.2,0.5c0.3-0.1,2.6-1.8,3.5-2.6
				c0.9-0.7,1.6-1.2,2.2-1.7c-1.4-0.2-2.8-0.6-4.1-0.9c-1.1-0.3-2.1-0.4-2.9-0.4c-1.3,0.1-2.6,0.5-4,1.3c-0.8,0.5-1.5,1.1-2.3,1.7
				c-1.4,1.1-2.8,2.2-4.5,2.8c-1.1,0.3-2.1,0.4-3.1,0.5c-0.6,0-1,0.1-1.5,0.1c-0.3,0-0.7,0.1-1,0.2l-0.1-0.2l0,0l0,0.2
				c-0.8,0.1-1.7,0.3-2.6,0.3c-0.5,0-0.9,0-1.4,0c-2.9-0.3-4.2-2.7-5.3-4.8l-0.2-0.3c-1-1.9-1.4-2.1-1.6-2.1c0,0-0.1,0-0.4,0.2
				c-0.9,0.9-1.7,2-2.4,3.2c-0.9,1.4-1.9,2.8-3.2,4c-0.5,0.4-1.4,1.1-2.2,1.2l-0.1,0c-0.5,0-0.9-0.2-1.2-0.6c-0.4-0.6-0.4-1.3,0.1-2.5
				c0.5-1.2,1.1-2.3,1.6-3.3c0.5-0.9,1-1.8,1.4-2.7c0.4-0.8,0.5-1.6,0.7-2.5c0.3-1.3,0.6-2.6,1.4-3.9c0.6-1,1.5-2.1,2.6-2.7
				c0.4-0.2,0.8-0.3,1.1-0.3c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.7-0.6,1.4-1.2,2.3-1.5c0.5-0.2,1.1-0.3,1.7-0.3
				c0.4,0,0.8,0,1.2,0c0.6,0,1.1,0,1.4,0c0.5,0,1-0.1,1.5-0.2c0.9-0.2,1.8-0.3,2.7-0.4c1.2,0,2.1,0.1,2.9,0.5c0.8,0.4,1.3,1.2,1.7,2.2
				c-0.4-1.6-0.5-3.2,0.4-4.6c-0.4,0.1-0.7,0.1-1.1,0.1c-1.7,0.1-3.3-0.4-4.6-1.5c-1.6-1.4-2.7-4.1-2.8-6.7c-0.1-2,0.6-3.8,1.7-4.8
				c0.8-0.7,1.7-0.8,2.4-0.9c0.3,0,0.6,0,0.8-0.1c0.1,0,0.2-0.2,0.4-1.5c0-0.1,0-0.2,0-0.3c0.3-2.1,0.5-4.2,0.7-6.3
				c0.4-3.8,0.7-7.8,1.9-11.5c1.3-4.1,4.7-7,8.5-7.2c0.4,0,0.8,0,1.2,0c3.2,0.3,6.4,0.9,9.6,1.4c0.9,0.2,1.7,0.4,2.5,0.6
				c-0.1-0.5-0.1-1,0.1-1.5c0.2-0.6,0.6-1,1.1-1.4c-0.2,0.1-0.3,0.1-0.5,0.2c-1.3,0.5-2.8,0.8-4.4,0.8c-0.7,0-1.5,0-2.2-0.1
				c-2.4-0.2-4.2-1.9-5.9-3.3c-0.4-0.3-0.8-0.7-1.2-1c-2.7-2.2-1.2-5.4-0.2-7.5c1.5-3.1,3.4-6.3,6-9.9c3.2-4.5,6.3-9.2,9.2-13.7
				c0.8-1.2,1.5-2.4,2.3-3.6c0.9-1.4,1.8-2.8,2.7-4.2l0.1-0.1c2.2-3.3,4.4-6.8,6.7-10.2c0.7-1,1.4-2.1,2.2-3c1.3-1.7,2.5-3.5,3.7-5.4
				c0.1-0.1,0.1-0.2,0.2-0.4l0-0.1c0.8-1.6,1.8-3.1,3.1-3.2l0.1,0c0.2,0,0.5,0.1,0.7,0.2c0.3,0.1,0.7,0.5,1.3,1.2c0.3-1.4,1-2.8,1.9-4
				c1.9-2.3,4.8-3.5,6.9-4.2c2.2-0.8,4.4-1.3,6.3-1.5c0.2,0,0.5,0,0.7,0c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1-0.9-2.1-1-3.3
				c-0.1-0.9,0-1.6,0.4-2.1c0.6-0.8,1.6-0.9,2.5-0.9l0,0c0.2,0,0.5,0,0.7-0.1c0.3,0,0.4-0.3,0.5-1.6c0-0.3,0-0.5,0-0.7
				c0.2-2.2,0.7-6.7,4.4-7.3c0.6-0.1,1.4,0.2,2.1,0.5c0.2,0.1,0.5,0.2,0.7,0.3c0.2-0.6,0.2-1.3,0.2-2c0-0.8,0.1-1.6,0.3-2.3
				c0.6-2.1,1.8-2.6,2.9-3c0.4-0.1,0.7-0.3,1.2-0.5c1.4-0.8,2.5-2.1,3.6-3.4c0.4-0.5,0.9-1,1.3-1.5c0.7-0.8,1.7-1.6,2.9-1.7
				c0.9,0,1.8,0.3,2.7,1.2c0.1,0.1,0.2,0.2,0.3,0.3l0,0c1.9,1.8,2.9,2.6,3.6,3c-0.4-0.7-1-1.6-1.2-2c-0.4-0.6-0.7-1-0.8-1.3
				c-0.9-2-0.8-4.3-0.8-6.3l0-0.3c0.1-5.4,1.9-12.9,6.6-17.8c1.3-1.4,2.9-2.4,4.5-3.1c0.3-0.1,0.6-0.2,0.9-0.2c0,0,0.1,0,0.1,0
				c1.8,0,3.5,1.5,4.5,2.5l0.1,0.1c2.1,1.9,3.6,3.9,4.5,6c-0.2-0.4-0.3-0.8-0.5-1.2c-1-2.4-1.9-4.2-2.9-5.9c-0.4-0.7-0.9-1.2-1.4-1.7
				c-0.4-0.4-0.9-0.9-1.3-1.4c-1.2-1.6-1.9-4.3-1.2-6.2c0.6-1.4,2.6-1.6,4.3-1.8c0.5,0,0.9-0.1,1.1-0.2l0.3-0.1
				c1.1-0.3,3.2-0.8,3.6-1.7c0.1-0.2-0.2-0.8-2.8-2.4c-0.5-0.3-0.8-0.5-1.1-0.7c-0.4-0.3-0.9-0.8-1-1.5c0-0.8,0.5-1.3,0.9-1.7
				c0.1-0.1,0.3-0.3,0.4-0.4l0.1-0.1c0.6-0.7,1.2-1.4,1.9-2.1c0.7-0.6,1.4-1.3,2-1.8c1.6-1.4,3.1-2.8,4.4-4.5c0.3-0.4,0.6-1,0.9-1.6
				c0.6-1.2,1.3-2.4,2.4-3.2c0.8-0.6,1.7-0.7,2.5-0.8c1.1-0.1,1.6-0.2,1.8-0.8c0.4-0.7,0.5-1.5,0.7-2.3c0.3-1.4,0.5-2.9,1.8-4.1
				c0.3-0.2,0.5-0.5,0.7-0.7c1-0.9,1.5-1.3,1.6-2.6c0.2-2.3,1.4-4.5,2.5-6.3c0.2-0.3,0.4-0.6,0.5-0.9c0.9-1.7,2.2-2.6,4.3-3.1
				c0.9-0.2,1.8-0.4,2.7-0.7l0.1,0c1.8-0.4,3.7-0.9,5.5-1.4c2.1-0.7,4.9-1.6,7.5-3c0.3-0.2,0.7-0.5,1.2-0.9c1-0.8,2.2-1.8,3.4-1.8
				c0.4,0,0.7,0.1,1.1,0.2c0.5,0.2,1,0.5,1.4,0.8c1.1,0.6,1.9,1.1,3.1,0.9c2-0.3,3.9-0.9,5.9-1.6c4.5-1.6,9.6-3.5,12.9-6.8
				c3.3-3.2,6.8-5.7,10.5-8.3c0.8-0.5,1.5-1.1,2.3-1.6c3.8-2.7,6.9-6,10.1-9.5l0,0c0.8-0.9,1.7-1.9,2.6-2.8c3.4-3.6,8-8.2,13.1-12.4
				c1-0.8,2-1.5,2.9-2.3l0.1-0.1c1.2-0.9,2.4-1.9,3.6-2.9c2.6-2.2,5.6-4.7,9.3-6c1.7-0.6,3.2-1.5,4.8-2.5c1-0.6,2-1.2,3-1.7
				c0.7-0.4,1.8-0.9,3-0.9c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0.1,0.9,0.2c0.4,0.1,0.7,0.1,0.9,0.1c0.2,0,0.2,0,0.2,0
				c0.7-0.5,1.2-1.5,1.8-2.5c0.6-1,1.1-2,1.9-2.8c1.1-1,2.1-1.2,2.9-1.3c0.1,0,0.3,0,0.4,0l0.5,0c0.1,0,0.2,0,0.3,0
				c0.4,0,0.7-0.1,1-0.2c1-0.4,1.7-2.6,2.2-4.1c0.2-0.7,0.5-1.4,0.7-1.9c1.6-3.3,3.5-6.4,5.7-9.3c1.6-2.1,3.7-4.5,6.3-6.1
				c1.8-1.1,3.8-1.9,5.7-2.7c0.8-0.3,1.7-0.7,2.6-1.1c5.6-2.5,8.9-7.1,12.5-11.9l0.1-0.1c0.3-0.4,0.6-0.9,1-1.3c1.4-1.9,3-3.6,4.6-5.3
				c2.3-2.5,4.7-5.1,6.3-8.1c0.5-0.9,0.9-1.9,1.3-2.8c1.7-3.8,3.2-7.4,8.7-8c3-0.3,4.7-2.8,6.4-5.4c0.6-1,1.3-2,2.1-2.9
				c1.9-2.2,4.3-3.1,6.7-3.9c0.9-0.3,1.8-0.6,2.7-1c11.2-4.9,19.9-10.6,26-16.9c3.1-3.2,5.6-7.1,8.1-10.9l0.1-0.1
				c0.6-0.9,1.2-1.9,1.8-2.8c0.7-1.1,1.5-2.2,2.2-3.3l0.1-0.1c2.6-3.9,6.5-11.2,9.3-15c1-1.3,2-2.6,3-3.8c1.8-2.2,3.7-4.5,5.2-7
				c2.8-4.8,5.1-9.6,6.9-14.3c0.6-1.6,1-3.3,1.4-4.9c0.4-1.9,0.9-3.8,1.7-5.7c1-2.6,2.2-5.3,3.4-7.8c1.5-3.3,3-6.7,4.3-10.2
				c1.6-4.6,1.4-9.3,1.1-14.2c-0.1-1.3-0.1-2.6-0.2-3.9c-0.1-3,0-6.8,1.7-10.1c0.7-1.4,1.5-2.6,2.4-3.8c0.7-1,1.4-2,2-3.1
				c0.9-1.6,1.1-3.5,1.3-5.4c0.1-0.9,0.2-1.9,0.4-2.9c0.4-2.2,1.2-4.2,1.9-6.2c0.3-0.7,0.5-1.4,0.8-2.1c1.1-3.2,0.7-6.2,0.2-9.5
				c-0.3-1.8-0.5-3.7-0.5-5.6c0-2.9,2.1-3.6,3.9-3.9c0.5-0.1,1-0.2,1.5-0.2l0.1,0c2.1-0.3,4.2-0.6,5.8-1.8c1.3-1,2.3-1.6,3.5-1.7
				c0.8,0,1.6,0.2,2.6,0.7c0.6,0.3,1.1,0.4,1.7,0.4c1.3-0.1,2.7-0.9,3.8-1.8c2.2-1.7,4.2-3.6,6.2-5.4c2.1-1.9,4.3-3.9,6.6-5.7
				c3.9-3,7.1-6.8,9.7-11.5c1.5-2.7,2.8-5.5,4-8.3c1.4-3.1,2.8-6.3,4.6-9.3c0.6-1,1.2-2,1.8-3c1.5-2.4,3-4.9,4-7.5
				c1.5-3.6,3.1-8.4,2.8-13.4c-0.2-3-0.2-6.1-0.3-9.1c0-1,0-2.1-0.1-3.1l0-2c-0.1-3.7-0.2-7.6-0.4-11.3c-0.2-2.7-0.7-5.4-1.1-8
				c-0.3-1.7-0.6-3.4-0.8-5.1c-0.3-2.4-0.7-5-0.8-7.7c-0.1-3.1,1.7-5.1,3.4-7l0.3-0.4c0.6-0.7,1.1-1.5,1.7-2.4c0.7-1.1,1.4-2.2,2.4-3.2
				c1.6-1.5,3.5-2.6,5.3-3.7c0.6-0.3,1.2-0.7,1.7-1c5.9-3.7,11-8.3,15.1-13.8c0.3-0.5,0.7-0.9,1-1.4l0.1-0.1c2.6-3.5,5.2-7.1,9.3-9.2
				c1.1-0.6,2.2-0.7,3-0.7c0.4,0,0.9,0,1.3,0l0.1,0c0.4,0,0.7,0,1.1,0l0.2,0c2-0.1,4.3-0.8,6.6-2.1c0.7-0.4,1.3-0.8,1.7-1.1
				c-0.7-0.2-1.5-0.2-2.5-0.2c-0.7,0-1.4,0.1-2.1,0.2c-0.7,0.1-1.5,0.1-2.1,0.2c-0.5,0-0.9,0-1.3,0c-1-0.1-1.6-0.4-1.7-1.1
				c-0.2-1.1,1-1.8,2.5-2.6c0.3-0.2,0.7-0.4,0.8-0.4c1.2-0.9,2.2-1.9,3.3-2.9c1-0.9,1.9-1.9,3-2.7c1.4-1.1,3-1.6,5.1-1.7
				c0.9,0,1.8,0,2.7,0c0.5,0,1.1,0.1,1.6,0.1c4.1,0.1,8.5,0.1,14.1,0.3c0.8,0,1.5,0,2.3,0c3.3,0,6.8,0,9.9,1.3l0.1,0
				c4,1.6,5.5,2.8,5.3,4.2c-0.1,0.5-0.5,1.1-1.4,1.1c-0.9,0-2.2-0.4-3.6-0.9c-0.4-0.1-0.8-0.3-0.9-0.3c-3.9-0.9-8-1.4-11.5-1.8
				c-0.5,0-1-0.1-1.6-0.2c-1.7-0.2-3.6-0.4-5.4-0.3c-1.4,0.1-2.5,0.3-3.3,0.7c-0.8,0.4-1.8,1.8-2.5,3c-0.2,0.4-0.5,0.8-0.8,1.2
				c-1.7,2.3-2.8,4.3-3,7.1c-0.1,1-0.6,2.1-1.1,3.1c-0.6,1.1-1.2,2.3-0.9,3.1c0,0.1,0.1,0.2,0.8,0.3c0.8,0.1,2.3,0.3,2.8,2.1
				c0.2,0.9,0.3,1.9,0.4,2.8c0.1,1.7,0.3,3.4,1.4,4.6c1.1,1.2,2.4,2.4,3.6,3.5c0.4,0.3,0.7,0.7,1.1,1c1.9,1.7,3.7,3.4,5.8,4.5
				c0.9,0.5,1.8,0.8,2.8,1.2c1.2,0.4,2.4,0.9,3.5,1.6c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.5,1.3-1c0.5-0.6,1.1-1.2,1.9-1.6
				c0.5-0.2,0.9-0.2,1.3-0.2c0.2,0,0.5,0,0.6-0.1c1-0.3,1.7-1.1,2.3-2.2c0.9-1.8,2-2.8,3.1-2.8l0.1,0c0.8,0,1.5,0.5,2.2,1.4
				c0.9,1.4,1.1,3,1.2,4.5c0.1,0.8,0.1,1.5,0.3,2.2c0.2,0.7,0.5,0.9,2,0.9c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c1,0,2.1,0.1,2.8,0.9
				c0.6,0.6,0.8,1.5,0.6,2.7c-0.1,0.9-0.3,1.8-0.5,2.6c-0.3,1.2-0.5,2.4-0.5,3.6c0,0.3,0,0.6,0,0.9c0.1,2.3,0.1,4.5-1.4,6.7
				c-0.3,0.5-0.6,0.9-0.9,1.3c-1.2,1.6-1.9,2.5-1.3,4.5c0.3,1.1-0.1,2.3-0.5,3.7c-0.3,1-0.7,2.6-0.3,2.9c2,1.9,1.8,3.8,1.5,6.1
				l-0.1,0.6c-0.3,2.4,0.7,3.8,2.9,5.8c0.3,0.3,0.7,0.6,1,0.8c1.4,1.2,2.7,2.3,3.3,4.6c0.1,0.4,0.2,0.9,0.3,1.4
				c0.3,1.5,0.6,3.4,1.5,3.8c1.9,1,1.7,2.1,1.5,3c-0.1,0.4-0.2,0.6,0,1c0,0,0.2,0.1,0.8,0.2c0.4,0.1,0.8,0.2,1.1,0.4
				c1.1,0.6,2,1.4,2.9,2.2c0.2,0.1,0.3,0.3,0.5,0.4l0.3,0.3c0.8,0.7,1.6,1.4,2.3,1.4l0,0c0.2,0,0.4-0.1,0.7-0.3
				c1.7-1.2,3.1-2.9,4.5-4.6c0.4-0.5,0.8-1,1.2-1.5c0.2-0.3,0.5-0.5,0.7-0.8c1.5-1.8,3-3.4,4-5.6c1.1-2.3,2.7-5.2,6-6.9
				c1.9-1,3.9-1.6,5.8-2.2l0.1,0c0.4-0.1,0.8-0.2,1.1-0.3c0.3-0.1,0.5-0.2,0.8-0.2c1.7-0.5,3.6-1.1,4.6-2.2c0.2-0.3,0.3-0.6,0.4-1.1
				c0.1-0.3,0.1-0.6,0.2-1c0.4-1.2,1.1-2.4,2.2-3.7c0.4-0.5,0.9-1,1.4-1.5c0.5-0.5,0.9-0.9,1.3-1.4c1.2-1.5,2.5-3,4.2-3.8
				c0.5-0.2,0.9-0.4,1.4-0.4c0,0,0.1,0,0.1,0c2.3,0,3.3,2.7,3.5,5.1c0,0.1,0,0.4,0.8,0.4c0,0,0.1,0,0.1,0c0.4,0,0.9-0.1,1.2-0.3
				c0.6-0.4,1.1-0.8,1.7-1.2c0.4-0.3,0.8-0.6,1.2-0.8c-0.1,0-0.1,0-0.2,0c-0.9-0.1-1.6-0.7-1.9-1.6c-0.6-1.5,0-3.8,0.8-4.8
				c1.6-1.8,3.2-2.7,5-3.8l0,0c0.4-0.3,0.9-0.5,1.3-0.8c0.2-0.1,0.4-0.3,0.6-0.4c0.6-0.4,1.3-1,2.1-1.1c0.4-0.1,0.7-0.1,0.9-0.1
				c0.6,0,0.8,0,1.2-0.4c1.8-1.7,2.7-3.1,2.8-3.6c-0.3-0.1-0.9-0.1-1.4-0.1l-0.7,0c-1.3,0-2.6-0.1-3.1-1c-0.5-1,0.2-2,0.4-2.3
				c0.8-1.2,1-2,0.6-2.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.8-1.4-2.1-0.7-3.3c0.3-0.5,0.9-0.9,2.3-1.7l0,0c0.1,0,0.2-0.1,0.3-0.1
				c-0.3-0.6-0.3-1.2-0.2-1.6c0-0.5,0.1-0.9,0.1-1.4c0.1-0.7,0.1-1.2,0.1-1.8c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.5-0.2-1.2,0.2-2.2
				c0.5-1.1,1.1-2.1,1.7-3.1l0.3-0.5c0.1-0.1,0.2-0.4,0.4-0.7l0,0c1.6-3.1,2.7-4.8,4.1-4.9l0.1,0c0.4,0,0.8,0.2,1.1,0.5
				c1.2,1.1,0.9,3.2,0.6,4.9c0,0.2,0,0.3-0.1,0.5c0.3-1,0.6-1.9,1.2-2.7c0.7-0.9,1.6-1.4,2.6-2c1-0.6,2-1.2,2.2-2
				c0.4-1.3,0.3-2.8,0.2-4.2l-0.1-0.8l0.8-0.2c0.6-0.2,1.3,0,1.7,0.4c0.8,0.8,0.8,2.3,0.8,3.2v0.1c0,0.9-0.1,1.7-0.1,2.6
				c-0.1,1.3-0.2,2.7-0.1,3.9c0.1,0.5,0.1,0.9,0.2,1.3c0.2,1.4,0.4,2.6-0.5,4.5c-1.1,2.1-2.5,4.5-4.4,7.2c-0.9,1.2-2,2.4-3.4,3.5
				c-0.4,0.3-0.9,0.6-1.3,0.9c-0.8,0.5-1.5,1-2,1.6c-0.5,0.7-0.3,2.1-0.2,3.5c0.1,1.2,0.3,2.4,0.1,3.4c-0.6,2.4-1.4,3.9-3,5.4
				c-0.7,0.7-1.1,1.4-1.2,2.2c0,0.3,0,0.7,0.1,1.1c0.1,0.9,0.2,1.9-0.3,2.9c-0.3,0.6-1,1.3-2.3,1.5c0.4,0.5,0.5,1.1,0.3,1.9
				c-0.1,0.9-0.4,1.7-0.6,2.5c-0.2,0.6-0.3,1.2-0.4,1.8c0.1-0.1,0.3-0.2,0.4-0.4c0.5-0.6,1-1.2,1.6-1.9c1.3-1.7,2.5-3.4,4.7-4.1
				c0.5-0.1,1.1-0.3,1.8-0.3c0.9,0,3.2,0,4.3,1.3c0.5,0.6,0.7,1.4,0.6,2.2c-0.2,1.2-1,1.9-1.7,2.5c-0.3,0.3-0.6,0.5-0.7,0.7
				c-0.2,0.3-0.4,0.6-0.5,1c-0.2,0.5-0.5,1.2-1.1,1.7c-0.9,0.9-2,1.6-3,2.2c-1.3,0.8-2.5,1.6-3.4,2.7c0,0,0,0,0,0.1c0.1,0,0.2,0,0.4,0
				c0.5,0,1.2-0.1,1.8-0.2c0.7-0.1,1.5-0.2,1.9-0.1c1.6,0.2,2.9,0.8,3.6,1.9c0.1,0.2,0.2,0.3,0.3,0.5c0-0.6,0-1.2,0.1-1.7l0-0.2
				c0-0.2,0-0.5,0-0.7c0-0.9,0.1-1.9,0.4-2.8c0.5-1.2,1.2-1.8,1.9-2.5c0.3-0.2,0.5-0.5,0.8-0.7c1.2-1.3,2.1-2.6,2.5-4.4
				c0.3-1.1,1.7-1.9,2.8-2c0,0,0,0,0,0c-0.3-0.4-0.5-0.9-0.7-1.3c-0.2-0.6-0.4-0.9-0.7-1c-0.7-0.3-1.5-0.6-1.7-1.5
				c-0.2-0.8,0.3-1.5,0.9-2.1l0.2-0.2l0.2-0.1c0.3-0.1,0.7-0.1,1.1-0.2c0.5,0,0.7-0.1,0.8-0.1c0.1-1.9,0.6-3.8,1.7-5.7
				c0.5-0.9,1.4-2.1,2.4-2.1l0.1,0c0.5,0,1.3,0.2,1.6,1.5c0.2,0.7,0.8,3,0.4,4.4c-0.3,1-0.8,1.9-1.3,2.8c-0.5,0.8-0.9,1.6-1.1,2.5
				l0,0.1c-0.6,2.4-1.3,3.6-2.4,3.8c0.8,1.2,0.3,2.8-0.1,3.8l0,0.1c-0.1,0.3-0.2,0.6-0.4,1c-1,2.1-1,2.7-0.9,2.8c0,0,0.1,0,0.3,0
				c1.1,0,1.4-0.5,1.9-1.5c0.4-0.8,0.9-1.6,1.9-2.2c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.6,0,0.8,0l0,0.2l0,0l0-0.2c0.2,0,0.3,0,0.4,0
				c0-0.7-0.3-1.6-0.6-2.4c-0.6-1.3-1.5-3.4,0.2-5.2c0.2-0.2,0.3-0.3,0.5-0.5c0.4-0.3,0.5-0.5,0.6-0.8c0.5-2.1,0.3-4.4,0.1-6.7l0-0.2
				c0-0.3,0-0.6-0.1-0.9c-0.1-1.8-0.1-3.5,1.2-5.3l0-0.1c0.9-1.2,1.7-2.1,2.6-2.3c0.4-0.1,0.8,0,1.2,0.4c0.9,1,0.5,4.9,0.4,5.4
				c-0.1,0.9-0.3,1.6-0.6,2.4c-0.5,1.7-0.9,2.9,0.2,4.3c0.1,0.2,0.3,0.4,0.5,0.5c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.2-0.4-0.2-0.5
				c-0.6-2-0.6-3.3-0.1-4.1c0.3-0.4,0.8-0.7,1.3-0.6c1,0.1,1.9,0.8,2.6,1.4l0,0c0.2,0.2,0.3,0.3,0.5,0.4c0.1,0.1,0.2,0.1,0.2,0.2
				c0.3,0.2,1,0.8,1.3,0.8c0.1-0.1,0.2-0.4,0.3-0.6c0.1-0.3,0.2-0.6,0.4-0.9c0.5-0.8,1.1-1,1.5-1l0.1,0c1,0,1.8,0.9,2.1,1.7
				c0.3,0.7,0.5,0.9,1,1.3c0.2,0.2,0.4,0.3,0.7,0.6c0.5,0.5,0.7,1,0.6,1.5c-0.1,1-1,1.5-1.9,2c-0.8,0.5-1.1,0.7-1.2,0.8c0,0,0,0,0,0.1
				c0.7,0.9,1.7,1.4,2.7,1.4l0.1,0c0.8,0,1.6-0.4,2.2-1.1c0.1-0.1,0.1-0.2,0.2-0.3l0.1-0.1c1.1-1.5,2.5-3.2,3.8-3.3l0.1,0
				c0.4,0,1.1,0.1,1.6,1.1c1,1.9,0.4,4.2-1.6,6.5c-0.5,0.6-0.8,1.4-1,2.2c-0.1,0.2-0.1,0.4-0.2,0.5c-0.3,0.8-0.7,1.1-1.1,1.4
				c-0.1,0.1-0.2,0.1-0.3,0.3c-0.3,0.2-0.6,0.7-1,1.2c-1,1.3-2.2,3-3.9,3.1c-0.3,0-0.6,0-0.9-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
				c0.4,0.4,0.8,0.7,1.4,1.1c0.8,0.6,1.2,1.3,1.1,2c-0.1,1.4-1.6,2.2-2.9,2.9c-0.6,0.3-0.9,0.5-0.9,0.5c0,0.1-0.1,0.3-0.1,0.4
				c-0.1,0.3-0.2,0.6-0.3,0.9c-0.8,1.3-2.3,1.7-3.4,1.8c-0.9,0.1-1.8,0.5-2.7,0.9c-1.2,0.5-2.4,1.1-3.9,1.1c-0.5,0-1.4,0.3-2.3,0.7
				c-1.4,0.5-2.8,0.9-3.9,1c-0.5,0-0.9,0-1.3-0.2c-0.1,0.9-0.6,1.7-1.5,2.5c-0.6,0.5-1.2,0.9-1.7,1.3c0.2,0,0.4,0,0.7,0
				c0.8,0,1.9,0.1,2.9,0.9c0.8,0.6,1.3,0.8,2,0.6c0.5-0.1,0.9-0.6,1.4-1.2c0.1-0.2,0.3-0.3,0.4-0.5c0.8-0.9,1.7-1.1,2.6-1.3
				c0.4-0.1,0.8-0.2,1.2-0.3l0.3-0.2c2-1,3.5-1.8,5.4-1.9c1.2,0,2.4,0.2,3.8,0.8c0,0,0,0,0,0c0-0.2,0-0.4,0-0.7
				c0.3-2.1,2.9-4.7,4.3-5.2c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0,1.4,0.1c0.3,0,0.6,0.1,0.9,0.1l0.1,0c0.3,0,0.7,0,0.9-1.1
				c0.8-3.1,3.6-3.7,6.1-4.1c0.6-0.1,1.5,0,2.1,0.1c0.4,0.1,0.8,0.1,1.1,0.1c0.4,0,0.8-0.1,1.2-0.8c0.8-1.4,1.1-3.3,1.3-5.1
				c0.1-0.9,0.2-1.8,0.4-2.7c0.1-0.4,0.6-2.3,1.9-2.4l0.1,0C1275.4,1707.6,1275.9,1708.1,1276.2,1709.2z M1229.1,1723.9l-1.1,0.4
				c1.5,0.2,3.2,0.5,3.5,0.6c0.9,0.3,1.6,0.8,2.1,1.3c0.6-0.8,2.1-1.4,5-2.1l0.1,0c0.1,0,0.2,0,0.2-0.1c1.9-0.6,3.7-1.6,5.4-2.5
				c0.4-0.2,1.1-0.7,1.7-1.1c-0.5,0-1.1,0-1.7,0l-1,0c-0.3,0-0.5,0-0.7,0l-0.1,0c-0.3,0-0.5,0-0.8,0c-0.5,0-0.9,0-1.3,0
				c-1,0-1.8,0.2-2.5,0.7l-0.2,0.1c-0.9,0.6-1.9,1.2-3.1,1.4c-0.7,0.1-1.4-0.1-1.8-0.3c-0.3-0.1-0.5-0.2-0.7-0.2l-0.1,0
				c-1,0.1-2,0.7-3,1.3c-0.3,0.2-0.5,0.3-0.8,0.5L1229.1,1723.9z M1253.3,1715c-0.5-0.8-0.5-1.9-0.4-3c-0.1,0.2-0.1,0.3-0.1,0.4
				C1252.4,1713.5,1252.7,1714.3,1253.3,1715z M1209.4,1705.1L1209.4,1705.1c-0.5,0-0.8,0.2-1.2,0.5c0.5-0.2,0.9-0.3,1.4-0.4
				c0.1,0,0.2,0,0.2-0.1C1209.6,1705.1,1209.5,1705.1,1209.4,1705.1z M1216.9,1731.9c0.5-0.1,1-0.2,1.5-0.4c0.3-0.1,0.5-0.1,0.8-0.2
				c-0.6,0-1.2-0.1-1.8-0.1c-1.1-0.1-2.4-0.2-3.6-0.2c-0.2,0-0.4,0-0.7,0c-1.8,0.1-2.1,0.4-2.1,0.5c0,0,0,0,0,0.2
				c0.4,1.6,1.6,1.8,4,1.9c0.4,0,0.7,0.1,1.1,0.2c-0.2-0.3-0.3-0.5-0.3-0.9C1215.9,1732.7,1216,1732.1,1216.9,1731.9z M1223.6,1723.8
				c0,0.5-0.1,1-0.2,1.5c-0.1,0.4-0.1,0.9-0.1,1.5c0,1.2-0.1,2.6-0.9,3.6c0.8-0.3,1.6-0.8,2.3-1.3c1.3-1,1.2-1.2,0.4-2.8l-0.2-0.4
				c-0.2-0.3-0.2-0.7,0-1c-0.3-0.1-0.5-0.3-0.7-0.5C1223.9,1724.3,1223.7,1724,1223.6,1723.8z M1190.5,1908.9L1190.5,1908.9
				L1190.5,1908.9L1190.5,1908.9z M1115,2048.2c0,0,0.1,0,0.3,0l0.1,0c1.1,0,2.8-1.1,3.8-1.7c0.2-0.1,0.4-0.2,0.6-0.3
				c0.8-0.4,1.6-0.9,2.3-1.4c0.8-0.5,1.6-1,2.4-1.5c-0.6,0.1-1.2,0.1-1.7,0.2c-2.6,0.3-5.4,0.6-7.3,1.9c-0.4,0.3-0.7,0.9-0.7,1.5
				C1114.5,2047.3,1114.6,2047.8,1115,2048.2z M927.9,2354.8c-0.6,0.5-0.9,0.9-1.1,1c0.1,0,0.2,0,0.3,0l0,0c1.1,0,2.3-2,3-3.2
				c0.2-0.3,0.4-0.6,0.6-0.9c1.4-2,3-3.9,4.8-5.5c-1.4,0.7-2.6,1.6-3.7,2.7c-0.7,0.8-1.2,1.6-1.7,2.6c-0.5,0.8-1,1.7-1.6,2.4
				C928.4,2354.3,928.2,2354.6,927.9,2354.8z M670.9,2192.1c0.1,0.6,0.2,1.2,0.3,2c0.1,1.3,0.3,3,1,3.4
				C672.1,2196.7,671.6,2194.3,670.9,2192.1z M588,2311c-0.2,0.1-0.3,0.2-0.5,0.2c1.2-0.3,2.4-0.4,3.6-0.4c1.5-0.1,2.9-0.2,4.2-0.7
				c0.6-0.2,1.6-1.3,1.7-1.8c0-0.1-0.1-0.1-0.3-0.2c-0.2-0.1-0.5-0.2-0.8-0.2c0,0-0.1,0-0.1,0c-1.3,0.1-2.9,0.8-4.1,1.4l-0.1,0
				c-0.3,0.1-0.5,0.2-0.7,0.3C589.9,2310.1,588.9,2310.6,588,2311z M561.1,2360.9c0.1,0.5,0.2,1,0.3,1.5c0.1,0.4,0.1,0.8,0.2,1.1
				c0,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.1,0.7,0.2,0.9c0-0.4,0-0.9-0.1-1.3c-0.1-0.8-0.3-1.8-0.6-2.8
				C561.1,2361.1,561.1,2361,561.1,2360.9z M720.7,2524.2c-0.5-4-2.2-4.8-3.5-4.8c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.1-0.9,0.2
				c-1,0.3-1.9,1-2.8,1.6c-1,0.7-2,1.4-3,1.5c-0.3,0-0.7-0.3-0.8-0.6c-0.1-0.2,0-0.2,0-0.3c0.4-0.5,1.1-0.7,1.8-1
				c0.3-0.1,0.5-0.2,0.8-0.3c1-0.4,1-1.1,0.9-1.4c-0.2-1-1.5-1.1-2.5-1.2l-0.1,0c-0.4,0-0.8-0.1-1.3-0.1c-1.5-0.1-3-0.2-4.4-0.7
				c-0.3-0.1-1-0.7-1.4-1.1c-0.9-0.8-1.8-1.5-2.6-1.5l-0.1,0c-0.1,0-0.2,0-0.3,0c-1,0.2-1.8,0.8-2.6,1.6c0-0.1,0-0.2,0-0.2
				c0-0.9-0.7-1.6-1.5-2.4c-0.1-0.1-0.3-0.3-0.5-0.5c0,0,0.1,0,0.1,0h0l0.1,0c1.6-0.1,2.1-1.9,2.3-3c0-0.1,0.1-0.2,0.1-0.3
				c0-0.1,0-0.1,0-0.1c0.1,0,0.2,0.1,0.4,0.3c0,0,0.1,0.2,0.1,0.3c0.1,0.3,0.3,0.7,0.7,1c1.1,1,3,1.2,4.3,1.2c0.5,0,1.1,0.1,1.7,0.2
				c1.1,0.1,2.3,0.2,3.4,0.2c1.3-0.1,1.8-0.8,2.1-1.3c0.6-1.4-0.1-3.4-0.9-4.4c-1.5-2-3.6-3.4-5.6-4.8c-1.7-1.1-3.4-2.3-4.7-3.8
				c-1.1-1.3-2.1-3-3-4.5c-1.5-2.7-3.1-5.4-5.9-7.2c-1.4-0.9-2.9-1.3-4.6-1.3c-0.6,0-1.2,0.1-1.8,0.2c-0.7,0.1-1.3,0.4-1.9,0.6
				c-0.9,0.3-1.8,0.6-2.6,0.6c-0.3,0-0.7,0-1-0.1c-0.6-0.2-1.3-0.2-2-0.2c-2.6,0.1-5.9,1.5-7.1,3.9c-1.3,2.5-1.2,4.5-0.8,6.8
				c0.1,0.5,0,1.1-0.1,1.6c-0.1,0.8-0.2,1.6,0,2.5c0.3,1.2,1.1,2.1,1.9,2.9c0.2,0.3,0.5,0.5,0.7,0.8c1.6,2,3.3,4.4,4,7
				c0.1,0.2,0.1,0.5,0.2,0.7c0.5,1.7,1.3,4.7,0.8,6c-0.6,1.7-2.5,2.4-4.4,3c-0.5,0.2-0.9,0.3-1.4,0.5c-0.9,0.4-1.5,0.9-1.7,1.6
				c-0.4,1.2,0.4,2.4,1.1,3.5l0.2,0.3c0,0.1,0.1,0.1,0.2,0.2l0.1,0.1c2.6,3.8,3.3,5.5,3.6,6.2c-0.7-0.1-1.4-0.2-2.1-0.3
				c-1.1-0.2-2.3-0.5-3.6-0.4c-0.6,0-1.2,0.1-1.8,0.3c-3,0.9-5.5,5.4-6.2,8.3c-0.3,1.5-0.1,3.1,0.8,5c0.1,0.2,0.2,0.4,0.3,0.6
				c0.2,0.3,0.5,0.9,0.5,1.1c-0.1,0.4-0.6,0.8-1,1.2c-0.4,0.3-0.7,0.6-1,1c-0.4,0.5-0.7,1-0.9,1.5c-0.4,0.7-0.7,1.3-1.2,1.7
				c-0.4,0.3-0.7,0.7-1.1,1c-1.8,1.5-3.3,2.9-3.9,5.9c-0.3,1.4-1.1,5.6,1.7,6.4c1.5,0.4,3.5,0.9,5.5,0.9c0.2,0,0.3,0,0.5,0
				c0.9,0,1.8-0.2,2.6-0.4c1.8-0.6,2.9-1.5,3.3-2.7c0.5-1.4-0.4-2.4-0.9-3c-0.2-0.2-0.3-0.4-0.4-0.5c-0.1-0.2-0.1-0.2,0.5-0.9
				c0.2-0.2,0.4-0.4,0.5-0.6c0.8-1.1,1-2.3,1.2-3.4l0-0.3c0.1-0.5,0.1-0.5,0.8-0.7c1.2-0.3,1.9-1.3,2.6-2.1l0,0
				c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.7-0.9,1.8-2.2,2.7-2.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.2
				c0.5,0.4,1.1,0.8,1.9,1c0.2,0,0.5,0.1,0.7,0.1l0.1,0c0.7,0,1.3-0.3,1.8-0.5c0.4-0.2,0.8-0.3,1.1-0.4c0,0,0.1,0.1,0.1,0.1
				c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,1.1,0.9,2,0.9c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0l0.2,0
				c2.8-0.1,5.3-1.2,8.1-3.5c0.5-0.4,0.9-0.8,1.3-1.2c1-0.9,2.1-1.9,3.2-2.3c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0c1.1-0.2,1.9-1,2.7-1.6
				c0.3-0.3,0.6-0.5,0.8-0.7c0.8-0.5,1.3-0.6,1.7-0.6c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.5,0.1l0.1,0c1.9-0.1,2-2.1,2.1-3.1
				c0-0.6,0.1-1,0.2-1.1c0.7-0.1,1.6,0.2,2.5,0.5l0,0c0.8,0.3,1.6,0.5,2.4,0.6c0.3,0,0.6,0,0.8,0c3-0.1,3-2.8,3-4.7c0-0.3,0-0.5,0-0.8
				c0.1-1.6,0.3-2,1.8-2.6c3-1.3,2.6-4.6,2.2-7L720.7,2524.2z M1334.2,1098.9c0.5,0.4,0.9,0.7,1.3,1.1c1.2,1.1,2.1,1.7,3.3,1.6
				c0.7,0,1.4-0.3,2.3-0.7c0.1,0,0.1-0.1,0.2-0.1c0.1,0.2,0.1,0.7,0,2.1c0,0.6-0.1,1,0,1.4c0.2,1.7,1.2,1.9,1.7,1.9c0,0,0.1,0,0.1,0
				c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0c0.3,0.2,0.5,0.8,0.7,1.2c0.1,0.3,0.3,0.7,0.5,1
				c0.7,1.2,2,2.1,2.9,2.6c0.9,0.5,1.7,0.8,2.5,0.8c0.1,0,0.1,0,0.2,0c1.6-0.1,2.6-1.2,2.9-3.1c0.5-3.2-1.6-5.6-3.4-7.6l-0.4-0.4
				c-2.1-2.5-2.8-4.5-2.1-6.9c0.1-0.3,0.2-0.7,0.4-1.1c0.6-1.7,1.7-4.5-0.7-6c-0.8-0.5-1.7-0.8-2.7-1.1c-1.5-0.5-3-0.9-3.5-1.8
				c-0.1-0.1,0.1-0.5,0.1-0.7c0-0.1,0.1-0.2,0.1-0.3c0.3-1.1,0.2-2,0.1-3c0-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.8,0-1.3
				c0.1-1.9,0.3-4.6-1.6-5.6c-0.7-0.4-1.5-0.5-2.6-0.5c-2.2,0.1-4.8,1.2-6.1,2.4c-0.9,0.9-0.7,2-0.5,2.7c0,0.1,0.1,0.2,0.1,0.4l0.1,0.4
				l0.3,0.2c0.4,0.3,2.2,1.8,1.5,3.3c-0.1,0.3-0.4,0.7-0.7,1c-0.5,0.5-0.9,1.1-1.2,1.9c-0.3,0.8,0.1,1.6,0.3,2.2
				c0.1,0.3,0.3,0.6,0.3,0.8c-0.1,0.8-0.4,1.7-0.8,2.5c-0.6,1.2-1.2,2.4-1,4C1330.2,1095.6,1332.3,1097.4,1334.2,1098.9z
				M1309.5,1094.1c0.1,0,0.1,0,0.2,0c0.8,0,1.6-0.4,2.1-0.9c1.6-1.8-0.1-6.9-0.7-8c-0.6-1-1.5-1.6-2.6-1.5c-1.2,0.1-2.5,0.8-3.5,1.5
				l-0.2,0.2l-0.3,0.7c-0.6,1.5-1.4,3.7-0.2,5.4C1305.1,1092.7,1307.6,1094.1,1309.5,1094.1z"
      />
      <path
        fill="none"
        stroke="#3A5686"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M842.1,1369.7l410,276l54,216 M1212.1,1863.7h1292 M682.1,2155.7h-552 M1272.1,1373.7l-412-46 M1598.1,825.7
				v406 M1217.2,3059.1c-13.8-44.1-21.3-91-21.3-139.6c0-64.4,13-125.7,36.6-181.5c0,0-73.6,108.1-200,160.9
				c71.9,37.2,133.4,91.7,179.1,158c0.8,1.2,1.6,2.4,2.4,3.6 M1276.2,3181.2c83.9,123.4,225.4,204.5,385.9,204.5
				c257.5,0,466.2-208.7,466.2-466.2c0-39.3-4.9-77.4-14-113.9c21.2-52,33.8-173,33.8-173c395-92,439-368,442-502s-60-269-202-413
				c-142-144-406-109-406-109c168-385-112-633-112-633c123,13,245-82,277-123c32-41,100-192,141-312c41-120,60-460-214-526
				s-387,147-387,147l-116,283c-165,257,147,465,147,465h-52c-181-238-340-274-340-274c-91-125-347-103-347-103s-97,74-206,167
				s-65,331-65,331l151,288l-9,42c0,0-173,96-270,337c-678,456-478,862-478,862c-38,72-24,240-24,240c-34,62-76,324-76,324
				c270,262,400,536,406,558c6,22,48,38,151.4,75.6c79.3,28.9,157.6,67.3,248.1,67.3c270.7,0,490.1-219.4,490.1-490.1
				c0-52-8.1-102.2-23.1-149.2"
      />
      <path
        fill="#FFFFFF"
        d="M1851.2,489.7h20.2c7.4,0,13.1,5.8,13.1,14.4c0,8.6-5.8,14.4-13.1,14.4h-1.6l16,16h-8.6l-16-16h-3.2v16h-6.7
				V489.7z M1871,512.1c3.5,0,6.7-3.2,6.7-8c0-4.8-3.2-8-6.7-8h-13.1v16H1871z M1932.5,519.1c-1.6,7-8,16-20.2,16
				c-11.8,0-21.4-9.3-21.4-23c0-13.8,9.6-23,21.4-23c11.2,0,18.2,7.7,20.2,15.7h-7c-2.2-6.1-7.4-9.3-13.1-9.3
				c-8.3,0-14.7,6.4-14.7,16.6c0,10.2,6.4,16.6,14.7,16.6c7.4,0,11.5-4.8,13.1-9.6H1932.5z M1937.6,512.1c0-13.8,9.6-23,21.4-23
				s21.4,9.3,21.4,23c0,13.8-9.6,23-21.4,23S1937.6,525.8,1937.6,512.1z M1944.3,512.1c0,10.2,6.4,16.6,14.7,16.6s14.7-6.4,14.7-16.6
				c0-10.2-6.4-16.6-14.7-16.6S1944.3,501.8,1944.3,512.1z M1994.2,500.9h-9.3v-6.4h7.4c1.3,0,1.9-0.6,1.9-1.9v-2.9h6.7v44.8h-6.7
				V500.9z M2044.8,512.1c0,14.7-8.3,23-17.9,23c-9.6,0-17.9-8.3-17.9-23c0-14.7,8.3-23,17.9-23C2036.5,489,2044.8,497.4,2044.8,512.1z
				M2026.9,495.4c-5.8,0-11.2,5.4-11.2,16.6c0,11.2,5.4,16.6,11.2,16.6c5.8,0,11.2-5.4,11.2-16.6
				C2038.1,500.9,2032.6,495.4,2026.9,495.4z M1748.5,1516.7h20.2c7.4,0,13.1,5.8,13.1,14.4c0,8.6-5.8,14.4-13.1,14.4h-1.6l16,16h-8.6
				l-16-16h-3.2v16h-6.7V1516.7z M1768.3,1539.1c3.5,0,6.7-3.2,6.7-8c0-4.8-3.2-8-6.7-8h-13.1v16H1768.3z M1829.8,1546.1
				c-1.6,7-8,16-20.2,16c-11.8,0-21.4-9.3-21.4-23c0-13.8,9.6-23,21.4-23c11.2,0,18.2,7.7,20.2,15.7h-7c-2.2-6.1-7.4-9.3-13.1-9.3
				c-8.3,0-14.7,6.4-14.7,16.6s6.4,16.6,14.7,16.6c7.4,0,11.5-4.8,13.1-9.6H1829.8z M1871,1539.1c0,14.7-8.3,23-17.9,23
				c-9.6,0-17.9-8.3-17.9-23s8.3-23,17.9-23C1862.7,1516,1871,1524.3,1871,1539.1z M1853.1,1522.4c-5.8,0-11.2,5.4-11.2,16.6
				s5.4,16.6,11.2,16.6c5.8,0,11.2-5.4,11.2-16.6S1858.9,1522.4,1853.1,1522.4z M1877.4,1555.7l18.4-18.6c2.2-2.2,3.3-3.8,3.3-7
				c0-4.8-2.9-7.7-7.4-7.7s-8,3.5-8,8.6h-6.7c0-9,6.1-15,14.7-15s14.4,5.8,14.4,14.1c0,5.4-3.2,9-6.4,12.2l-12.5,12.5v0.3h19.5v6.4
				h-29.4V1555.7z M1623.5,2798.1h20.2c7.4,0,13.1,5.8,13.1,14.4s-5.8,14.4-13.1,14.4h-1.6l16,16h-8.6l-16-16h-3.2v16h-6.7V2798.1z
				M1643.3,2820.5c3.5,0,6.7-3.2,6.7-8s-3.2-8-6.7-8h-13.1v16H1643.3z M1704.7,2827.6c-1.6,7-8,16-20.2,16c-11.8,0-21.4-9.3-21.4-23
				c0-13.8,9.6-23,21.4-23c11.2,0,18.2,7.7,20.2,15.7h-7c-2.2-6.1-7.4-9.3-13.1-9.3c-8.3,0-14.7,6.4-14.7,16.6
				c0,10.2,6.4,16.6,14.7,16.6c7.4,0,11.5-4.8,13.1-9.6H1704.7z M1746,2820.5c0,14.7-8.3,23-17.9,23c-9.6,0-17.9-8.3-17.9-23
				s8.3-23,17.9-23C1737.7,2797.5,1746,2805.8,1746,2820.5z M1728.1,2803.9c-5.8,0-11.2,5.4-11.2,16.6s5.4,16.6,11.2,16.6
				c5.8,0,11.2-5.4,11.2-16.6S1733.9,2803.9,1728.1,2803.9z M1758.2,2830.1c0,3.5,3.5,7,8.6,7c5.4,0,8.3-2.9,8.3-7c0-3.8-3.2-7-8.6-7
				h-2.9v-6.1h2.9c5.1,0,8-2.9,8-6.7c0-3.5-2.9-6.4-7.7-6.4s-8,2.9-8,6.4h-6.7c0-7,5.8-12.8,14.7-12.8c8.6,0,14.4,5.8,14.4,12.8
				c0,7-6.4,9.6-6.4,9.6v0.3c0,0,7,2.6,7,10.2c0,7.4-6.1,13.1-15,13.1c-9.3,0-15.4-6.1-15.4-13.4H1758.2z M726,1780.1h20.2
				c7.4,0,13.1,5.8,13.1,14.4c0,8.6-5.8,14.4-13.1,14.4h-1.6l16,16h-8.6l-16-16h-3.2v16H726V1780.1z M745.8,1802.5c3.5,0,6.7-3.2,6.7-8
				c0-4.8-3.2-8-6.7-8h-13.1v16H745.8z M807.3,1809.5c-1.6,7-8,16-20.2,16c-11.8,0-21.4-9.3-21.4-23s9.6-23,21.4-23
				c11.2,0,18.2,7.7,20.2,15.7h-7c-2.2-6.1-7.4-9.3-13.1-9.3c-8.3,0-14.7,6.4-14.7,16.6s6.4,16.6,14.7,16.6c7.4,0,11.5-4.8,13.1-9.6
				H807.3z M812.4,1802.5c0-13.8,9.6-23,21.4-23s21.4,9.3,21.4,23s-9.6,23-21.4,23S812.4,1816.3,812.4,1802.5z M819.1,1802.5
				c0,10.2,6.4,16.6,14.7,16.6s14.7-6.4,14.7-16.6s-6.4-16.6-14.7-16.6S819.1,1792.3,819.1,1802.5z M881.2,1786.5h-21.4v-6.4h29.1v5.8
				l-16,39h-7.4L881.2,1786.5z M909,1003.2h20.2c7.4,0,13.1,5.8,13.1,14.4c0,8.6-5.8,14.4-13.1,14.4h-1.6l16,16H935l-16-16h-3.2v16H909
				V1003.2z M928.9,1025.6c3.5,0,6.7-3.2,6.7-8c0-4.8-3.2-8-6.7-8h-13.1v16H928.9z M990.3,1032.6c-1.6,7-8,16-20.2,16
				c-11.8,0-21.4-9.3-21.4-23c0-13.8,9.6-23,21.4-23c11.2,0,18.2,7.7,20.2,15.7h-7c-2.2-6.1-7.4-9.3-13.1-9.3
				c-8.3,0-14.7,6.4-14.7,16.6s6.4,16.6,14.7,16.6c7.4,0,11.5-4.8,13.1-9.6H990.3z M995.4,1025.6c0-13.8,9.6-23,21.4-23
				s21.4,9.3,21.4,23c0,13.8-9.6,23-21.4,23S995.4,1039.3,995.4,1025.6z M1002.2,1025.6c0,10.2,6.4,16.6,14.7,16.6s14.7-6.4,14.7-16.6
				s-6.4-16.6-14.7-16.6S1002.2,1015.3,1002.2,1025.6z M1052.1,1014.4h-9.3v-6.4h7.4c1.3,0,1.9-0.6,1.9-1.9v-2.9h6.7v44.8h-6.7V1014.4z
				"
      />
    </svg>
  )
}

export default SVG
