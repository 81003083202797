import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEOPage from '../components/SEO'
import Layout from '../components/Layout'
import ArrowLink from '../components/Ui/ArrowLink'
import Tracking from '../components/ContentBlocks/ContentBlocksTracking'
import Footer from '../components/Footer'

// Quota Maps
import GSH from '../components/SVG/QuotaMaps/GSH.js'
import HAK from '../components/SVG/QuotaMaps/HAK.js'
import HPB from '../components/SVG/QuotaMaps/HPB.js'
import LIN from '../components/SVG/QuotaMaps/LIN.js'
import RCO from '../components/SVG/QuotaMaps/RCO.js'
import RIB from '../components/SVG/QuotaMaps/RIB.js'
import RSK from '../components/SVG/QuotaMaps/RSK.js'
import SCH from '../components/SVG/QuotaMaps/SCH.js'
import SPD from '../components/SVG/QuotaMaps/SPD.js'
import SPE from '../components/SVG/QuotaMaps/SPE.js'
import TRU from '../components/SVG/QuotaMaps/TRU.js'

// Reference Quota Map Codes..
// Ghost Shark: GSH
// Hake: HAK
// Grouper: HPB
// Ling: LIN
// Cod: RCO
// Deep Sea Cod: RIB
// Rough Skate: RSK
// School Shark: SCH
// Spiny Dog Fish: SPD
// Sea Perch: SPE
// Trumpeter: TRU

const footerLinks = ['the-kawatea', 'pot-fishing', 'past']

class ProductPage extends React.Component {
  render() {
    const { data, location, pageContext } = this.props
    const product = data.allPrismicProducts.edges[0].node.data

    let packed_date = product.catch.filter(item =>
      item.packed_date.includes(pageContext.date)
    )

    function formatDate(dateString) {
      var date = new Date(dateString)
      var months = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      var d = date.getDate()
      var m = months[date.getMonth()]
      var y = date.getFullYear()
      return (d <= 9 ? '0' + d : d) + ' ' + m + ' ' + y
    }

    let productVariant = packed_date[0]

    const renderMap = () => {
      let area = productVariant.nz_quota_management_area.replace(/[0-9]/g, '')
      switch (area) {
        case 'GSH':
          return <GSH />
        case 'HAK':
          return <HAK />
        case 'HPB':
          return <HPB />
        case 'LIN':
          return <LIN />
        case 'RCO':
          return <RCO />
        case 'RIB':
          return <RIB />
        case 'RSK':
          return <RSK />
        case 'SCH':
          return <SCH />
        case 'SPD':
          return <SPD />
        case 'SPE':
          return <SPE />
        case 'TRU':
          return <TRU />
        default:
          return null
      }
    }

    return (
      <Layout notInMenu>
        <SEOPage
          title={`${product.product_name &&
            product.product_name} | ${pageContext.date && pageContext.date}`}
          description={`${product.product_name &&
            product.product_name} | ${pageContext.date && pageContext.date}`}
          location={location}
        />
        <div className="gutters bg-black">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/24 pt-hh lg:pr-7 lg:border-r border-white-opaque-25 mb-8 lg:mb-0">
              <ArrowLink
                linkText="Back"
                link="traceability"
                arrowDirection="left"
                arrowColor="white"
              />
              <div className="pt-6 lg:py-20 font-light leading-snug">
                <div className="border-b border-white-opaque-25 mb-6">
                  <div className="w-8/12 md:w-auto">
                    <h4 className="pb-4 text-2xl">{product.product_name}</h4>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row lg:flex-col lg:text-2xl">
                  <div className="md:w-1/3 lg:w-auto">
                    <h5 className="hidden md:block lg:hidden uppercase mb-2 md:mb-0 tracking-widest text-xs">
                      Packed
                    </h5>
                  </div>
                  <div className="md:w-1/3 lg:w-auto">
                    <h5 className="hidden md:block lg:hidden uppercase mb-2 tracking-widest text-xs">
                      Vessel
                      <br />
                      Landed
                    </h5>
                  </div>
                  <div className="md:w-1/3 lg:w-auto">
                    <h5 className="hidden md:block lg:hidden uppercase mb-2 tracking-widest text-xs">
                      NZ Quota
                      <br />
                      Management Area
                    </h5>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row lg:flex-col border-b border-white-opaque-25 lg:border-b-0 lg:text-2xl">
                  <div className="md:w-1/3 lg:w-auto lg:pb-4 mb-5 lg:mb-6">
                    <h5 className="md:hidden lg:block uppercase mb-2 tracking-widest text-xs">
                      Packed
                    </h5>

                    <div className="pb-4">
                      {formatDate(productVariant.packed_date)}
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-auto lg:pb-4 mb-5 lg:mb-6">
                    <h5 className="md:hidden lg:block uppercase mb-2 tracking-widest text-xs">
                      Vessel Landed
                    </h5>
                    <div className="pb-4">
                      {productVariant.vessel_landed_date}
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-auto lg:pb-4 mb-5 lg:mb-6">
                    <h5 className="md:hidden lg:block uppercase mb-2 tracking-widest text-xs">
                      NZ Quota
                      <br />
                      Management Area
                    </h5>
                    <div>{productVariant.nz_quota_management_area}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:flex-1 mb-8 lg:mb-0">
              <div className="w-full sm:w-1/2 lg:w-full lg:h-screen lg:p-8 mx-auto">
                {renderMap()}
              </div>
            </div>
          </div>
        </div>
        <Tracking />
        <Footer links={footerLinks} />
      </Layout>
    )
  }
}

ProductPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageProductQuery = graphql`
  query ProductsByUid($uid: String) {
    allPrismicProducts(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            catch {
              nz_quota_management_area
              packed_date
              vessel_landed_date(formatString: "DD MMMM YYYY")
            }
            order
            product_name
          }
        }
      }
    }
  }
`

export default ProductPage
